import {FormattedMessage} from "react-intl";
import {PricingInfo} from "lib/model/product/PricingInfo";
import {ReactElement} from "react";
import {ProductCardMode} from "components/product-card/productCardModel";

export interface PriceTagProps {
    mode: ProductCardMode
    pricingInfo: PricingInfo
}

export interface LoadablePriceTagProps {
    mode: ProductCardMode
    pricingInfo: PricingInfo | null
}

export function PriceTag(props: PriceTagProps) {
    switch (props.mode) {
        case "tall":
            return <PriceTagTall pricingInfo={props.pricingInfo} />
        case "compact":
            return <PriceTagCompact pricingInfo={props.pricingInfo} />
    }
}

export function LoadablePriceTag(props: LoadablePriceTagProps) {
    switch (props.mode) {
        case "tall":
            return <LoadablePriceTagTall pricingInfo={props.pricingInfo} />
        case "compact":
            return <LoadablePriceTagCompact pricingInfo={props.pricingInfo} />
    }
}

function PriceTagTall(props: Omit<PriceTagProps, 'mode'>): ReactElement {
    const pi = props.pricingInfo;
    return (
        <div className="tw-flex">

            {/* retail + sale price */}
            <div className="tw-flex tw-flex-col tw-pl-4 tw-pb-4">
                <div className={`tw-text-3xl tw-font-bold ${pi.isSale ? "tw-text-red" : ""}`}>
                    CHF {pi.isSale ? pi.formattedSalePrice : pi.formattedRetailPrice}
                </div>
                {pi.isSale &&
                <div className="tw-text-xl tw-font-bold tw-line-through tw-pt-1 tw-text-grey">
                    <FormattedMessage id="price.onSaleInstead"/> CHF {pi.formattedRetailPrice}
                </div>
                }
            </div>

            {/* price per unit   */}
            {pi.pricePerUnit && (
                <div className="tw-mt-2 tw-text-lg tw-text-grey tw-ml-2">
                    {pi.pricePerUnit}
                </div>
            )}
        </div>
    );
}

function PriceTagTallSkeleton(): ReactElement {
    return <div className="tw-bg-skeletonBackgroundGrey tw-h-10 tw-w-32 tw-ml-4 tw-mb-4"/>
}

function LoadablePriceTagTall(props: Omit<LoadablePriceTagProps, 'mode'>): ReactElement {
    return props.pricingInfo
        ? <PriceTagTall pricingInfo={props.pricingInfo}/>
        : <PriceTagTallSkeleton/>
}

function PriceTagCompact(props: Omit<PriceTagProps, 'mode'>): ReactElement {
    const pi = props.pricingInfo;
    return <div className="tw-flex">
        {/* retail + sale price */}
        <div className="tw-flex tw-flex-col">
            <div className={`tw-text-2xl tw-font-bold ${pi.isSale ? "tw-text-red" : ""}`}>
                CHF {pi.isSale ? pi.formattedSalePrice : pi.formattedRetailPrice}
            </div>
            {pi.isSale && (
                <div className="tw-text-xl tw-font-bold tw-line-through tw-pt-1 tw-text-grey">
                    <FormattedMessage id="price.onSaleInstead"/> CHF {pi.formattedRetailPrice}
                </div>
            )}
        </div>
    </div>
}

function PriceTagCompactSkeleton(): ReactElement {
    return <div className="tw-bg-skeletonBackgroundGrey tw-h-10 tw-w-32"/>
}

function LoadablePriceTagCompact(props: Omit<LoadablePriceTagProps, 'mode'>): ReactElement {
    return props.pricingInfo
        ? <PriceTagCompact pricingInfo={props.pricingInfo}/>
        : <PriceTagCompactSkeleton/>
}
