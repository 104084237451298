import {ReactElement, useState} from "react";
import {useIntl} from "react-intl";

interface SearchInputProps {
	searchDepositItem: (query: string) => void
}

export function SearchInput(props: SearchInputProps) : ReactElement {

	const intl = useIntl();
	const [searchInput, setSearchInput] = useState<HTMLInputElement>();

	return(
		<>
			<input className={'tw-border-2 tw-w-full tw-p-6 tw-pr-20 tw-text-2xl tw-border-lightGrey tw-text-lightGrey'}
				   onChange={(e) => props.searchDepositItem(e.target.value)}
				   type={'text'}
				   ref={(input) => { setSearchInput(input!) }}
				   placeholder={intl.formatMessage({id:'cart.deposit.search'})} />
			<div className={'tw-absolute tw-top-7 tw-right-8 tw-text-lightGrey tw-text-3xl tw-cursor-pointer'}
				 onClick={() => searchInput?.focus()}>
				<i className={'fa fa-search'} />
			</div>
		</>
	);

}