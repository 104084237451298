import jsonp from "jsonp";

export interface MailchimpSubscribeArgs {
    mailchimpDomain: string
    u: string // this is the name of the actual mailchimp param
    mailchimpId: string
    params: Record<string, string>
}

interface MailchimpResponse {
    msg: string
    result: "success" | string
}

export interface MailchimpSuccess {
    msg: string
}

export function mailchimpSubscribe(args: MailchimpSubscribeArgs): Promise<MailchimpSuccess> {
    return new Promise<any>((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("u", args.u);
        params.append("id", args.mailchimpId);
        Object.entries(args.params).forEach(([key, value]) => params.append(key, value));

        jsonp(
            `https://${args.mailchimpDomain}/subscribe/post-json?${params.toString()}`,
            {
                param: "c"
            },
            (error, data: MailchimpResponse) => {
                if (error != null) {
                    return reject(error);
                }
                if (data?.result !== "success") {
                    return reject(data?.msg);
                }
                resolve(data);
            }
        );
    });
}

export function validateEmail(email: string): boolean {
    // https://stackoverflow.com/a/1373724
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return email.match(emailRegex) != null;
}
