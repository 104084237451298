import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {sanitize} from "../../../../../lib/utility";

interface CheckoutAddressLastNameFieldProps {
    lastNameError: string
    setLastNameError: (error: string) => void
    lastName: string
    setLastName: (lastName: string) => void
}

export function CheckoutAddressLastNameField(props: CheckoutAddressLastNameFieldProps) {

    const intl = useIntl();

    return (
        <div className={"tw-mt-8"}>
            <div className={`${props.lastNameError ? 'tw-text-red' : 'tw-text-black'} tw-flex tw-flex-1`}>
                <FormattedMessage id={'cart.lastName'}/> *
            </div>
            <div className={"tw-flex tw-flex-1"}>
                <input
                    value={props.lastName ? props.lastName : ''}
                    onChange={(e) => {
                        props.setLastNameError('');
                        props.setLastName(sanitize(e.target.value));
                    }}
                    placeholder={intl.formatMessage({id: 'cart.lastName'})}
                    className={`${props.lastNameError ? 'tw-border-red' : 'tw-border-grey'} tw-border-2 tw-flex-1 tw-p-6 tw-text-2xl`}
                    type={"text"}/>
            </div>
            {props.lastNameError && (
                <div className={"tw-text-red tw-ml-2 tw-mt-2"}>
                    <FormattedMessage id={props.lastNameError}/>
                </div>
            )}
        </div>
    )

}