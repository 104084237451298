import {ReactElement, useState} from "react";
import {ReactComponent as DecrementButtonSvg} from "../../widgets/DecrementButton.svg";
import {ReactComponent as IncrementButtonSvg} from "../../widgets/IncrementButton.svg";
import {DepositItemModel} from "../../lib/model/depositinfo/DepositItemModel";
import {useAppDispatch} from "../../lib/redux/reduxHooks";
import {depositInfoActions} from "./depositInfoSlice";

interface DepositItemRowProps {
	depositItem: DepositItemModel
	setDepositHasChanged: () => void
}

export function DepositItemRow(props: DepositItemRowProps): ReactElement {

	const dispatch = useAppDispatch();
	const [quantity, setQuantity] = useState<number>(props.depositItem.quantity);

	const decrementItem = () => {
		let newQuantity = quantity - 1;
		if (newQuantity > 0) {
			setQuantity(newQuantity);
			props.setDepositHasChanged();
			dispatch(depositInfoActions.decrementDepositItem(props.depositItem));
		} else {
			dispatch(depositInfoActions.removeDepositItem(props.depositItem));
		}
	}

	const incrementItem = () => {
		let newQuantity = quantity + 1;
		setQuantity(newQuantity);
		props.setDepositHasChanged();
		let depositItem = Object.assign(
			{},
			props.depositItem,
			{
				quantity: newQuantity
			}
		);
		dispatch(depositInfoActions.incrementDepositItem(depositItem));
	}

	return (
		<div className={'tw-flex tw-flex-row tw-items-center tw-border-lightGrey tw-border-b tw-py-6'}>
			<div className={'tw-mr-4 tw-ml-8'}>
				<img alt={props.depositItem.name} src={`${props.depositItem.imgUrl}+?thumbnail`}/>
			</div>
			<div className={'tw-font-bold tw-flex tw-flex-row tw-mx-8 tw-flex-1'}>
				{props.depositItem.packagingType} {props.depositItem.name}
			</div>
			<div className={'tw-font-bold tw-flex tw-flex-row tw-items-center'}>
				<div className={'tw-w-8 tw-mx-6 hover:tw-cursor-pointer hover:tw-text-black'}
					 onClick={() => decrementItem()}>
					<DecrementButtonSvg/>
				</div>
				<div>
					{quantity}
				</div>
				<div className={'tw-w-8 tw-ml-6 tw-mr-8 hover:tw-cursor-pointer hover:tw-text-black'}
					 onClick={() => incrementItem()}>
					<IncrementButtonSvg/>
				</div>
			</div>
		</div>
	);

}