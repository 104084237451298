import React, {ComponentType, FunctionComponent, ReactElement} from "react";
import {connectHits, Hit, HitsProvided, InsightsClient} from 'react-instantsearch-core';
import {HeaderSearchProductHit} from "./HeaderSearchProductHit";
import {ProductIndexDoc} from "lib/algolia/algoliaProductIndex";

export interface SearchbarProductHitsProps {
    insightsClient: InsightsClient
}

function ProductHits(props: SearchbarProductHitsProps & HitsProvided<ProductIndexDoc>): ReactElement {
    return <ul className="tw-w-full">
        {props.hits.map(hit =>
            <HeaderSearchProductHit
                key={hit.objectID}
                hit={hit as Hit<ProductIndexDoc>}
                insightsClient={props.insightsClient}
            />
        )}
    </ul>
}

export const HeaderSearchProductHits: ComponentType<SearchbarProductHitsProps> =
    connectHits(ProductHits as FunctionComponent<HitsProvided<ProductIndexDoc>>) as unknown as ComponentType<SearchbarProductHitsProps>;
