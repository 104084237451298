import {ReactComponent as Close} from "./img/close.svg";
import {FormattedMessage} from "react-intl";

export interface AdventCalendarPopupHeaderProps {
	currentPage: number
	setIsOpen: (isOpen: boolean) => void
}

export default function AdventCalendarPopupHeader(props: AdventCalendarPopupHeaderProps) {

	return(
		<>
			<div className={"tw-pb-8"}>
				{props.currentPage}/3 <FormattedMessage id={'frontend.adventcalendar.select.design'} />
			</div>
			<button className="tw-absolute tw-top-8 tw-right-8 tw-z-modal-close-btn"
					onClick={() => props.setIsOpen(false)}>
				<Close />
			</button>
		</>
	);

}