import {ReactElement} from "react";
import {SaleBadge} from "./SaleBadge";
import {MvpBadge} from "./MvpBadge";
import {TextBadge} from "./TextBadge";
import {BadgeType, isTextBadge} from "lib/model/product/BadgeType";
import {PricingInfo} from "lib/model/product/PricingInfo";

export interface BadgeProps {
    badgeType: BadgeType
    pricingInfo?: PricingInfo | null
}

export function Badge(props: BadgeProps): ReactElement | null {
    if (props.badgeType === 'badge_sale' && props.pricingInfo?.isSale) {
        return <SaleBadge discount={String(props.pricingInfo.discount)}/>;
    }

    if (props.badgeType === 'badge_cyberSale' && props.pricingInfo?.isSale) {
        return <SaleBadge discount={String(props.pricingInfo.discount)}/>;
    }

    if (props.badgeType === 'badge_mvp') {
        return <MvpBadge/>;
    }

    if (isTextBadge(props.badgeType)) {
        return <TextBadge badgeType={props.badgeType}/>
    }

    return null;
}
