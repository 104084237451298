import {ReactNode, Fragment, ReactElement} from "react";

export interface HighlighterProps {
    text: string
    highlight: string
    renderHighlight?: (textToHighlight: string) => ReactNode
}

export function Highlighter({text, highlight, renderHighlight}: HighlighterProps): ReactElement {
    if (highlight.length === 0) {
        return <>text</>;
    }

    const doHighlight = renderHighlight ?? (s => <strong>{s}</strong>)
    const result: ReactNode[] = [];
    const lowerCaseText = text.toLowerCase();
    const lowerCaseHighlight = highlight.toLowerCase();
    let lastIndex = 0;
    let currentIndex = lowerCaseText.indexOf(lowerCaseHighlight);
    while (currentIndex >= 0) {
        result.push(<Fragment key={currentIndex}>
            {text.slice(lastIndex, currentIndex)}
            {doHighlight(text.slice(currentIndex, currentIndex + highlight.length))}
        </Fragment>);
        lastIndex = currentIndex + highlight.length;
        currentIndex = lowerCaseText.indexOf(lowerCaseHighlight, lastIndex);
    }
    result.push(<Fragment key={currentIndex}>{text.slice(lastIndex)}</Fragment>);

    return <>{result}</>
}
