import {ReactElement, ReactNode} from "react";

export interface ImageButtonProps {
	onClick: () => void
	disabled: boolean
	children: ReactNode
}

export function ImageButton(props: ImageButtonProps): ReactElement {
	return <button onClick={props.onClick}
				   disabled={props.disabled}
				   className={`tw-text-black hover:tw-text-grey disabled:tw-text-disabled ${props.disabled ? 'hover:tw-cursor-default' : ''}`}>
		{props.children}
	</button>
}
