import {get, post} from "./api";
import {ClaimableWin} from "../../components/blackfriday/blackFriday";

const ADD_TO_CART_ENDPOINT = '/api/v1/cart/add-to-cart';
const SINGLE_ADD_TO_CART_ENDPOINT = '/api/v1/cart/add-to-cart-single';
const GET_LOTTERY_INFO_ENDPOINT = '/api/v1/cart/lottery-info'

export type CartItem = {
    productId: number,
    quantity: number
}

export type LotteryResult = {
    openAmount: number | null | undefined
    claimableWin: ClaimableWin | null | undefined
}

export async function addSingleItemToCart(cartItem: CartItem): Promise<any> {
    return await post({
        url: SINGLE_ADD_TO_CART_ENDPOINT,
        body: {
            ...cartItem
        }
    });
}

export async function addItemsToCart(cartItems: CartItem[]) : Promise<any> {
    return await post({
        url: ADD_TO_CART_ENDPOINT,
        body: {
            addToCartItems: cartItems
        }
    });
}

export async function getLotteryInfo(): Promise<LotteryResult> {
    return await get({
        url: GET_LOTTERY_INFO_ENDPOINT
    })
}
