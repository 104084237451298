import {ReactElement} from "react";
import {DepositItemRow} from "./DepositItemRow";
import {DepositItemModel} from "../../lib/model/depositinfo/DepositItemModel";

interface DepositItemRowListProps {
	depositItemList: DepositItemModel[],
	setDepositHasChanged: () => void
}

export function DepositItemRowList(props: DepositItemRowListProps) : ReactElement {

	return(
		<>
			{props.depositItemList && props.depositItemList.map(depositItem => (
				<DepositItemRow
					key={depositItem.id}
					setDepositHasChanged={props.setDepositHasChanged}
					depositItem={depositItem}/>
			))}
		</>
	)

}