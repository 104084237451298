import {ReactElement} from "react";
import {DepositItemModel} from "../../lib/model/depositinfo/DepositItemModel";
import {FetchStatus} from "../../lib/utility";
import {useAppDispatch} from "../../lib/redux/reduxHooks";
import {depositInfoActions} from "./depositInfoSlice";

interface SearchBoxProps {
	searchResults: DepositItemModel[] | undefined,
	searchableDepositItemsState: FetchStatus,
	setSearch: (query: string) => void,
	setDepositHasChanged: () => void
}

export function SearchBox(props: SearchBoxProps) : ReactElement {

	const dispatch = useAppDispatch();

	if(props.searchResults && props.searchableDepositItemsState === 'FETCHED') {
		return (
			<div className={'tw-bg-white tw-absolute tw-w-full tw-border tw-border-lightGrey tw-mt-1 tw-z-50'}>
				{props.searchResults && props.searchResults.slice(0, 5).map((depositItem, i) => (
					<div className={'tw-py-3 hover:tw-bg-skeletonGrey tw-px-6 hover:tw-cursor-pointer'} key={i} onClick={() => {
						dispatch(depositInfoActions.addDepositItem(depositItem));
						props.setSearch('');
						props.setDepositHasChanged();
					}}>
						{`${depositItem.packagingType ?? ''} ${depositItem.name}`}
					</div>
				))}
			</div>
		)
	} else {
		return (
			<div className={'tw-bg-white tw-absolute tw-w-full tw-border tw-border-lightGrey tw-mt-1'}>
				{[...Array(1)].map((e, i) => (
					<div className={'tw-flex tw-flex-row'} key={i}>
						<div style={{width: '80px', height: '30px'}} className={'tw-bg-skeletonGrey tw-my-4 tw-ml-4 skeleton-box'}></div>
						<div style={{height: '30px'}} className={'tw-bg-skeletonGrey tw-my-4 tw-ml-4 tw-w-full tw-mr-8 skeleton-box'}></div>
					</div>
				))}
			</div>
		)
	}

}