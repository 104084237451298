import {ActionButtonProps} from "./ProductSliderDesktop";

export const PrevButton = (props: ActionButtonProps) => (
	<div className={"tw-absolute embla__prev-container tw-cursor-pointer tw-text-lightGrey hover:tw-text-grey"}>
		<button className="embla__prev" onClick={props.clickAction}>
			<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="22.5" cy="22.5" r="21.5" transform="rotate(-180 22.5 22.5)" fill="white"
						stroke="currentColor" strokeWidth="2"/>
				<path d="M27 30L19 22L27 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
			</svg>
		</button>
	</div>
)