export const DepositItemListSkeleton = () => {
	return(
		<>
			{[...Array(1)].map((e, i) => (
				<div key={i} className={'tw-flex tw-flex-row tw-justify-center tw-items-center'} style={{height: '80px'}}>
					<div style={{width: '55px', height: '55px'}} className={'tw-bg-skeletonGrey tw-my-4 tw-mx-6 skeleton-box'}></div>
					<div style={{height: '30px'}} className={'tw-bg-skeletonGrey tw-my-4 tw-ml-4 fw-flex tw-flex-1 tw-mr-4 skeleton-box'}></div>
					<div style={{width: '20px', height: '30px'}} className={'tw-bg-skeletonGrey tw-my-4 tw-ml-4 skeleton-box'}></div>
					<div style={{width: '30px', height: '30px'}} className={'tw-bg-skeletonGrey tw-my-4 tw-ml-4 skeleton-box'}></div>
					<div style={{width: '20px', height: '30px'}} className={'tw-bg-skeletonGrey tw-my-4 tw-ml-4 skeleton-box tw-mr-4'}></div>
				</div>
			))}
		</>
	);
}