import ReactDatePicker, {registerLocale} from "react-datepicker";
import de from "date-fns/locale/de";
import React from "react";
import {useSelector} from "react-redux";
import {checkoutSelectors} from "../../checkoutSlice";

registerLocale("de", de);

interface datePickerProps {
    date: Date;
    setDate: (date: Date) => void;
    hideDatePicker: () => void;
}

export const CheckoutSectionDeliveryDatePicker = (props: datePickerProps) => {

    const deliveryDatesState = useSelector(checkoutSelectors.getCheckoutDeliveryDatesState);
    const deliveryDates = useSelector(checkoutSelectors.getCheckoutDeliveryDates);

    if(deliveryDatesState !== 'FETCHED') {
        return (
            <div>
                Loading...
            </div>
        )
    }

    const calendarDates = deliveryDates.map((date) => new Date(date));

    return (
        <ReactDatePicker
            dateFormat={"dd.MM.yyyy"}
            locale={de}
            selected={props.date}
            onChange={(date) => {
                props.setDate(date!);
                props.hideDatePicker();
            }}
            includeDates={calendarDates}
            inline
        />
    );
};