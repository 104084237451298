import {SupplyLimitedSaleInfo} from "lib/model/product/ProductModel";
import {ReactElement, ReactNode} from "react";
import {ProgressBar} from "widgets/ProgressBar";
import {FormattedMessage} from "react-intl";

export interface SupplyLimitedSaleBarProps {
    supplyLimitedSale: SupplyLimitedSaleInfo
}

export function SupplyLimitedSaleBar(props: SupplyLimitedSaleBarProps): ReactElement {
    const percent = props.supplyLimitedSale.available / props.supplyLimitedSale.totalSupply * 100;
    return <>
        <ProgressBar
            percent={percent}
            fillColor="#BB2E2E"
            emptyColor="#ECE7DF"
        />
        <div className="tw-text-xl tw-italic tw-pt-2 md:tw-pt-2.5">
            {props.supplyLimitedSale.available > 0 ? (
                <FormattedMessage
                    id="frontend.supplyLimitedSaleBar.dealsAvailable"
                    values={{
                        count: props.supplyLimitedSale.available,
                        b: (chunks: ReactNode) => <strong>{chunks}</strong>
                    }}
                />
            ) : (
                <FormattedMessage id="frontend.supplyLimitedSaleBar.soldOut" />
            )}
        </div>
    </>
}
