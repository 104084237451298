import React, {useEffect, useState} from "react";
import {getCityByZip} from "../../../../lib/api/utilitiesApi";
import {CheckoutAddressValues} from "../../model/CheckoutAddressValuesModel";
import {CheckoutAddressSelector} from "./fields/CheckoutAddressSelector";
import {CheckoutAddressCompanyField} from "./fields/CheckoutAddressCompanyField";
import {CheckoutAddressSalutationField} from "./fields/CheckoutAddressSalutationField";
import {CheckoutAddressPhoneField} from "./fields/CheckoutAddressPhoneField";
import {CheckoutAddressPlzCityField} from "./fields/CheckoutAddressPlzCityField";
import {CheckoutAddress2Field} from "./fields/CheckoutAddress2Field";
import {CheckoutAddressField} from "./fields/CheckoutAddressField";
import {CheckoutAddressLastNameField} from "./fields/CheckoutAddressLastNameField";
import {CheckoutAddressFirstNameField} from "./fields/CheckoutAddressFirstNameField";

interface CheckoutAddressFormProps {
    salutation: string;
    company: string;
    firstName: string;
    lastName: string;
    address: string;
    address2: string;
    plz: string;
    city: string;
    phone: string;

    setSalutation: (salutation: string) => void;
    setCompany: (company: string) => void;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    setAddress: (address: string) => void;
    setAddress2: (address2: string) => void;
    setPlz: (plz: string) => void;
    setCity: (city: string) => void;
    setPhone: (phone: string) => void;

    salutationError: string;
    firstNameError: string;
    lastNameError: string;
    addressError: string;
    plzError: string;
    cityError: string;
    phoneError: string;

    setSalutationError: (salutationError: string) => void;
    setFirstNameError: (firstNameError: string) => void;
    setLastNameError: (lastNameError: string) => void;
    setAddressError: (addressError: string) => void;
    setPlzError: (plzError: string) => void;
    setCityError: (cityError: string) => void;
    setPhoneError: (phoneError: string) => void;

    customerAddresses: CheckoutAddressValues[]
}

export function CheckoutAddressForm(props: CheckoutAddressFormProps) {

    const [hasCompanyField, setHasCompanyField] = useState<boolean>(false);
    const [hasAddress2, setHasAddress2] = useState<boolean>(false);

    const updateCityPerPlz = async (plz: string) => {
        const city = await getCityByZip(plz);
        if(city.locationFound) {
            props.setCity(city.locationName);
        }
    }

    const findSelectedAddress = (): number => {
        var index = 0

        props.customerAddresses.forEach((address, idx) => {
            if(
                address.salutation === props.salutation &&
                address.firstName === props.firstName &&
                address.lastName === props.lastName &&
                address.address === props.address &&
                address.plz === props.plz &&
                address.city === props.city &&
                address.phone === props.phone
            ) {
                index = idx
            }
        })

        return index
    }

    const updateAddress = (address: CheckoutAddressValues) => {
        props.setCompany(address.company);
        props.setSalutation(address.salutation);
        props.setFirstName(address.firstName);
        props.setLastName(address.lastName);
        props.setAddress(address.address);
        props.setAddress2(address.address2);
        props.setPlz(address.plz);
        props.setCity(address.city);
        props.setPhone(address.phone);
    }

    useEffect(() => {
        props.customerAddresses.forEach((address) => {
            if(address.default) {
                if(!props.company && !props.salutation && !props.firstName && !props.lastName && !props.address && !props.plz && !props.city && !props.phone) {
                    props.setCompany(address.company);
                    props.setSalutation(address.salutation);
                    props.setFirstName(address.firstName);
                    props.setLastName(address.lastName);
                    props.setAddress(address.address);
                    props.setAddress2(address.address2);
                    props.setPlz(address.plz);
                    props.setCity(address.city);
                    props.setPhone(address.phone);
                }
            }
        });
    }, [props]);

    return (
        <div className={"tw-flex tw-flex-1 tw-flex-col tw-pr-0 md:tw-pr-24 tw-mt-8"}>
            <div>
                <CheckoutAddressSelector
                    salutationError={props.salutationError}
                    updateAddress={updateAddress}
                    customerAddresses={props.customerAddresses}
                    selectedAddressIndex={findSelectedAddress()}
                />
                <CheckoutAddressCompanyField
                    hasCompanyField={hasCompanyField}
                    company={props.company}
                    setHasCompanyField={setHasCompanyField}
                    setCompany={props.setCompany}
                />
                <CheckoutAddressSalutationField
                    salutationError={props.salutationError}
                    salutation={props.salutation}
                    setSalutationError={props.setSalutationError}
                    setSalutation={props.setSalutation}
                />
            </div>
            <CheckoutAddressFirstNameField
                firstNameError={props.firstNameError}
                setFirstNameError={props.setFirstNameError}
                firstName={props.firstName}
                setFirstName={props.setFirstName}
            />
            <CheckoutAddressLastNameField
                lastNameError={props.lastNameError}
                setLastNameError={props.setLastNameError}
                lastName={props.lastName}
                setLastName={props.setLastName}
            />
            <CheckoutAddressField
                addressError={props.addressError}
                setAddressError={props.setAddressError}
                address={props.address}
                setAddress={props.setAddress}
            />
            <CheckoutAddress2Field
                hasAddress2={hasAddress2}
                address2={props.address2}
                setHasAddress2={setHasAddress2}
                setAddress2={props.setAddress2}
            />
            <CheckoutAddressPlzCityField
                plzError={props.plzError}
                setPlzError={props.setPlzError}
                plz={props.plz}
                setPlz={props.setPlz}
                cityError={props.cityError}
                setCityError={props.setCityError}
                city={props.city}
                setCity={props.setCity}
                updateCityPerPlz={updateCityPerPlz}
            />
            <CheckoutAddressPhoneField
                phoneError={props.phoneError}
                setPhoneError={props.setPhoneError}
                phone={props.phone}
                setPhone={props.setPhone}
            />
        </div>
    )

}