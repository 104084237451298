import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	getDepositInfo,
	getDepositItems,
	getDepositItemsSuggestions,
	getSearchableDepositItems
} from "../../lib/api/depositInfoApi";
import {FetchStatus} from "../../lib/utility";
import {DepositItemModel} from "../../lib/model/depositinfo/DepositItemModel";

type DepositInfoRootState = {
	depositInfo: DepositInfoState
}

interface DepositInfoState {
	depositItemsSuggestionsStatus: FetchStatus
	depositItemsSuggestions: DepositItemModel[]

	searchableDepositItemsStatus: FetchStatus
	searchableDepositItems: DepositItemModel[]

	depositItemsStatus: FetchStatus
	depositItems: DepositItemModel[]

	depositInfoStatus: FetchStatus
	depositInfo: boolean
}

const initialState: DepositInfoState = {
	depositItemsSuggestionsStatus: 'INIT',
	depositItemsSuggestions: [],

	searchableDepositItemsStatus: 'INIT',
	searchableDepositItems: [],

	depositItemsStatus: 'INIT',
	depositItems: [],

	depositInfoStatus: 'INIT',
	depositInfo: false
}

const publicSelectors = {
	getDepositItemsSuggestionsStatus: (state: DepositInfoRootState) => state.depositInfo.depositItemsSuggestionsStatus,
	getDepositItemsSuggestions: (state: DepositInfoRootState) => state.depositInfo.depositItemsSuggestions,

	getSearchableDepositItemsStatus: (state: DepositInfoRootState) => state.depositInfo.searchableDepositItemsStatus,
	getSearchableDepositItems: (state: DepositInfoRootState) => state.depositInfo.searchableDepositItems,

	getDepositItemsStatus: (state: DepositInfoRootState) => state.depositInfo.depositItemsStatus,
	getDepositItems: (state: DepositInfoRootState) => state.depositInfo.depositItems,

	getDepositInfoStatus: (state: DepositInfoRootState) => state.depositInfo.depositInfoStatus,
	getDepositInfo: (state: DepositInfoRootState) => state.depositInfo.depositInfo
}

const fetchDepositInfoSuggestions = createAsyncThunk(
	'depositInfo/fetchDepositItemsSuggestions',
	async () => {
		return await getDepositItemsSuggestions();
	}
)

const fetchSearchableDepositItems = createAsyncThunk(
	'depositInfo/fetchSearchableDepositItems',
	async () => {
		return await getSearchableDepositItems();
	}
)

const fetchDepositItems = createAsyncThunk(
	'depositInfo/fetchDepositItems',
	async () => {
		return await getDepositItems();
	}
)

const fetchDepositInfo = createAsyncThunk(
	'depositInfo/fetchDepositInfo',
	async () => {
		return await getDepositInfo();
	}
)

const {actions, reducer} = createSlice({
	name: 'depositInfo',
	initialState,
	reducers: {
		removeDepositItem(state: DepositInfoState, action: PayloadAction<DepositItemModel>) {
			state.depositItems = state.depositItems.filter(depositItem => {
				return depositItem.id !== action.payload.id
			});
			state.depositItemsSuggestions = state.depositItemsSuggestions.filter(depositItemSuggestion => {
				return depositItemSuggestion.id !== action.payload.id
			});

			if(state.depositItems.length === 0) {
				state.depositItemsSuggestions = [];
			}
		},
		addDepositItem(state: DepositInfoState, action: PayloadAction<DepositItemModel>) {
			state.depositItems.push(action.payload);
		},
		incrementDepositItem(state: DepositInfoState, action: PayloadAction<DepositItemModel>) {
			const index = state.depositItems.findIndex(depositItem => {
				return depositItem.id === action.payload.id;
			});

			if(index === -1) {
				state.depositItemsSuggestions.forEach(suggestion => {
					if(suggestion.id === action.payload.id) {
						state.depositItems.push(action.payload);
					} else {
						state.depositItems.push(suggestion);
					}
				});
			} else {
				state.depositItems.forEach(depositItem => {
					if(depositItem.id === action.payload.id) {
						depositItem.quantity = action.payload.quantity;
					}
				});
			}
		},
		decrementDepositItem(state: DepositInfoState, action: PayloadAction<DepositItemModel>) {
			state.depositItems.forEach(depositItem => {
				if(depositItem.id === action.payload.id) {
					depositItem.quantity = action.payload.quantity - 1;
				}
			});
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchDepositInfoSuggestions.pending, (state, action) => {
				state.depositItemsSuggestionsStatus = 'FETCHING';
			})
			.addCase(fetchDepositInfoSuggestions.fulfilled, (state, action) => {
				state.depositItemsSuggestionsStatus = 'FETCHED';
				state.depositItemsSuggestions = action.payload
			})
			.addCase(fetchDepositInfoSuggestions.rejected, (state, action) => {
				state.depositItemsSuggestionsStatus = 'ERROR';
			})

			.addCase(fetchSearchableDepositItems.pending, (state, action) => {
				state.searchableDepositItemsStatus = 'FETCHING';
			})
			.addCase(fetchSearchableDepositItems.fulfilled, (state, action) => {
				state.searchableDepositItemsStatus = 'FETCHED';
				state.searchableDepositItems = action.payload
			})
			.addCase(fetchSearchableDepositItems.rejected, (state, action) => {
				state.searchableDepositItemsStatus = 'ERROR';
			})

			.addCase(fetchDepositItems.pending, (state, action) => {
				state.depositItemsStatus = 'FETCHING';
			})
			.addCase(fetchDepositItems.fulfilled, (state, action) => {
				state.depositItemsStatus = 'FETCHED';
				state.depositItems = action.payload;
			})
			.addCase(fetchDepositItems.rejected, (state, action) => {
				state.depositItemsStatus = 'ERROR';
			})
			.addCase(fetchDepositInfo.fulfilled, (state, action) => {
				state.depositInfoStatus = 'FETCHED';
				state.depositInfo = action.payload;
			})
	}
});

const publicActions = {
	fetchDepositInfoSuggestions,
	fetchSearchableDepositItems,
	fetchDepositItems,
	fetchDepositInfo,
	removeDepositItem: actions.removeDepositItem,
	addDepositItem: actions.addDepositItem,
	incrementDepositItem: actions.incrementDepositItem,
	decrementDepositItem: actions.decrementDepositItem
}

export {reducer as depositInfoReducer};
export {publicActions as depositInfoActions};
export {publicSelectors as depositInfoSelectors};