import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getCheckoutAddresses,
    getCheckoutContact,
    getCheckoutCustomer,
    getCheckoutDelivery, getCheckoutDeliveryDates, getCheckoutKeywords,
    getCheckoutOrder,
    getCheckoutOrderInfo, getCheckoutPayment,
    getCurrentCheckoutSection
} from "../../lib/api/checkoutApi";
import {FetchStatus} from "../../lib/utility";
import {CheckoutOrderModel} from "./model/CheckoutOrderModel";
import {CheckoutContactModel} from "./model/CheckoutContactModel";
import {CheckoutAddressValues} from "./model/CheckoutAddressValuesModel";
import {CheckoutCustomerModel} from "./model/CheckoutCustomerModel";
import {CheckoutSectionStateModel} from "./model/CheckoutSectionStateModel";
import {CheckoutDeliveryModel} from "./model/CheckoutDeliveryModel";
import {CheckoutPaymentModel} from "./model/CheckoutPaymentModel";

type CheckoutRootState = { checkout: CheckoutState }

interface CheckoutState {
    checkoutOrder: CheckoutOrderModel
    checkoutOrderState: FetchStatus

    checkoutContact: CheckoutContactModel
    checkoutContactState: FetchStatus

    sectionStatus: FetchStatus
    currentCheckoutSection: CheckoutSectionStateModel
    checkoutOrderInfoState: FetchStatus
    checkoutOrderInfo: string

    checkoutShippingAddress: CheckoutAddressValues
    checkoutBillingAddress: CheckoutAddressValues
    checkoutCustomerAddresses: CheckoutAddressValues[]

    checkoutAddressStatus: FetchStatus
    checkoutCustomerStatus: FetchStatus
    checkoutCustomer: CheckoutCustomerModel

    checkoutDelivery: CheckoutDeliveryModel
    checkoutDeliveryState: FetchStatus

    checkoutDeliveryDates: string[]
    checkoutDeliveryDatesState: FetchStatus

    checkoutHasShippableFulfillmentGroups: boolean

    checkoutWarningKeywordsStatus: FetchStatus
    checkoutWarningKeywords: string[]

    checkoutPayment: CheckoutPaymentModel
    checkoutPaymentState: FetchStatus
}

const initialState: CheckoutState = {
    checkoutOrder: {} as CheckoutOrderModel,
    checkoutOrderState: 'INIT',

    checkoutContact: {} as CheckoutContactModel,
    checkoutContactState: 'INIT',

    sectionStatus: 'INIT',
    currentCheckoutSection: 'CONTACT_INFO',
    checkoutOrderInfoState: 'INIT',
    checkoutOrderInfo: '',

    checkoutShippingAddress: {} as CheckoutAddressValues,
    checkoutBillingAddress: {} as CheckoutAddressValues,
    checkoutCustomerAddresses: [],

    checkoutAddressStatus: 'INIT',
    checkoutCustomerStatus: 'INIT',
    checkoutCustomer: {} as CheckoutCustomerModel,

    checkoutDelivery: {} as CheckoutDeliveryModel,
    checkoutDeliveryState: 'INIT',

    checkoutDeliveryDates: [],
    checkoutDeliveryDatesState: 'INIT',

    checkoutHasShippableFulfillmentGroups: true,

    checkoutWarningKeywordsStatus: 'INIT',
    checkoutWarningKeywords: [],

    checkoutPayment: {} as CheckoutPaymentModel,
    checkoutPaymentState: 'INIT'
}

const publicSelectors = {
    getCheckoutOrder: (state: CheckoutRootState) => state.checkout.checkoutOrder,
    getCheckoutOrderState: (state: CheckoutRootState) => state.checkout.checkoutOrderState,

    getCheckoutContact: (state: CheckoutRootState) => state.checkout.checkoutContact,
    getCheckoutContactState: (state: CheckoutRootState) => state.checkout.checkoutContactState,

    getCurrentCheckoutSection: (state: CheckoutRootState) => state.checkout.currentCheckoutSection,
    getSectionStatus: (state: CheckoutRootState) => state.checkout.sectionStatus,

    getCheckoutOrderInfo: (state: CheckoutRootState) => state.checkout.checkoutOrderInfo,
    getCheckoutOrderInfoState: (state: CheckoutRootState) => state.checkout.checkoutOrderInfoState,

    getCheckoutShippingAddress: (state: CheckoutRootState) => state.checkout.checkoutShippingAddress,
    getCheckoutBillingAddress: (state: CheckoutRootState) => state.checkout.checkoutBillingAddress,
    getCheckoutCustomerAddresses: (state: CheckoutRootState) => state.checkout.checkoutCustomerAddresses,

    getCheckoutCustomerState: (state: CheckoutRootState) => state.checkout.checkoutCustomerStatus,
    getCheckoutCustomer: (state: CheckoutRootState) => state.checkout.checkoutCustomer,

    getCheckoutDelivery: (state: CheckoutRootState) => state.checkout.checkoutDelivery,
    getCheckoutDeliveryState: (state: CheckoutRootState) => state.checkout.checkoutDeliveryState,

    getCheckoutDeliveryDates: (state: CheckoutRootState) => state.checkout.checkoutDeliveryDates,
    getCheckoutDeliveryDatesState: (state: CheckoutRootState) => state.checkout.checkoutDeliveryDatesState,

    getCheckoutHasShippableFulfillmentGroups: (state: CheckoutRootState) => state.checkout.checkoutHasShippableFulfillmentGroups,

    getCheckoutWarningKeywordsStatus: (state: CheckoutRootState) => state.checkout.checkoutWarningKeywordsStatus,
    getCheckoutWarningKeywords: (state: CheckoutRootState) => state.checkout.checkoutWarningKeywords,

    getCheckoutPayment: (state: CheckoutRootState) => state.checkout.checkoutPayment,
    getCheckoutPaymentState: (state: CheckoutRootState) => state.checkout.checkoutPaymentState
};

const fetchCheckoutOrder = createAsyncThunk(
    'checkout/fetchCheckoutOrder',
    async () => {
        return await getCheckoutOrder();
    }
)

const fetchCheckoutContact = createAsyncThunk(
    'checkout/fetchCheckoutContact',
    async () => {
        return await getCheckoutContact();
    }
)

const fetchCurrentCheckoutSection = createAsyncThunk(
    'checkout/fetchCurrentCheckoutSection',
    async () => {
        return await getCurrentCheckoutSection();
    }
)

const fetchCheckoutOrderInfo = createAsyncThunk(
    'checkout/fetchCheckoutOrderInfo',
    async () => {
        return await getCheckoutOrderInfo();
    }
)

const fetchCheckoutAddresses = createAsyncThunk(
    'checkout/fetchCheckoutAddresses',
    async () => {
        return await getCheckoutAddresses();
    }
)

const fetchCheckoutCustomer = createAsyncThunk(
    'checkout/fetchCheckoutCustomer',
    async () => {
        return await getCheckoutCustomer();
    }
)

const fetchCheckoutDelivery = createAsyncThunk(
    'checkout/fetchCheckoutDelivery',
    async () => {
        return await getCheckoutDelivery();
    }
)

const fetchCheckoutDeliveryDates = createAsyncThunk(
    'checkout/fetchCheckoutDeliveryDates',
    async () => {
        return await getCheckoutDeliveryDates();
    }
)

const fetchCheckoutKeywords = createAsyncThunk(
    'checkout/fetchCheckoutKeywords',
    async () => {
        return await getCheckoutKeywords();
    }
)

const fetchCheckoutPayment = createAsyncThunk(
    'checkout/fetchCheckoutPayment',
    async () => {
        return await getCheckoutPayment();
    }
)

const {reducer} = createSlice({
    name: 'checkout',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(fetchCheckoutOrder.pending, (state, action) => {
            state.checkoutOrderState = 'FETCHING';
        })
        builder.addCase(fetchCheckoutOrder.fulfilled, (state, action) => {
            state.checkoutOrderState = 'FETCHED';
            state.checkoutOrder = action.payload;
        })
        builder.addCase(fetchCheckoutOrder.rejected, (state, action) => {
            state.checkoutOrderState = 'ERROR';
        })

        builder.addCase(fetchCheckoutContact.pending, (state, action) => {
            state.checkoutContactState = 'FETCHING';
        })
        builder.addCase(fetchCheckoutContact.fulfilled, (state, action) => {
            state.checkoutContactState = 'FETCHED';
            state.checkoutContact = action.payload;
        })
        builder.addCase(fetchCheckoutContact.rejected, (state, action) => {
            state.checkoutContactState = 'ERROR';
        })

        builder.addCase(fetchCurrentCheckoutSection.pending, (state, action) => {
            state.sectionStatus = 'FETCHING';
        })
        builder.addCase(fetchCurrentCheckoutSection.fulfilled, (state, action) => {
            state.sectionStatus = 'FETCHED';
            state.currentCheckoutSection = action.payload as CheckoutSectionStateModel;
        })
        builder.addCase(fetchCurrentCheckoutSection.rejected, (state, action) => {
            state.sectionStatus = 'ERROR';
        })



        builder.addCase(fetchCheckoutOrderInfo.fulfilled, (state, action) => {
            state.checkoutOrderInfoState = 'FETCHED';
            state.checkoutOrderInfo = action.payload;
        })
        builder.addCase(fetchCheckoutAddresses.fulfilled, (state, action) => {
            state.checkoutShippingAddress = action.payload.shippingAddress;
            state.checkoutBillingAddress = action.payload.billingAddress;
            state.checkoutCustomerAddresses = action.payload.customerAddresses!;
            state.checkoutHasShippableFulfillmentGroups = action.payload.hasShippableFulfillmentGroups!
            state.checkoutAddressStatus = 'FETCHED';
        })
        builder.addCase(fetchCheckoutCustomer.fulfilled, (state, action) => {
            state.checkoutCustomer = action.payload;
            state.checkoutCustomerStatus = 'FETCHED';
        })

        builder.addCase(fetchCheckoutDelivery.pending, (state, action) => {
            state.checkoutDeliveryState = 'FETCHING';
        })
        builder.addCase(fetchCheckoutDelivery.fulfilled, (state, action) => {
            state.checkoutDelivery = action.payload;
            state.checkoutDeliveryState = 'FETCHED';
        })
        builder.addCase(fetchCheckoutDelivery.rejected, (state, action) => {
            state.checkoutDeliveryState = 'ERROR';
        })

        builder.addCase(fetchCheckoutDeliveryDates.pending, (state, action) => {
            state.checkoutDeliveryDatesState = 'FETCHING';
        })
        builder.addCase(fetchCheckoutDeliveryDates.fulfilled, (state, action) => {
            state.checkoutDeliveryDates = action.payload;
            state.checkoutDeliveryDatesState = 'FETCHED';
        })
        builder.addCase(fetchCheckoutDeliveryDates.rejected, (state, action) => {
            state.checkoutDeliveryDatesState = 'ERROR';
        })


        builder.addCase(fetchCheckoutKeywords.fulfilled, (state, action) => {
            state.checkoutWarningKeywords = action.payload;
            state.checkoutWarningKeywordsStatus = 'FETCHED';
        })

        builder.addCase(fetchCheckoutPayment.pending, (state, action) => {
            state.checkoutPaymentState = 'FETCHING';
        })
        builder.addCase(fetchCheckoutPayment.fulfilled, (state, action) => {
            state.checkoutPaymentState = 'FETCHED';
            state.checkoutPayment = action.payload;
        })
        builder.addCase(fetchCheckoutPayment.rejected, (state, action) => {
            state.checkoutPaymentState = 'ERROR';
        })
    }
});

const publicActions = {
    fetchCheckoutOrder,
    fetchCheckoutContact,
    fetchCurrentCheckoutSection,
    fetchCheckoutOrderInfo,
    fetchCheckoutAddresses,
    fetchCheckoutCustomer,
    fetchCheckoutDelivery,
    fetchCheckoutDeliveryDates,
    fetchCheckoutKeywords,
    fetchCheckoutPayment
}

export {reducer as checkoutReducer};
export {publicActions as checkoutActions};
export {publicSelectors as checkoutSelectors};