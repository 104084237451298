import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {sanitize} from "../../../../../lib/utility";

export interface CheckoutAddressPlzCityFieldProps {
    plzError: string
    plz: string
    setPlzError: (error: string) => void
    setPlz: (plz: string) => void
    cityError: string
    city: string
    setCityError: (error: string) => void
    setCity: (city: string) => void
    updateCityPerPlz: (plz: string) => void
}

export function CheckoutAddressPlzCityField(props: CheckoutAddressPlzCityFieldProps) {

    const intl = useIntl();

    return (
        <div className={"tw-mt-8"}>
            <div className={"tw-flex tw-flex-row"}>
                <div className={"tw-flex tw-flex-col tw-pr-4 md:tw-pr-16"}>
                    <div className={`${props.plzError ? 'tw-text-red' : 'tw-text-black'}`}>
                        <FormattedMessage id={'cart.postalCode'}/> *
                    </div>
                    <div className={""}>
                        <input
                            value={props.plz ? props.plz : ''}
                            onChange={(e) => {
                                let inputValue = parseInt(e.target.value);
                                if (e.target.value.length <= 4) {
                                    props.setPlzError('');
                                    props.setPlz(sanitize(inputValue.toString()));
                                    if (e.target.value.length === 4) {
                                        props.updateCityPerPlz(e.target.value);
                                    }
                                }
                            }}
                            placeholder={intl.formatMessage({id: 'cart.postalCode'})}
                            className={`${props.plzError ? 'tw-border-red' : 'tw-border-grey'} tw-border-2 tw-p-6 tw-text-2xl tw-w-36 md:tw-w-56`}
                            type={"number"}/>
                    </div>
                </div>
                <div className={"tw-flex tw-flex-1 tw-flex-col"}>
                    <div className={`${props.cityError ? 'tw-text-red' : 'tw-text-black'} tw-flex tw-flex-1`}>
                        <FormattedMessage id={'cart.cityState'}/> *
                    </div>
                    <div className={"tw-flex tw-flex-1"}>
                        <input
                            value={props.city ? props.city : ''}
                            onChange={(e) => {
                                props.setCityError('');
                                props.setCity(sanitize(e.target.value))
                            }}
                            placeholder={intl.formatMessage({id: 'cart.cityState'})}
                            className={`${props.cityError ? 'tw-border-red' : 'tw-border-grey'} tw-border-2 tw-flex-1 tw-p-6 tw-text-2xl`}
                            type={"text"}/>
                    </div>
                </div>
            </div>
            {props.plzError && (
                <div className={"tw-text-red tw-ml-2 tw-mt-2"}>
                    <FormattedMessage id={props.plzError}/>
                </div>
            )}
            {props.cityError && (
                <div className={"tw-text-red tw-ml-2 tw-mt-2"}>
                    <FormattedMessage id={props.cityError}/>
                </div>
            )}
        </div>
    )

}