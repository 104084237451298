import {ReactElement} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import FavoritesImage from "./Icon_Favorites.png"
import parse from "html-react-parser";

export default function FavoritesEmptyPage() : ReactElement {

    const intl = useIntl();

    return(
        <div className={"tw-flex tw-flex-1 tw-mb-32"}>
            <div className={"tw-flex tw-flex-1 tw-flex-col tw-items-center tw-mt-16"}>
                <div>
                    <img
                        className={"tw-h-favoritesImage"}
                        src={FavoritesImage}
                        alt={"Favorites"}
                    />
                </div>
                <div className={"tw-mt-8 tw-text-3xl sm:tw-text-4xl tw-font-gotham tw-font-bold tw-text-center"}>
                    <FormattedMessage id={"frontend.favorites.empty.title"} />
                </div>
                <div className={"tw-text-center tw-mt-8"}>
                    {parse(intl.formatMessage({id: "frontend.favorites.empty.line1"}))}
                </div>
                <div className={"tw-text-center tw-mt-8"}>
                    {parse(intl.formatMessage({id: "frontend.favorites.empty.line2"}))}
                </div>
            </div>
        </div>
    );

}