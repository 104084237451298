import {ReactElement, ReactNode} from "react";
import {ProductCardMode} from "../productCardModel";
import {omit} from "ramda";

export interface ClickEventActionRowProps {
	clickEvent: () => void
	children: ReactNode
}

export interface ClickEventActionRowResponsiveProps extends ClickEventActionRowProps {
	mode: ProductCardMode
}

export function ClickEventActionRowResponsive(props: ClickEventActionRowResponsiveProps) {
	const childProps = omit(['mode'], props);
	switch (props.mode) {
		case "tall":
			return <ClickEventActionRowTall {...childProps} />
		case "compact":
			return <ClickEventActionRowCompact {...childProps} />
	}
}

export function ClickEventActionRowTall(props: ClickEventActionRowProps): ReactElement {
	return (
		<div onClick={props.clickEvent}
		   className={`tw-flex tw-justify-center tw-cursor-pointer
                       tw-bg-black tw-text-white tw-border-t tw-border-black
                       tw-p-6 tw-mt-0 tw-text-2xl tw-font-semibold
                       hover:tw-bg-white hover:tw-text-black hover:tw-border-black`}>
			{props.children}
		</div>
	);

}

export function ClickEventActionRowCompact(props: ClickEventActionRowProps): ReactElement {
	return (
		<div onClick={props.clickEvent}
		   className={`tw-flex tw-justify-center tw-cursor-pointer
                       tw-bg-black tw-text-white tw-border-t tw-border-black
                       hover:tw-bg-white hover:tw-text-black hover:tw-border-black`}>
			{props.children}
		</div>
	);

}