export interface PromiseHolder<T> {
    readonly promise: Promise<T>
    readonly resolve: (param: T) => void
    readonly reject: () => void
}

/**
 * hacky hack for creating promises and access their resolve / reject functions
 * outside of the constructor
 */
export function createPromiseHolder<T>(): PromiseHolder<T> {
    let resolve: null | PromiseHolder<T>['resolve'] = null;
    let reject: null | PromiseHolder<T>['reject'] = null;
    const promise = new Promise<T>((resolveFn, rejectFn) => {
        resolve = resolveFn;
        reject = rejectFn;
    });

    return {
        promise,
        resolve: resolve!,
        reject: reject!
    }
}
