import {get} from "./api";

const UTILITIES_LOCATION_ENDPOINT = "/utilities/location";

export interface CityByZipResponse {
    locationFound: boolean
    locationName: string
}

export async function getCityByZip(zip: string): Promise<CityByZipResponse> {
    return await get({
        url: UTILITIES_LOCATION_ENDPOINT + "/" + zip
    });
}