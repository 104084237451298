const addLeadingZero = (input : string): string => {
	if(input.length === 1) {
		return '0' + input;
	}
	return input;
}

export const getAge = (day: string, month: string, year: string): number => {
	let dateString = year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);
	let today = new Date();
	let birthDate = new Date(dateString);
	let age = today.getFullYear() - birthDate.getFullYear();
	let m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
}