import {useIntl} from "react-intl";
import {sanitize} from "../../../../../lib/utility";
import React, {useState} from "react";
import {CheckoutDeliveryOtherInstructionField} from "./CheckoutDeliveryOtherInstructionField";

interface CheckoutDeliveryDeliverOtherInstructionFieldProps {
    whereToDeliverOtherText: string;
    setWhereToDeliverOtherText: (whereToDeliverOther: string) => void;
    whereToDeliverOtherInstructionWarning: string,
    setWhereToDeliverOtherInstructionWarning: (whereToDeliverOtherInstructionWarning: string) => void
}

export function CheckoutDeliveryDeliverOtherInstructionField(props: CheckoutDeliveryDeliverOtherInstructionFieldProps) {

    const warningKeys: string[] = ["Wohnung", "Stock", "Geschoss", "Etage", "Appartamento", "piano", "Appartement", "étage"];
    const [containsWarningKeys, setContainsWarningKeys] = useState<boolean>(false);

    const intl = useIntl();

    const instructionContainsWarningKey = (instruction: string) => {
        return warningKeys.some(keyword => instruction.toLowerCase().includes(keyword.toLowerCase()));
    }

    const changeDeliveryInstruction = (instruction: string) => {
        instruction = sanitize(instruction);

        if(instruction.length <= 25) {
            props.setWhereToDeliverOtherText(instruction)
        }

        if(instructionContainsWarningKey(instruction)) {
            props.setWhereToDeliverOtherInstructionWarning(intl.formatMessage({id: "sevendays.deliver.other.warning"}))
            setContainsWarningKeys(true)
        } else {
            props.setWhereToDeliverOtherInstructionWarning('')
            setContainsWarningKeys(false)
        }
    }

    return (
        <CheckoutDeliveryOtherInstructionField
            text={props.whereToDeliverOtherText}
            warning={props.whereToDeliverOtherInstructionWarning}
            changeInstruction={changeDeliveryInstruction}
            containsWarningKeys={containsWarningKeys}
        />
    )
}