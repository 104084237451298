import {ApiVolatileProductInfo, getVolatileProductInfo} from "lib/api/productApi";
import {AnyAction, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit";
import {difference, fromPairs, isEmpty, merge, union} from "ramda";

type AdvancedSearchRootState = { advancedSearch: AdvancedSearchState };
type AdvancedSearchThunk<ReturnType = void> = ThunkAction<ReturnType, AdvancedSearchRootState, unknown, AnyAction>

export interface AdvancedSearchPortalSlot {
    resultIndex: number
    domId: string
}

interface AdvancedSearchState {
    volatileProductInfos: {
        productInfoMap: VolatileProductInfoMap
        knownProductIds: number[]
    },
    portals: {
        slots: AdvancedSearchPortalSlot[],
        ready: boolean
    }
}

type VolatileProductInfoMap = { [productId: number]: ApiVolatileProductInfo };

const initialState: AdvancedSearchState = {
    volatileProductInfos: {
        productInfoMap: {},
        knownProductIds: []
    },
    portals: {
        slots: [],
        ready: false
    }
}

const {actions, reducer} = createSlice({
    name: 'advancedSearch',
    initialState,
    reducers: {
        addVolatileProductInfos(state: AdvancedSearchState, action: PayloadAction<VolatileProductInfoMap>) {
            state.volatileProductInfos.productInfoMap = merge(state.volatileProductInfos.productInfoMap, action.payload);
        },
        addKnownProductIds(state: AdvancedSearchState, action: PayloadAction<number[]>) {
            state.volatileProductInfos.knownProductIds =
                union(state.volatileProductInfos.knownProductIds, action.payload)
        },
        addPortal(state: AdvancedSearchState, action: PayloadAction<AdvancedSearchPortalSlot>) {
            state.portals.slots.push(action.payload);
        },
        setPortalsReady(state: AdvancedSearchState, action: PayloadAction<boolean>) {
            state.portals.ready = action.payload;
        }
    }
});

const requestVolatileProductInfos = (productIds: number[]): AdvancedSearchThunk<Promise<void>> =>
    (dispatch, getState) => {
        const knownProductIds = getState().advancedSearch.volatileProductInfos.knownProductIds;
        const unknownProducts = difference(productIds, knownProductIds);

        if (!isEmpty(unknownProducts)) {
            dispatch(actions.addKnownProductIds(productIds));
            return getVolatileProductInfo(unknownProducts)
                .then(volatileProductInfos => {
                    const newProductInfos = fromPairs(volatileProductInfos.map(vpi => [vpi.productId, vpi]));
                    dispatch(actions.addVolatileProductInfos(newProductInfos));
                });
        } else {
            return Promise.resolve();
        }
    }

const publicActions = {
    requestVolatileProductInfos,
    addPortal: actions.addPortal,
    setPortalsReady: actions.setPortalsReady
}

const publicSelectors = {
    getVolatileProductInfos: (state: AdvancedSearchRootState) => state.advancedSearch.volatileProductInfos.productInfoMap,
    getPortalSlots: (state: AdvancedSearchRootState) => state.advancedSearch.portals.slots,
    getPortalsReady: (state: AdvancedSearchRootState) => state.advancedSearch.portals.ready
}

export {reducer as advancedSearchReducer};
export {publicActions as advancedSearchActions};
export {publicSelectors as advancedSearchSelectors};
