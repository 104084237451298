import {ReactElement, ReactNode} from "react";
import {ProductCardMode} from "components/product-card/productCardModel";
import {omit} from "ramda";

export interface LinkActionRowProps {
	link: string
	children: ReactNode
}

export interface LinkActionRowResponsiveProps extends LinkActionRowProps {
	mode: ProductCardMode
}

export function LinkActionRowResponsive(props: LinkActionRowResponsiveProps) {
	const childProps = omit(['mode'], props);
	switch (props.mode) {
		case "tall":
			return <LinkActionRowTall {...childProps} />
		case "compact":
			return <LinkActionRowCompact {...childProps} />
	}
}

export function LinkActionRowTall(props: LinkActionRowProps): ReactElement {
	return (
		<a href={props.link}
		   className={`tw-flex tw-justify-center tw-cursor-pointer
                       tw-bg-black tw-text-white tw-border-t tw-border-black
                       tw-p-6 tw-mt-0 tw-text-2xl tw-font-semibold
                       hover:tw-bg-white hover:tw-text-black hover:tw-border-black`}>
			{props.children}
		</a>
	);

}

export function LinkActionRowCompact(props: LinkActionRowProps): ReactElement {
	return (
		<a href={props.link}
		   className={`tw-flex tw-justify-center tw-cursor-pointer
                       tw-bg-black tw-text-white tw-border-t tw-border-black
                       hover:tw-bg-white hover:tw-text-black hover:tw-border-black`}>
			{props.children}
		</a>
	);

}
