import {ReactElement} from "react";

export function MvpBadge() : ReactElement {

    return(
        <div className={"tw-absolute tw--right-4 tw-mt-4"}>
            <div className={"tw-bg-black tw-px-4 tw-py-2"}>
                <img className={"tw-h-mvpBadge"} src={"/img/mvp/mvp-logo.svg"} alt={"Mövenpick"} />
            </div>
            <div className={"tw-absolute tw-right-0"}>
                <svg height="10" width="10" fill="#D8D4CF">
                    <polygon points="0,10 10,0 0,0" />
                </svg>
            </div>
        </div>
    );

}