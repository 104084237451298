import {createContext, ReactElement, ReactNode, useContext} from "react";
import {IntlProvider} from "react-intl";
import {BlLocale, DefaultBlLocale} from "lib/BlLocale";
import {IntlErrorCode} from "@formatjs/intl";
import {Translations} from "lib/api/translationsApi";
import {useDispatch, useSelector} from "react-redux";
import {i18nActions, i18nReducer, i18nSelectors} from "./i18nSlice";
import {reduxStore} from "lib/redux/reduxStore";

reduxStore.injectReducer('i18n', i18nReducer);

export interface I18nProps {
    children: ReactNode
}

export type LanguageTranslations = { [locale in BlLocale]?: Translations }

export function I18n(props: I18nProps): ReactElement {
    const dispatch = useDispatch();
    const currentLocale = useSelector(i18nSelectors.getCurrentLocale);
    const translations = useSelector(i18nSelectors.getTranslations);
    const intlLocale = blLocaleToIntlLocale(currentLocale);


    return <IntlProvider
        messages={translations}
        locale={intlLocale}
        defaultLocale="de-CH"
        onError={error => {
            if (
                error.code === IntlErrorCode.MISSING_TRANSLATION &&
                typeof error.descriptor?.id === 'string'
            ) {
                dispatch(i18nActions.fetchTranslationBatched(currentLocale, [error.descriptor.id]));
            }
        }}
    >
        <BlLocaleContext.Provider value={currentLocale}>
            {props.children}
        </BlLocaleContext.Provider>
    </IntlProvider>
}

const BlLocaleContext = createContext<BlLocale>(DefaultBlLocale);

export function useBlLocale() {
    return useContext(BlLocaleContext);
}

function blLocaleToIntlLocale(blLocale: BlLocale): string {
    switch (blLocale) {
        case "de_CH":
            return 'de-CH';
        case "fr":
            return 'fr-CH';
        case "it":
            return 'it-CH';
    }
}

