import {ReactElement} from "react";

export interface PaginationProps {
	pages: number,
	setPage: (index: number) => void,
	page: number
}

export default function Pagination(props: PaginationProps): ReactElement {

	return (
		<div className={"tw-flex tw-flex-1 tw-flex-row tw-justify-center"}>
			{[...Array(props.pages)].map((e, i) => (
				<div
					className={`tw-cursor-pointer tw-py-4 tw-px-6 tw-border-grey tw-border tw-m-4 tw-rounded-md ${i + 0 === props.page && 'tw-bg-grey tw-text-white'}`}
					onClick={() => props.setPage(i)}>{i + 1}</div>
			))}
		</div>
	)

}