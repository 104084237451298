import {ReactElement} from "react";
import {FormattedMessage} from "react-intl";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "lib/responsive-breakpoints";

import ConfettiMobile from "./confetti-mobile.svg";
import ConfettiDesktop from "./confetti-desktop.svg";

export interface BlackFridayLotterySuccessProps {
    prizeName: string
    prizeImage: string
}

export function BlackFridayLotterySuccess(props: BlackFridayLotterySuccessProps): ReactElement {
    const isDesktop = useMediaQuery(mediaQueries.sm);
    const confetti = isDesktop ? ConfettiDesktop : ConfettiMobile;
    const imageSource = `${props.prizeImage}?productMainMobile`; // ?productMainMobile has max height 300, that's enough
    return (
        <div id="black-friday-lottery-success"
             className="tw-w-full tw-bg-white tw-pt-10 tw-pb-32 tw-relative
                        md:tw-pt-14 md:tw-pb-28 md:tw-overflow-hidden md:tw-px-80">
            <div className="tw-mx-14 tw-text-center tw-text-black md:tw-mb-16">
                <h2 className="tw-font-gotham tw-font-bold tw-text-4xl tw-p-0 tw-mb-4">
                    <FormattedMessage id="frontend.blackfriday.lottery.successPopup.heading"/>
                </h2>
                <h3 className="tw-text-3xl tw-text-black">
                    <FormattedMessage
                        id="frontend.blackfriday.lottery.successPopup.subHeading"
                        values={{prizeName: props.prizeName}}
                    />
                </h3>
            </div>

            <div className="tw-mx-2 tw-mt-5 tw-pt-24 tw-px-28 tw-bg-center
                            md:tw-pt-0 md:tw-px-12"
                 style={{"backgroundImage": `url(${confetti})`}}>
                <div className="tw-rounded-full tw-bg-red tw-p-16 tw-mx-auto
                                md:tw-p-24 md:tw-max-w-xl">
                    <img
                        src={imageSource}
                        alt={props.prizeName}
                    />
                </div>
            </div>

        </div>
    );
}
