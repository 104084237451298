import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {sanitize} from "../../../../../lib/utility";

interface CheckoutAddressFirstNameFieldProps {
    firstNameError: string
    setFirstNameError: (error: string) => void
    firstName: string
    setFirstName: (firstName: string) => void
}

export function CheckoutAddressFirstNameField(props: CheckoutAddressFirstNameFieldProps) {

    const intl = useIntl();

    return (
        <div className={"tw-mt-8"}>
            <div className={`${props.firstNameError ? 'tw-text-red' : 'tw-text-black'} tw-flex tw-flex-1`}>
                <FormattedMessage id={'cart.firstName'}/> *
            </div>
            <div className={"tw-flex tw-flex-1"}>
                <input
                    value={props.firstName ? props.firstName : ''}
                    onChange={(e) => {
                        props.setFirstNameError('')
                        props.setFirstName(sanitize(e.target.value))
                    }}
                    placeholder={intl.formatMessage({id: 'cart.firstName'})}
                    className={`${props.firstNameError ? 'tw-border-red' : 'tw-border-grey'} tw-border-2 tw-flex-1 tw-p-6 tw-text-2xl`}
                    type={"text"}/>
            </div>
            {props.firstNameError && (
                <div className={"tw-text-red tw-ml-2 tw-mt-2"}>
                    <FormattedMessage id={props.firstNameError}/>
                </div>
            )}
        </div>
    )

}