import {StatsProvided} from "react-instantsearch-core";
import {connectStats} from "react-instantsearch-dom";
import {ReactElement} from "react";
import {FormattedMessage} from "react-intl";

export interface SearchbarResultsButtonProps {
	searchTerm: string,
	onClick: () => void
}

function ResultsButton(props: SearchbarResultsButtonProps & StatsProvided): ReactElement {
	const hasTerm = props.searchTerm.length > 0;
	const disableButton = props.nbHits === 0 || !hasTerm;

	return <button
		disabled={disableButton}
		className="tw-block tw-bg-grey tw-h-20 tw-w-full tw-py-5 tw-leading-10 tw-text-center tw-text-white hover:tw-text-white hover:tw-no-underline disabled:tw-opacity-50"
		onClick={props.onClick}>
		<ButtonLabel
			searchTerm={props.searchTerm}
			nbHits={props.nbHits}
		/>
	</button>
}

function ButtonLabel(props: { searchTerm: string, nbHits: number }): ReactElement {
	if (props.nbHits === 0) {
		return <FormattedMessage
			id="frontend.header-search.results-button.noHits"
			values={{
				searchTerm: props.searchTerm,
				strong: (chunks: string) => <strong>{chunks}</strong>
			}}
		/>;
	} else if (props.searchTerm.length === 0) {
		return <FormattedMessage id="frontend.header-search.results-button.noTerm"/>;
	} else {
		return <FormattedMessage
			id="frontend.header-search.results-button.showResults"
			values={{
				searchTerm: props.searchTerm,
				noHits: props.nbHits,
				strong: (chunks: string) => <strong>{chunks}</strong>
			}}
		/>
	}
}

export const HeaderSearchResultsButton = connectStats(ResultsButton);
