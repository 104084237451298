import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {ELEVATOR_TYPE, WHERE_TO_DELIVER} from "../CheckoutSectionDeliveryRow";
import {CheckoutDeliveryDeliverOtherInstructionField} from "./CheckoutDeliveryDeliverOtherInstructionField";

interface CheckoutSectionDeliveryWhereToDeliverFieldProps {
    whereToDeliver?: string;
    setWhereToDeliver: (whereToDeliver: WHERE_TO_DELIVER) => void;
    whereToDeliverOtherText: string;
    setWhereToDeliverOtherText: (whereToDeliverOther: string) => void;
    whereToDeliverOtherInstructionWarning: string,
    setWhereToDeliverOtherInstructionWarning: (whereToDeliverOtherInstructionWarning: string) => void
    whereToDeliverError: string;
    setWhereToDeliverError: (whereToDeliverError: string) => void;
    floor: string;
    setFloor: (floor: string) => void;
    floorError: string;
    setFloorError: (floorError: string) => void;
    elevator: string;
    setElevator: (elevator: ELEVATOR_TYPE) => void;
    elevatorError: string;
    setElevatorError: (elevatorError: string) => void;
    showElevatorSelection: boolean;
    setShowElevatorSelection: (showElevatorSelection: boolean) => void;
    showFloorTextInput: boolean;
    setShowFloorTextInput: (showFloorTextInput: boolean) => void;
    floorValues: string[];
    isCargoDelivery: () => boolean;
}

export function CheckoutDeliveryWhereToDeliverField(props: CheckoutSectionDeliveryWhereToDeliverFieldProps) {

    const intl = useIntl();

    function resetFields() {
        props.setWhereToDeliverError('');
        props.setWhereToDeliverOtherText('');
        props.setWhereToDeliverOtherInstructionWarning('');
        props.setFloor('');
        props.setFloorError('');
        props.setElevator('INIT');
        props.setElevatorError('');
        props.setShowElevatorSelection(false);
        props.setShowFloorTextInput(false);
    }

    return (
        <div className={"tw-flex tw-flex-1 tw-flex-col tw-mt-8"}>
            <div className={"tw-text-3xl tw-font-semibold"}>
                <FormattedMessage id={'cart.deliveryInfo.where.to.delivery'}/>
            </div>
            <div>
                <div className={`${props.whereToDeliverError ? 'tw-text-red' : 'tw-text-black'} radio`}>
                    <label>
                        <input type="radio" checked={props.whereToDeliver === "DOOR"} onChange={() => {
                            props.setWhereToDeliver('DOOR');
                            resetFields();
                        }}/>
                        <FormattedMessage id={'sevendays.deliver.door'}/>
                    </label>
                </div>
                {!props.isCargoDelivery() && (
                    <div className={`${props.whereToDeliverError ? 'tw-text-red' : 'tw-text-black'} radio`}>
                        <label>
                            <input type="radio" checked={props.whereToDeliver === "FLOOR"}
                                   onChange={() => {
                                       props.setWhereToDeliver('FLOOR');
                                       resetFields();
                                   }}/>
                            <FormattedMessage id={'sevendays.door'}/>
                        </label>
                    </div>
                )}
                <div className={`${props.whereToDeliverError ? 'tw-text-red' : 'tw-text-black'} radio`}>
                    <label>
                        <input type="radio" checked={props.whereToDeliver === "GARAGE"}
                               onChange={() => {
                                   props.setWhereToDeliver('GARAGE');
                                   resetFields()
                               }}/>
                        <FormattedMessage id={'sevendays.deliver.garage'}/>
                    </label>
                </div>
                <div className={`${props.whereToDeliverError ? 'tw-text-red' : 'tw-text-black'} radio`}>
                    <label>
                        <input type="radio" checked={props.whereToDeliver === "OTHER"}
                               onChange={() => {
                                   props.setWhereToDeliver('OTHER');
                                   resetFields()
                               }}/>
                        <FormattedMessage id={'sevendays.deliver.other'}/>
                        {props.whereToDeliver === 'OTHER' &&
                            <CheckoutDeliveryDeliverOtherInstructionField
                                whereToDeliverOtherText={props.whereToDeliverOtherText}
                                setWhereToDeliverOtherText={props.setWhereToDeliverOtherText}
                                whereToDeliverOtherInstructionWarning={props.whereToDeliverOtherInstructionWarning}
                                setWhereToDeliverOtherInstructionWarning={props.setWhereToDeliverOtherInstructionWarning}
                            />
                        }
                    </label>
                </div>
            </div>
            {props.whereToDeliverError && (
                <div className={"tw-text-red tw-mt-4"}>
                    {props.whereToDeliverError}
                </div>
            )}
            {props.whereToDeliver === 'FLOOR' && (
                <div className={"tw-mt-4"}>
                    <div className={'tw-text-2xl tw-font-semibold'}>
                        <FormattedMessage id={'sevendays.delivery.which.floor'}/>
                    </div>
                    <div>
                        <select
                            className={`tw-border ${props.floorError ? 'tw-border-red' : 'tw-border-grey'} tw-p-4 tw-mt-2 tw-cursor-pointer`}
                            onChange={(e) => {
                                props.setFloor(e.target.value);
                                props.setShowElevatorSelection(e.target.value !== 'EG');
                                props.setShowFloorTextInput(e.target.value === 'other');
                            }}
                            defaultValue={props.floor ? (props.floorValues.includes(props.floor) ? props.floor : 'other') : ('select')}>
                            <option disabled value={'select'}>
                                {intl.formatMessage({id: 'sevendays.select.floor'})}
                            </option>
                            {props.floorValues.map((value, i) => (
                                <option key={i} value={value}>{value}</option>
                            ))}
                            <option value={'other'}>
                                {intl.formatMessage({id: 'sevendays.select.floor.other'})}
                            </option>
                        </select>
                    </div>
                    {props.floorError && (
                        <div className={"tw-text-red tw-mt-4"}>
                            {props.floorError}
                        </div>
                    )}
                    {props.showFloorTextInput &&
                        <div className={"tw-mt-4 tw-flex tw-flex-1"}>
                            <input
                                className={`tw-border tw-w-24 ${props.floorError ? 'tw-border-red' : 'tw-border-grey'} tw-p-4 tw-mt-2`}
                                onChange={(e) => {
                                    props.setFloor(e.target.value);
                                }}
                                maxLength={3}
                                value={props.floor}
                                type={'number'}
                            />
                            <div
                                className={`${props.floorError ? 'tw-text-red' : 'tw-text-black'} tw-ml-4 tw-flex tw-flex-1 tw-items-center`}>
                                <FormattedMessage id={'sevendays.select.floor.number'}/>
                            </div>
                        </div>
                    }
                    {props.showElevatorSelection &&
                        <>
                            <div className={'tw-text-2xl tw-font-semibold tw-mt-6'}>
                                <FormattedMessage id={'sevendays.is.elevator'}/>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" checked={props.elevator === 'YES'} onChange={() => {
                                        props.setElevatorError('');
                                        props.setElevator('YES');
                                    }}/>
                                    <FormattedMessage id={'sevendays.yes'}/>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" checked={props.elevator === 'NO'} onChange={() => {
                                        props.setElevatorError('');
                                        props.setElevator('NO');
                                    }}/>
                                    <FormattedMessage id={'sevendays.no'}/>
                                </label>
                            </div>
                            <div className={"tw-text-xl"}>
                                <FormattedMessage id={'sevendays.elevator.service.fee'}/>
                            </div>
                            {props.elevatorError && (
                                <div className={"tw-text-red tw-mt-4"}>
                                    {props.elevatorError}
                                </div>
                            )}
                        </>
                    }
                </div>
            )}
        </div>
    );
}