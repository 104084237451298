import {ReactElement, ReactNode, useState} from "react";
import {AddToCartButton} from "widgets/cartbutton/AddToCartButton";
import {ProductCore} from "lib/model/product/ProductModel";
import {showNotification} from "lib/utility";
import {favoriteActions, favoriteListSelectors} from "../../favorites/favoriteListSlice";
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {useAppDispatch} from "lib/redux/reduxHooks";
import {FavoriteIcon} from "widgets/FavoriteIcon";
import {ProductCardMode} from "components/product-card/productCardModel";
import {CountInput, DefaultDecrementButton, DefaultIncrementButton} from "widgets/CountInput";
import {ReactComponent as DecrementButtonSvg} from "widgets/DecrementButton.svg";
import {ReactComponent as IncrementButtonSvg} from "widgets/IncrementButton.svg";
import {
	favoriteInclusiveLastOrdersActions,
	favoriteInclusiveLastOrdersListSelectors
} from "../../favorites-inclusive-last-orders/favoriteInclusiveLastOrdersSlice";

export interface DefaultActionRowProps {
	mode: ProductCardMode
	product: ProductCore
	cartTrackingClass?: string
	children: ReactNode
	blackFridayActive: boolean
	orderId?: number
	isGridView?: boolean
}

export function DefaultActionRow(props: DefaultActionRowProps): ReactElement {

	const intl = useIntl();
	const dispatch = useAppDispatch();
	const [currentCount, setCurrentCount] = useState(1);

	const isFavoritesInclusiveLastOrders = document.getElementById("favoritesInclusiveLastOrders") as HTMLInputElement;

	const isFavoriteSelector = useSelector(favoriteListSelectors.isFavorite(props.product.productId));
	const isFavoriteInclusiveLastOrderSelector = useSelector(favoriteInclusiveLastOrdersListSelectors.isFavorite(props.product.productId));

	const isFavorite = isFavoritesInclusiveLastOrders ? isFavoriteInclusiveLastOrderSelector : isFavoriteSelector;

	const trackFavoritesEvent = (favorite: boolean) => {
		// @ts-ignore
		window.dataLayer.push({
			'event': 'Tracking Event',
			'category': 'User Tracking',
			'action': 'Favourites - Item' + (favorite ? ' Added' : ' Removed'),
			'label': window.location.href
		});
	}

	const addToWishlistEvent = () => {
		const price = props.product.pricingInfo?.salePrice ? props.product.pricingInfo?.salePrice : props.product.pricingInfo?.retailPrice
		// @ts-ignore
		window.dataLayer.push({ ecommerce: null });
		// @ts-ignore
		window.dataLayer.push({
			event: "add_to_wishlist",
			ecommerce: {
				currency: "CHF",
				value: price,
				items: [
					{
						item_id: props.product.productId,
						item_name: props.product.name,
						item_category: props.product.type,
						price: price,
						packaging_info: props.product.packagingInfo.type
					}]
			}
		})
	}

	const addToCartEvent = (quantity: number) => {
		const price = props.product.pricingInfo?.salePrice ? props.product.pricingInfo?.salePrice : props.product.pricingInfo?.retailPrice
		// @ts-ignore
		window.dataLayer.push({ ecommerce: null });
		// @ts-ignore
		window.dataLayer.push({
			event: "add_to_cart",
			ecommerce: {
				currency: "CHF",
				value: price,
				items: [
					{
						item_id: props.product.productId,
						item_name: props.product.name,
						item_category: props.product.type,
						price: price,
						packaging_info: props.product.packagingInfo.type,
						quantity: quantity
					}]
			}
		})
	}

	const setFavorite = (favorite: boolean) => {
		trackFavoritesEvent(favorite);

		if(favorite) {
			addToWishlistEvent()
		}

		if(isFavoritesInclusiveLastOrders) {
			if (favorite) {
				dispatch(favoriteInclusiveLastOrdersActions.addFavoriteProductId(props.product.productId));
			} else {
				dispatch(favoriteInclusiveLastOrdersActions.removeFavoriteProductId(props.product.productId));
			}
		} else {
			if (favorite) {
				dispatch(favoriteActions.addFavoriteProductId(props.product.productId));
			} else {
				dispatch(favoriteActions.removeFavoriteProductId(props.product.productId));
			}
		}
		showNotification(props.product.name + " " + intl.formatMessage({id: isFavorite ? 'frontend.favorites.remove' : 'frontend.favorites.add'}));
	}

	switch (props.mode) {
		case "tall":
			return (
				<DefaultActionRowTallComponent
					blackFridayActive={props.blackFridayActive}
					orderId={props.orderId}
					productId={props.product.productId}
					currentCount={currentCount}
					setCount={setCurrentCount}
					isFavorite={isFavorite}
					setFavorite={setFavorite}
					cartTrackingClass={props.cartTrackingClass}
					addToCartEvent={addToCartEvent}
				/>
			);
		case "compact":
			return (
				<DefaultActionRowCompactComponent
					blackFridayActive={props.blackFridayActive}
					orderId={props.orderId}
					productId={props.product.productId}
					currentCount={currentCount}
					setCount={setCurrentCount}
					isFavorite={isFavorite}
					setFavorite={setFavorite}
					cartTrackingClass={props.cartTrackingClass}
					addToCartEvent={addToCartEvent}
					isGridView={props.isGridView}
				>
					{props.children}
				</DefaultActionRowCompactComponent>
			);
	}
}

interface DefaultActionRowComponentProps {
	productId: number
	currentCount: number
	setCount: (c: number) => void
	isFavorite: boolean
	setFavorite: (favorite: boolean) => void
	cartTrackingClass?: string
	children?: ReactNode
	orderId?: number
	blackFridayActive: boolean
	addToCartEvent?: (quantity: number) => void
	isGridView?: boolean
}

function DefaultActionRowTallComponent(props: DefaultActionRowComponentProps) {
	return (
		<div className="tw-flex tw-items-start tw-py-4 tw-pr-2 tw-pl-0 tw-border-t-2 tw-border-homeComponentsBg">
			<div className="tw-w-productCardImage">
				<CountInput
					count={props.currentCount}
					onSetCount={props.setCount}
					incrementButton={<IncrementButtonSvg className="tw-w-auto tw-h-12"/>}
					decrementButton={<DecrementButtonSvg className="tw-w-auto tw-h-12"/>}
				/>
			</div>
			<div className="tw-flex tw-flex-1 tw-pt-1.5">
				{props.children}
			</div>
			<div className="tw-self-center tw-flex tw-items-center tw-mr-4">
				<FavoriteIcon
					setFavorite={props.setFavorite}
					isFavorite={props.isFavorite}
				/>
				<AddToCartButton
					blackFridayActive={props.blackFridayActive}
					orderId={props.orderId}
					quantity={props.currentCount}
					productId={props.productId}
					additionalClass={props.cartTrackingClass}
					addToCartEvent={props.addToCartEvent}
				/>
			</div>
		</div>
	)
}

function DefaultActionRowCompactComponent(props: DefaultActionRowComponentProps) {
	return (
		<>
			<div>
				<div className={`tw-flex ${props.isGridView ? "tw-items-center tw-justify-between" : "tw-items-start"} tw-pb-4`}>
					<div className={`${props.isGridView ? "tw-ml-5" : "tw-w-productCardImage"}`}>
						<CountInput
							count={props.currentCount}
							onSetCount={props.setCount}
							incrementButton={DefaultIncrementButton}
							decrementButton={DefaultDecrementButton}
							isGridView={props.isGridView}
						/>
					</div>
					<div className="tw-flex tw-flex-1 tw-pt-1.5">
						{props.children}
					</div>
					<div className={`tw-flex tw-items-center ${props.isGridView ? "tw-mr-5" : "tw-mr-4"}`}>
						<FavoriteIcon
							setFavorite={props.setFavorite}
							isFavorite={props.isFavorite}
							isGridView={props.isGridView}
						/>
						{!props.isGridView &&
							<AddToCartButton
								blackFridayActive={props.blackFridayActive}
								orderId={props.orderId}
								quantity={props.currentCount}
								productId={props.productId}
								additionalClass={props.cartTrackingClass}
								addToCartEvent={props.addToCartEvent}
							/>
						}
					</div>
				</div>
				{props.isGridView &&
					<AddToCartButton
						blackFridayActive={props.blackFridayActive}
						orderId={props.orderId}
						quantity={props.currentCount}
						productId={props.productId}
						additionalClass={props.cartTrackingClass}
						addToCartEvent={props.addToCartEvent}
						isGridView={props.isGridView}
					/>
				}
			</div>
		</>

	)
}
