import {ReactElement, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "lib/responsive-breakpoints";
import {ProductCore} from "lib/model/product/ProductModel";
import FavoritesEmptyPage from "./FavoritesEmptyPage";
import ProductListStats from "../product-list/ProductListStats";
import Pagination from "./Pagination";
import {ProductCard} from "components/product-card/ProductCard";

export interface FavoritesCustomerProps {
    products: ProductCore[]
    blackFridayActive: boolean
    orderId?: number
}

export default function FavoritesCustomer(props: FavoritesCustomerProps) : ReactElement {

    const isMobile = !useMediaQuery(mediaQueries.sm);

    const [page, setPage] = useState(0);
    const pageSize = 9;
    const startIndex = page * pageSize;

    const pagedProducts = props.products.slice(startIndex , startIndex + pageSize);
    const pages = Math.ceil(props.products.length / pageSize);

    return(
        <div className={`tw-flex tw-flex-1 tw-flex-col ${!isMobile && 'tw-ml-24 tw-mt-20'}`}>
            {!isMobile &&
                <div className={"tw-flex tw-flex-1 tw-mb-4"}>
                    <div className={"tw-flex tw-flex-1 tw-text-4xl tw-font-bold"}>
                        <FormattedMessage id={"frontend.topnavbarmenu.lastarticles.favorites"} />
                    </div>
                </div>
            }
            {props.products.length > 0 ?
                <div className={"tw-flex tw-flex-col"}>
                    <ProductListStats noHits={props.products.length} />
                    <div className={`tw-flex tw-flex-1`}>
                        <div
                            className={`${isMobile ? 'tw-flex tw-flex-col' : 'tw-grid tw-grid-cols-3 tw-gap-10 tw-mt-4 tw-mb-12'}`}>
                            {pagedProducts.map((product, index) => (
                                <div className={`${isMobile ? 'tw-my-4' : ''}`} key={index}>
                                    <ProductCard
                                        blackFridayActive={props.blackFridayActive}
                                        orderId={props.orderId}
                                        mode="responsive"
                                        product={product}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {pages > 1 &&
                        <Pagination
                            pages={pages}
                            setPage={setPage}
                            page={page} />
                    }
                </div> :
                <div className={`tw-flex tw-flex-1`}>
                    <FavoritesEmptyPage />
                </div>
            }
        </div>
    );

}
