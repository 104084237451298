import {FormattedMessage} from "react-intl";
import React from "react";

interface CheckoutDeliverySaveButtonProps {
    saveDelivery: () => void;
}

export function CheckoutDeliverySaveButton(props: CheckoutDeliverySaveButtonProps) {

    return (
        <div className={"tw-flex tw-flex-1 tw-mt-8"}>
            <div className={"tw-flex tw-flex-1 tw-pr-0 md:tw-pr-72"}>
                <div onClick={() => props.saveDelivery()}
                     className={"tw-flex tw-flex-1 tw-border-black tw-bg-black tw-text-white tw-border-2 tw-justify-center tw-py-5 tw-mt-6 " +
                         "hover:tw-cursor-pointer hover:tw-bg-white hover:tw-text-black tw-font-gotham-black tw-italic"}>
                    <FormattedMessage id={"cart.deliveryInformation.submit"}/>
                </div>
            </div>
            <div className={"tw-flex tw-flex-0 md:tw-flex-1"}/>
        </div>
    );

}