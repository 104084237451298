import {FormattedMessage, useIntl} from "react-intl";
import React from "react";

import {ReactComponent as PlusIcon} from "../plus.svg";
import {sanitize} from "../../../../../lib/utility";

export interface CheckoutAddress2FieldProps {
    hasAddress2: boolean
    address2: string
    setHasAddress2: (value: boolean) => void
    setAddress2: (value: string) => void
}

export function CheckoutAddress2Field(props: CheckoutAddress2FieldProps) {

    const intl = useIntl();

    return (
        <>
            {(props.hasAddress2 || props.address2) ?
                <div className={"tw-mt-8"}>
                    <div className={"tw-flex tw-flex-1"}>
                        <FormattedMessage id={'cart.address2'}/>
                    </div>
                    <div className={"tw-flex tw-flex-1"}>
                        <input
                            value={props.address2 ? props.address2 : ''}
                            onChange={(e) => props.setAddress2(sanitize(e.target.value))}
                            placeholder={intl.formatMessage({id: 'cart.address2'})}
                            className={"tw-border-grey tw-border-2 tw-p-6 tw-flex-1 tw-text-2xl"}
                            type={"text"}/>
                    </div>
                </div>
                :
                <div onClick={() => props.setHasAddress2(true)}
                     className={"tw-mt-12 tw-mb-4 tw-flex tw-cursor-pointer"}>
                    <div className={"tw-ml-2"}>
                        <PlusIcon/>
                    </div>
                    <div className={"tw-ml-4 tw-text-2xl"}>
                        <FormattedMessage id={"cart.add.line2"}/>
                    </div>
                </div>
            }
        </>
    )

}