import {ReactElement} from "react";
import {SummaryItemList} from "./SummaryItemList";
import {SummaryNoDeposit} from "./SummaryNoDeposit";
import {SummaryItemSekelton} from "./SummaryItemSekelton";
import {useSelector} from "react-redux";
import {depositInfoSelectors} from "../depositInfoSlice";

interface SummaryProps {
	showEditDeposit: () => void
}

export function Summary(props: SummaryProps) : ReactElement {

	const depositItems = useSelector(depositInfoSelectors.getDepositItems);
	const depositItemsState = useSelector(depositInfoSelectors.getDepositItemsStatus);

	if(depositItemsState === 'FETCHED') {
		if(depositItems.length > 0) {
			return(
				<SummaryItemList
					depositItems={depositItems} />
			);
		} else {
			return(
				<SummaryNoDeposit
					showEditDeposit={props.showEditDeposit} />
			);
		}
	} else {
		return(
			<SummaryItemSekelton />
		);
	}

}