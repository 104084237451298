import {ReactElement, ReactNode} from "react";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "lib/responsive-breakpoints";
import {JustDrinkBottlesXs} from "../../widgets/justdrink/jdbottles/JustDrinkBottlesResponsive";


export interface JustDrinkBannerProps {
    image: JustDrinkBannerImageType | undefined
    title?: ReactNode
    subtitle?: ReactNode
}

const JustDrinkBannerImageTypes = [
    "jd-bottles",
] as const;

export type JustDrinkBannerImageType = typeof JustDrinkBannerImageTypes[number];
export const DEFAULT_JUSTDRINK_BANNER_IMAGE: JustDrinkBannerImageType = "jd-bottles"

export function JustDrinkBanner(props: JustDrinkBannerProps): ReactElement {
    const isMobile = !useMediaQuery(mediaQueries.md);
    const jdBannerImageType = props.image ?? "jd-bottles"
    return (
        <div className="tw-w-full tw-relative tw-h-40 tw-relative
                        md:tw-h-56 md:tw--mt-12">
            <div className="tw-z-10 tw-pl-5 tw-absolute tw-l-0 tw-b-0 tw-h-40 md:tw-h-auto md:tw-pl-20">
                <JustDrinkBannerImage image={jdBannerImageType} />
            </div>
            <div className="tw-z-0 tw-absolute tw-bottom-0 tw-pl-48 tw-left-0 tw-right-0 tw-h-20 tw-bg-homeComponentsBg
                            tw-text-black tw-flex-1
                            tw-flex tw-flex-col tw-justify-center tw-items-center
                            md:tw-pl-0 md:tw-h-32">
                {props.title && (
                    <div className="tw-text-3xl tw-font-gotham-black tw-font-bold md:tw-text-5xl"
                         style={{"fontSize": isMobile ? "18px" : "35px"}}>
                        {props.title}
                    </div>
                )}
                {props.subtitle && (
                    <div className="tw-text-xl tw-font-gotham tw-mt-1 md:tw-text-3xl md:tw-mt-3"
                         style={{"fontSize": isMobile ? "12px" : "16px"}}>
                        {props.subtitle}
                    </div>
                )}
            </div>
        </div>
    )
}

export function getJustDrinkBannerImage(s: string | undefined): JustDrinkBannerImageType {
    return (s != null && isJustDrinkBannerImage(s)) ? s : DEFAULT_JUSTDRINK_BANNER_IMAGE;
}

export function isJustDrinkBannerImage(s: string): s is JustDrinkBannerImageType {
    return JustDrinkBannerImageTypes.includes(s as JustDrinkBannerImageType);
}

interface JustDrinkBannerImageProps {
    image: JustDrinkBannerImageType
}

function JustDrinkBannerImage(props: JustDrinkBannerImageProps): ReactElement {
    switch(props.image) {
        case "jd-bottles":
            return <JustDrinkBottlesXs />;
    }
}