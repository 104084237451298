import {ReactElement} from "react";

export interface SaleBadgeProps {
    discount: string
}

export function SaleBadge(props: SaleBadgeProps) : ReactElement {

    return(
        <div className={"tw-absolute tw--right-4 tw-mt-4"}>
            <div className={"tw-bg-red tw-text-white tw-text-3xl tw-font-semibold tw-py-2 tw-px-4"}>
                <p>{props.discount}%</p>
            </div>
            <div className={"tw-absolute tw-right-0"}>
                <svg height="10" width="10" fill="#D8D4CF">
                    <polygon points="0,10 10,0 0,0" />
                </svg>
            </div>
        </div>
    )

}