import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {sanitize} from "../../../../../lib/utility";

export interface CheckoutAddressFieldProps {
    addressError: string
    setAddressError: (error: string) => void
    address: string
    setAddress: (address: string) => void
}

export function CheckoutAddressField(props: CheckoutAddressFieldProps) {

    const intl = useIntl();

    return (
        <div className={`${props.addressError ? 'tw-text-red' : 'tw-text-black'} tw-mt-8`}>
            <div className={"tw-flex tw-flex-1"}>
                <FormattedMessage id={'cart.address'}/> *
            </div>
            <div className={"tw-flex tw-flex-1"}>
                <input
                    value={props.address ? props.address : ''}
                    onChange={(e) => {
                        props.setAddressError('');
                        props.setAddress(sanitize(e.target.value))
                    }}
                    placeholder={intl.formatMessage({id: 'cart.address'})}
                    className={`${props.addressError ? 'tw-border-red' : 'tw-border-grey'} tw-border-2 tw-flex-1 tw-p-6 tw-text-2xl`}
                    type={"text"}/>
            </div>
            {props.addressError && (
                <div className={"tw-text-red tw-ml-2 tw-mt-2"}>
                    <FormattedMessage id={props.addressError}/>
                </div>
            )}
        </div>
    )

}