import {ReactElement} from "react";
import {ReactComponent as FavoritesIconBlackFilled} from "widgets/favorite-icons/favorites-icon-black-filled.svg";
import {ReactComponent as FavoritesIconBlack} from "widgets/favorite-icons/favorites-icon-black.svg";

export interface FavoritesIconProps {
    isFavorite: boolean,
    setFavorite: (favorite: boolean) => void
    isGridView?: boolean
}

export function FavoriteIcon(props: FavoritesIconProps) : ReactElement {
    return (
        <div className={`${props.isGridView ? "" : "tw-mr-4"} tw-cursor-pointer`} onClick={() => props.setFavorite(!props.isFavorite)}>
            {props.isFavorite ?
                <FavoritesIconBlackFilled /> :
                <FavoritesIconBlack />
            }
        </div>
    );
}
