import {ReactElement} from "react";

export interface ProgressBarProps {
    percent: number
    fillColor: string
    emptyColor: string
}

export function ProgressBar(props: ProgressBarProps): ReactElement {
    return (
        <div className="tw-block tw-rounded-full tw-overflow-hidden tw-w-full tw-h-3 tw-p-0"
             style={{
                 backgroundColor: props.emptyColor
             }}>
            <div className="tw-block tw-h-full"
                 style={{
                     backgroundColor: props.fillColor,
                     width: `${props.percent}%`
                 }}>
            </div>
        </div>
    );
}
