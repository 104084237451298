import {ReactElement, ReactNode, useState} from "react";
import {ProductCore} from "lib/model/product/ProductModel";
import {ProductCardMode} from "components/product-card/productCardModel";
import {LinkActionRowResponsive} from "./LinkActionRow";
import {FormattedMessage} from "react-intl";
import {DefaultActionRow} from "components/product-card/action-rows/DefaultActionRow";
import {ClickEventActionRowResponsive} from "./ClickEventActionRow";
import AdventCalendar from "../../advent-calendar/AdventCalendar";

export interface ActionRowProps {
    mode: ProductCardMode
    product: ProductCore
    children?: ReactNode | undefined
    cartTrackingClass?: string // ga sometimes tracks stuff by class on cart button
    blackFridayActive: boolean
    orderId?: number
    isGridView?: boolean
}

export function ActionRow(props: ActionRowProps): ReactElement {

    const [showAdventCalendar, setShowAdventCalendar] = useState(false);

    if (props.product.badge === 'badge_outOfStock') {
        return <OutOfStockActionRow />
    }

    switch (props.product.type) {
        case "degupack":
            return (
                <LinkActionRowResponsive
                    mode={props.mode}
                    link={props.product.cardActionButtonUrl}>
                    <FormattedMessage id="degupack.button.config.label" />
                </LinkActionRowResponsive>
            );
        case "friendspack":
            return (
                <LinkActionRowResponsive
                    mode={props.mode}
                    link={props.product.cardActionButtonUrl}>
                    <FormattedMessage id="friendspack.button.config.label" />
                </LinkActionRowResponsive>
            );
        case "giftcard":
            return (
                <LinkActionRowResponsive
                    mode={props.mode}
                    link={props.product.cardActionButtonUrl}>
                    <FormattedMessage id={'giftcard.button.config.label'}/>
                </LinkActionRowResponsive>
            );
        case "adventcalendar":
            return (
                <ClickEventActionRowResponsive
                    mode={props.mode}
                    clickEvent={() => {
                        setShowAdventCalendar(true)
                    }}>
                    <FormattedMessage id="adventcalendar.button.config.label" />
                    <AdventCalendar
                        isOpen={showAdventCalendar}
                        setIsOpen={setShowAdventCalendar} />
                </ClickEventActionRowResponsive>
            )
        default:
            return <DefaultActionRow
                blackFridayActive={props.blackFridayActive}
                orderId={props.orderId}
                mode={props.mode}
                product={props.product}
                cartTrackingClass={props.cartTrackingClass}
                isGridView={props.isGridView}
            >
                {props.children}
            </DefaultActionRow>
    }
}

function OutOfStockActionRow(): ReactElement {
    return (
        <div className={"tw-flex tw-justify-center tw-bg-skeletonBackgroundGrey tw-p-6"}>
            <p>
                <FormattedMessage id={'product.unavailable'} />
            </p>
        </div>
    );
}
