import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    FetchStatus,
    getFavoriteProductIdsIInclusiveLastOrders,
    updateFavorites
} from "../../lib/utility";


type FavoriteProductInclusiveLastOrdersListRootState = { favoriteProductInclusiveLastOrdersList: FavoriteProductInclusiveLastOrdersListState }

interface FavoriteProductInclusiveLastOrdersListState {
    favoriteProductIdsIncludedLastOrders: number[],
    isAnonymous: boolean,
    status: FetchStatus
}

const initialState: FavoriteProductInclusiveLastOrdersListState = {
    favoriteProductIdsIncludedLastOrders: [],
    isAnonymous: true,
    status: 'INIT'
}


const publicSelectors = {
    getProductIds: (state: FavoriteProductInclusiveLastOrdersListRootState) => state.favoriteProductInclusiveLastOrdersList.favoriteProductIdsIncludedLastOrders,
    getStatus: (state: FavoriteProductInclusiveLastOrdersListRootState) => state.favoriteProductInclusiveLastOrdersList.status,
    isFavorite: (productId: number | undefined) => (state: FavoriteProductInclusiveLastOrdersListRootState) => productId !== undefined && state.favoriteProductInclusiveLastOrdersList.favoriteProductIdsIncludedLastOrders.includes(productId)
}
const favoriteProductInclusiveLastOrdersList = createAsyncThunk(
    'favoriteProductInclusiveLastOrdersList/create',
    async (isAnonymous: boolean) => {
        const productIds = await getFavoriteProductIdsIInclusiveLastOrders(isAnonymous);
        return {
            productIds,
            isAnonymous
        }
    }
)

const {actions, reducer} = createSlice({
    name: 'favoriteProductInclusiveLastOrdersList',
    initialState,
    reducers: {
        addFavoriteProductId(state: FavoriteProductInclusiveLastOrdersListState, action: PayloadAction<number>) {
            state.favoriteProductIdsIncludedLastOrders.push(action.payload);
            updateFavorites(state.favoriteProductIdsIncludedLastOrders, state.isAnonymous);
        },
        removeFavoriteProductId(state: FavoriteProductInclusiveLastOrdersListState, action: PayloadAction<number>) {
            const index = state.favoriteProductIdsIncludedLastOrders.indexOf(action.payload);
            state.favoriteProductIdsIncludedLastOrders.splice(index, 1);
            updateFavorites(state.favoriteProductIdsIncludedLastOrders, state.isAnonymous);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(favoriteProductInclusiveLastOrdersList.pending, (state, action) => {
                state.status = 'FETCHING'
            })
            .addCase(favoriteProductInclusiveLastOrdersList.fulfilled, (state, action) => {
                state.favoriteProductIdsIncludedLastOrders = action.payload.productIds;
                state.isAnonymous = action.payload.isAnonymous;
                state.status = 'FETCHED'
            })
            .addCase(favoriteProductInclusiveLastOrdersList.rejected, (state, action) => {
                state.status = 'ERROR'
            })
    }
});

const publicActions = {
    addFavoriteProductId: actions.addFavoriteProductId,
    removeFavoriteProductId: actions.removeFavoriteProductId,
    favoriteProductInclusiveLastOrdersList
}

export {reducer as favoriteProductInclusiveLastOrdersListReducer};
export {publicActions as favoriteInclusiveLastOrdersActions};
export {publicSelectors as favoriteInclusiveLastOrdersListSelectors};
