import {ReactElement, useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "lib/responsive-breakpoints";
import BackgroundMobile from "./background-mobile.png";
import BackgroundDesktop from "./background-desktop.png";
import {FormattedMessage} from "react-intl";
import PlayButton from "./play-button.svg";
import {claimPrice} from "lib/api/blackFridayApi";

import {Alignment, Fit, Layout, useRive} from '@rive-app/react-canvas';

const THE_WHEEL_PATH = "/img/rive/black_friday_fortune_23.riv"

export interface BlackFridayWheelProps {
    onPriceClaimed: () => void
    wheelPosition: number
    orderId: number
    closeHandler: () => void
}

export function BlackFridayWheel(props: BlackFridayWheelProps): ReactElement {
    const [claimSuccess, setClaimSuccess] = useState(false);
    const [claimError, setClaimError] = useState<string | null>(null);
    const [wheelStatus, setWheelStatus] = useState<'INIT'|'SPINNING'|'FINISHED'>('INIT');
    const [wheelStarted, setWheelStarted] = useState(false);

    const isDesktop = useMediaQuery(mediaQueries.sm);
    const backgroundImage = isDesktop ? BackgroundDesktop : BackgroundMobile;
    const animation = determineAnimation(props.wheelPosition);

    const playHandler = () => {
        setWheelStatus('SPINNING');
        setWheelStarted(true);
    }

    const stopHandler = () => {
        setWheelStatus('FINISHED');
    };

    const {RiveComponent, rive} = useRive({
        src: THE_WHEEL_PATH,
        animations: animation,
        onPlay: playHandler,
        onStop: stopHandler,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.Center
        })
    });

    const spinHandler = () => {
        claimPrice({orderId: props.orderId})
            .then(() => setClaimSuccess(true))
            .catch(error => setClaimError(error));
        rive?.play();
    }

    const onPriceClaimed = props.onPriceClaimed;
    useEffect(() => {
        if (wheelStatus === 'FINISHED' && wheelStarted && claimSuccess) {
            setTimeout(() => {
                onPriceClaimed();
            }, 1500);
        }
    }, [wheelStatus, wheelStarted, claimSuccess, onPriceClaimed])

    return (
        <>
            <div id="blackfriday-wheel"
                 style={{backgroundImage: `url(${backgroundImage})`}}
                 className="tw-w-full tw-bg-cover tw-pt-10 tw-pb-32 tw-relative
                            md:tw-pt-14 md:tw-pb-28 md:tw-overflow-hidden md:tw-px-80">
                <div className="tw-mx-14 tw-text-center
                                tw-text-black tw-font-bold tw-text-3xl
                                md:tw-text-4xl md:tw-mb-16">
                    <FormattedMessage id="frontend.blackfriday.lottery.wheelPopup.heading" />
                </div>
                {claimError != null ? (
                    <div className="tw-w-full tw-relative
                                    md:tw-h-5/>6"
                         style={{
                             height: isDesktop ? "350px" : "300px"
                         }}
                    >
                        <h1>Da ist was schiefgelaufen!</h1>
                        <p>{claimError}</p>
                    </div>
                ) : (
                    <div className="tw-w-full tw-h-96 tw-relative
                                    md:tw-h-5/6"
                         style={{
                             height: isDesktop ? "350px" : "300px"
                         }}
                         onClick={spinHandler}>
                        <RiveComponent />
                        <div className="tw-flex tw-z-10 tw-absolute tw-inset-0 tw-justify-center tw-items-center tw-cursor-pointer">
                            <img src={PlayButton} alt="Play Button"/>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

function determineAnimation(wheelPosition: number): string {
    switch (wheelPosition) {
        case 1: return "FS_GLASS_gift";
        case 2: return "JUSTDRINK_gift";
        case 3: return "SCB_gift";
        case 4: return "SOM_gift";
        case 5: return "BRAUWELT_gift";
        case 6: return "1664_gift";
        default: return "JUSTDRINK_gift";
    }
}
