import {get, post} from "./api";
import {DepositItemModel} from "../model/depositinfo/DepositItemModel";
import {ApiResponse} from "./myAccountApi";

const BASE_ENDPOINT_URL = "/api/v1/checkout/deposit";

const DEPOSIT_ITEMS_SUGGESTIONS_ENDPOINT = BASE_ENDPOINT_URL + "/suggestions";
const SEARCH_DEPOSIT_ITEMS_ENDPOINT = BASE_ENDPOINT_URL + "/deposit-search";
const DEPOSIT_ITEMS_ENDPOINT = BASE_ENDPOINT_URL + "/deposit-items";
const DEPOSIT_INFO_SAVE_DEPOSIT_ITEMS_ENDPOINT = BASE_ENDPOINT_URL + "/save-deposit-info";
const DEPOSIT_INFO_POPULATED = BASE_ENDPOINT_URL + "/info";

export async function getDepositItemsSuggestions() : Promise<DepositItemModel[]> {
	return await post({
		url: DEPOSIT_ITEMS_SUGGESTIONS_ENDPOINT
	})
}

export async function getSearchableDepositItems() : Promise<DepositItemModel[]> {
	return await post({
		url: SEARCH_DEPOSIT_ITEMS_ENDPOINT
	})
}

export async function getDepositItems() : Promise<DepositItemModel[]> {
	return await post({
		url: DEPOSIT_ITEMS_ENDPOINT
	})
}

export async function saveDepositItems(depositItems: DepositItemModel[]) : Promise<ApiResponse> {
	return await post({
		url: DEPOSIT_INFO_SAVE_DEPOSIT_ITEMS_ENDPOINT,
		body: {
			depositItems
		}
	})
}

export async function getDepositInfo() : Promise<boolean> {
	return await get({
		url: DEPOSIT_INFO_POPULATED
	})
}