import {FormattedMessage, useIntl} from "react-intl";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {checkoutSelectors} from "../../../checkoutSlice";
import {sanitize} from "../../../../../lib/utility";

interface CheckoutDeliveryInstructionFieldProps {
    deliveryInstruction?: string;
    setDeliveryInstruction: (deliveryInstruction: string) => void;
}

export function CheckoutDeliveryInstructionField(props: CheckoutDeliveryInstructionFieldProps) {

    const [deliveryInstructionWarning, setDeliveryInstructionWarning] = useState<string>();

    const warningKeys: string[] = useSelector(checkoutSelectors.getCheckoutWarningKeywords);

    const instructionContainsWarningKey = (instruction: string) => {
        return warningKeys.some(keyword => instruction.toLowerCase().includes(keyword.toLowerCase()));
    }

    const changeDeliveryInstruction = (instruction: string) => {

        instruction = sanitize(instruction);

        if(instruction.length <= 50) {
            props.setDeliveryInstruction(instruction);
        }

        if(instructionContainsWarningKey(instruction)) {
            setDeliveryInstructionWarning(intl.formatMessage({id: 'cart.deliveryInformation.warning'}))
        } else {
            setDeliveryInstructionWarning('');
        }

    }

    const intl = useIntl();

    return(
        <>
            <div className={"tw-flex tw-flex-1 tw-mt-12"}>
                <div className={"tw-flex tw-flex-1 tw-pr-0 md:tw-pr-72 tw-flex-col"}>
                    <div className={"tw-text-3xl tw-font-semibold"}>
                        <FormattedMessage id={'cart.deliveryInformation.comment'}/>
                    </div>
                    <input type={'text'}
                           className={'tw-border tw-border-black tw-p-6 tw-flex tw-flex-1 tw-mt-4'}
                           onChange={(e) => changeDeliveryInstruction(e.target.value)}
                           value={props.deliveryInstruction === null || props.deliveryInstruction === undefined ? '' : props.deliveryInstruction}
                           placeholder={intl.formatMessage({id: 'cart.deliveryInformation.placeholder'})}
                    />
                </div>
                <div className={"tw-flex tw-flex-0 md:tw-flex-1"}/>
            </div>
            {deliveryInstructionWarning &&
                <div className={'tw-flex tw-flex-1 tw-mt-2 tw-pr-0 md:tw-pr-72'}>
                    <div className={'tw-flex tw-flex-1 tw-text-red'}>
                        {deliveryInstructionWarning}
                    </div>
                    <div className={"tw-flex tw-flex-0 md:tw-flex-1"}/>
                </div>
            }
        </>
    );

}