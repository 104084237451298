import {ReactElement} from "react";
import {PackagingInfo} from "lib/model/product/ProductModel";

export interface PackagingInfoProps {
    packagingInfo: PackagingInfo
}

export function PackagingInfoLabel(props: PackagingInfoProps): ReactElement {
    const pi = props.packagingInfo;
    const str = pi.units && pi.amountOfClInUnit ?
        `${pi.units}x${pi.amountOfClInUnit}cl` :
        pi.type;
    return <>{str}</>;
}
