import {ProductCore} from "../model/product/ProductModel";
import {post} from "./api";
import {get} from "./api";
import {apiProductToProductCore} from "./productApi";

export const ALL_FAVORITES_ENDPOINT = "/api/v1/favorites";
export const UPDATE_CUSTOMER_FAVORITES_ENDPOINT = "/api/v1/favorites/update-customer";
export const IS_FAVORITE_ENDPOINT = "/api/v1/favorites/is-favorite";
export const FAVORITES_INCLUSIVE_LAST_ORDERS = "/api/v1/favorites/inclusive-last-orders";

export async function getAllFavorites() : Promise<ProductCore[]> {
    const apiFavoritesList = await post({
        url: ALL_FAVORITES_ENDPOINT
    });
    return apiFavoritesList.map(apiProductToProductCore);
}

export function updateCustomerFavorites(productIds: string) : void {
    post({
        url: UPDATE_CUSTOMER_FAVORITES_ENDPOINT,
        body: {
            'productIds': JSON.parse(productIds)
        }
    });
}

export async function checkIfProductIdIsFavorite(pId: number) : Promise<boolean> {
    return await post({
        url: IS_FAVORITE_ENDPOINT + '/' + pId
    });
}

export async function getFavoritesInclusiveLastOrders() : Promise<ProductCore[]>{
    const apiFavoritesList = await get({
        url: FAVORITES_INCLUSIVE_LAST_ORDERS
    });
    return apiFavoritesList.map(apiProductToProductCore);
}