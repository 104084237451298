import merryChristmas from "./img/merrychristmas.png";
import bestDad from "./img/bestdad.png";
import bestFriend from "./img/bestfriends.png";

import {AdventCalendarEdition, AdventCalendarMode, AdventCalendarStep} from "./AdventCalendar";
import AdventCalendarPopupHeader from "./AdventCalendarPopupHeader";
import {FormattedMessage, useIntl} from "react-intl";
import {ReactComponent as CartIcon} from "../../widgets/cartbutton/cart-icon_white.svg";
import {useState} from "react";

export interface Step3Props {
	setIsOpen: (isOpen: boolean) => void
	mode: AdventCalendarMode
	edition: AdventCalendarEdition
	setStep: (step: AdventCalendarStep) => void
	isGift: boolean
	setIsGift: (isGift: boolean) => void
	dailyGreetings: boolean
	setDailyGreetings: (dailyGreetings: boolean) => void
	recipient: string | undefined
	setRecipient: (recipient: string) => void
	from: string | undefined
	setFrom: (from: string) => void
	to: string | undefined
	setTo: (to: string) => void
	message: string | undefined
	setMessage: (message: string) => void
	addToCart: () => void
	orderItemId?: string
	errorRecipient: boolean
	errorFrom: boolean
	errorTo: boolean
	errorMessage: boolean
}

export default function AdventCalendarPopupStep3(props: Step3Props) {

	const intl = useIntl();

	const [isButtonHovered, setIsButtonHovered] = useState(false);

	return (
		<div>
			<AdventCalendarPopupHeader
				currentPage={3}
				setIsOpen={props.setIsOpen} />
			<div className={"tw-flex tw-flex-col tw-flex-1"}>
				<div className={'tw-flex tw-flex-col tw-flex-1 tw-mx-8 tw-border-b tw-border-skeletonGrey tw-pb-4 tw-pt-4'}>
					<div className={'tw-flex tw-flex-row'}>
						<div className={'tw-mr-4'}>
							<img alt={'article preview'} src={props.mode === 'MERRY_CHRISTMAS' ? merryChristmas : props.mode === 'BEST_DAD' ? bestDad : bestFriend} />
						</div>
						<div className={'tw-flex tw-flex-row'}>
							<div className={'tw-flex tw-flex-col tw-items-start tw-text-left tw-ml-4 tw-mr-4'}>
								<div className={'tw-font-semibold tw-text-3xl'}>
									<FormattedMessage
										values={{
											edition: props.edition === 'WINTER' ? 'Winter Edition' : 'Leicht & Fruchtig',
											mode: props.mode === 'MERRY_CHRISTMAS' ? 'Merry Christmas' : props.mode === 'BEST_DAD' ? 'Best Dad' : 'Best Friends'
										}}
										id={'frontend.adventcalendar.summary'}
									/>
								</div>
								<div>
									<FormattedMessage id={'frontend.adventcalendar.summary.description'} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={'tw-flex tw-flex-col tw-flex-1 tw-mx-8 tw-border-b tw-border-skeletonGrey tw-pb-4 tw-pt-4'}>
					<div className={'tw-flex tw-flex-row tw-pt-4 tw-cursor-pointer'} onClick={() => {
						if(props.isGift) {
							props.setFrom("");
							props.setTo("");
							props.setMessage("");
							props.setRecipient("");
							props.setIsGift(false);
						} else {
							props.setIsGift(true);
						}
					}}>
						<div className={"tw-ml-4"}>
							<input
								checked={props.isGift}
								type={'checkbox'} />
						</div>
						<div className={'tw-text-2xl tw-font-semibold tw-text-black tw-ml-4'}>
							<FormattedMessage id={'frontend.adventcalendar.gift.title'} />
						</div>
					</div>
					{props.isGift ?
						<div className={"tw-ml-4 tw-text-left"}>
							<div>
								<div>
									<FormattedMessage id={'frontend.adventcalendar.recipient'} />
								</div>
								<div>
									{props.errorRecipient &&
										<div className={'tw-text-red'}>
											<FormattedMessage id={'frontend.adventcalendar.error.recipient'} />
										</div>
									}
									<input
										onChange={(e) => props.setRecipient(e.target.value)}
										value={props.recipient}
										className={"tw-border tw-border-grey tw-w-full tw-p-2"}
										placeholder={intl.formatMessage({id: 'frontend.adventcalendar.recipient.placeholder'})}
										type={"text"} />
								</div>
							</div>
							<div className={"tw-flex tw-flex-col md:tw-flex-row tw-mt-4"}>
								<div className={"tw-flex tw-flex-col tw-flex-1 md:tw-mr-4"}>
									<div>
										<FormattedMessage id={'frontend.adventcalendar.from'} />
									</div>
									<div>
										{props.errorFrom &&
                                            <div className={'tw-text-red'}>
                                                <FormattedMessage id={'frontend.adventcalendar.error.from'} />
                                            </div>
										}
										<input
											value={props.from}
											onChange={(e) => props.setFrom(e.target.value)}
											className={"tw-border tw-border-grey tw-w-full tw-p-2"}
											placeholder={intl.formatMessage({id: 'frontend.adventcalendar.from.placeholder'})}
											type={"text"} />
									</div>
								</div>
								<div className={"tw-flex tw-flex-col tw-flex-1 md:tw-ml-4"}>
									<div>
										<FormattedMessage id={'frontend.adventcalendar.to'} />
									</div>
									<div>
										{props.errorTo &&
                                            <div className={'tw-text-red'}>
                                                <FormattedMessage id={'frontend.adventcalendar.error.to'} />
                                            </div>
										}
										<input
											value={props.to}
											onChange={(e) => props.setTo(e.target.value)}
											className={"tw-border tw-border-grey tw-w-full tw-p-2"}
											placeholder={intl.formatMessage({id: 'frontend.adventcalendar.to.placeholder'})}
											type={"text"} />
									</div>
								</div>
							</div>
							<div className={"tw-mt-4"}>
								<div>
									<FormattedMessage id={'frontend.adventcalendar.message'} />
								</div>
								<div>
									{props.errorMessage &&
                                        <div className={'tw-text-red'}>
                                            <FormattedMessage id={'frontend.adventcalendar.error.message'} />
                                        </div>
									}
									<textarea
										onChange={(e) => props.setMessage(e.target.value)}
										className={"tw-border tw-border-grey tw-w-full tw-p-2"}
										placeholder={intl.formatMessage({id: 'frontend.adventcalendar.message.placeholder'})}
									>{props.message}</textarea>
								</div>
							</div>
							<div className={"tw-mt-4 tw-mb-8"}>
								<div>
									<FormattedMessage id={'frontend.adventcalendar.message.description'} />
								</div>
							</div>
						</div> :
						<div className={"tw-pb-4 tw-text-left"}>
							<div>
								<FormattedMessage id={'frontend.adventcalendar.gift.description'} />
							</div>
						</div>
					}
				</div>
				<div className={'tw-flex tw-flex-col tw-flex-1 tw-mx-8 tw-border-b tw-border-skeletonGrey tw-pb-4 tw-pt-4'}>
					<div className={'tw-flex tw-flex-row tw-pt-4 tw-cursor-pointer'} onClick={() => props.setDailyGreetings(!props.dailyGreetings)}>
						<div className={"tw-ml-4"}>
							<input
								checked={props.dailyGreetings}
								type={'radio'} />
						</div>
						<div className={'tw-text-2xl tw-font-semibold tw-text-black tw-ml-4'}>
							<FormattedMessage id={'frontend.adventcalendar.daily.greetings'} />
						</div>
					</div>
					<div className={"tw-flex tw-flex-1 tw-pl-2 tw-flex-col tw-items-start tw-text-left"}>
						<div>
							<FormattedMessage id={'frontend.adventcalendar.yes.send.newsletter'} />
						</div>
						<div className={"tw-text-lg tw-pt-1 tw-pb-3"}>
							<FormattedMessage id={'frontend.adventcalendar.yes.send.newsletter.disclaimer'} />
						</div>
					</div>
					<div className={'tw-flex tw-flex-row tw-pb-4 tw-pt-4 tw-cursor-pointer'} onClick={() => props.setDailyGreetings(!props.dailyGreetings)}>
						<div className={"tw-ml-4"}>
							<input
								checked={!props.dailyGreetings}
								type={'radio'} />
						</div>
						<div className={'tw-text-2xl tw-font-semibold tw-text-black tw-ml-4'}>
							<FormattedMessage id={'frontend.adventcalendar.no.send.newsletter'} />
						</div>
					</div>
				</div>
				<div className={'tw-flex tw-flex-1 tw-flex-col md:tw-flex-row'} style={{marginRight: '20px', marginLeft: '20px'}}>
					<div
						onClick={() => props.setStep('STEP2')}
						className={'tw-p-6 tw-px-16 tw-mt-12 tw-mb-12 tw-font-bold tw-bg-white tw-border-black tw-text-black tw-cursor-pointer tw-border md:tw-w-adventCalendarPopupButton'}>
						<FormattedMessage id={'frontend.adventcalendar.back'} />
					</div>
					<div className={'tw-flex tw-flex-1'}/>
					<button
						onClick={() => {
							props.addToCart();
						}}
						className={`${props.orderItemId && 'md:tw-w-adventCalendarPopupButton'} advent-calendar-add-to-cart tw-p-6 tw-bg-black tw-text-white md:tw-mt-12 tw-mb-48 md:tw-mb-12 tw-font-bold hover:tw-bg-white tw-border-black hover:tw-text-black tw-cursor-pointer tw-border`}
						onMouseEnter={() => setIsButtonHovered(true)}
						onMouseLeave={() => setIsButtonHovered(false)}>
						{props.orderItemId ? <FormattedMessage id={'frontend.adventcalendar.save.to.cart'} /> :
							<div className={'tw-flex tw-flex-row tw-items-center tw-justify-center'}>
								<FormattedMessage id={'frontend.adventcalendar.add.to.cart'} />
								<div className={'tw-ml-2'}>
									<CartIcon
										fill={isButtonHovered ? '#000000' : '#FFFFFF'}/>
								</div>
							</div>
						}
					</button>
				</div>
			</div>
		</div>
	)

}