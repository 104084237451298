import algoliasearch, {SearchClient} from "algoliasearch";
import searchInsights, {InsightsClient} from "search-insights";

export interface Algolia {
    client: SearchClient,
    searchInsights: InsightsClient
}

export function initAlgolia(appId: string, apiKey: string): Algolia {
    const client = algoliasearch(appId, apiKey);
    const proxyClient: SearchClient = {
        ...client,
        search(requests, requestOptions) {
            if (requests.every(({ params }) => !params?.query && !params?.filters)) {
                return Promise.resolve({
                    results: requests.map(request => ({
                        hits: [],
                        nbHits: 0,
                        nbPages: 0,
                        page: 0,
                        processingTimeMS: 0,
                        hitsPerPage: 0,
                        exhaustiveNbHits: true,
                        query: request.query ?? "",
                        params: ""
                    })),
                });
            }

            return client.search(requests, requestOptions);
        }
    }
    
    searchInsights('init', {
        appId: appId,
        apiKey: apiKey
    });

    return {
        client: proxyClient,
        searchInsights: searchInsights
    };
}
