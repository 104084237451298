import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {ReactComponent as PlusIcon} from "../plus.svg";
import {sanitize} from "../../../../../lib/utility";

export interface CheckoutAddressCompanyFieldProps {
    hasCompanyField: boolean
    company: string
    setHasCompanyField: (value: boolean) => void
    setCompany: (value: string) => void
}

export function CheckoutAddressCompanyField(props: CheckoutAddressCompanyFieldProps) {

    const intl = useIntl();

    return (
        <>
        {(!props.hasCompanyField && !props.company) ?
            <div className={"tw-ml-2 tw-cursor-pointer tw-flex tw-flex-row tw-mb-8"}
                 onClick={() => props.setHasCompanyField(true)}>
                <div>
                    <PlusIcon/>
                </div>
                <div className={"tw-ml-4 tw-text-2xl"}>
                    <FormattedMessage id={"cart.add.business.address"}/>
                </div>
            </div>
            :
            <div className={"tw-mt-8 tw-mb-8"}>
                <div className={"tw-flex tw-flex-1"}>
                    <FormattedMessage id={'cart.companyName'}/>
                </div>
                <div className={"tw-flex tw-flex-1"}>
                    <input
                        onChange={(e) => props.setCompany(sanitize(e.target.value))}
                        value={props.company ? props.company : ''}
                        placeholder={intl.formatMessage({id: 'cart.companyName'})}
                        className={"tw-border-grey tw-border-2 tw-flex-1 tw-p-6 tw-text-2xl"}
                        type={"text"}/>
                </div>
            </div>
        }
        </>
    )

}