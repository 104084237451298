import {ReactElement, useEffect} from "react";
import {ProductCore} from "lib/model/product/ProductModel";
import {reduxStore} from "lib/redux/reduxStore";
import {
    productListsActions,
    productListsReducer,
    productListsSelectors
} from "components/product-list/productListsSlice";
import {useAppDispatch} from "lib/redux/reduxHooks";
import {useSelector} from "react-redux";
import {ProductListSkeleton} from "components/product-list-item/ProductListSkeleton";
import {times} from "ramda";
import {ErrorMessage} from "widgets/ErrorMessage";
import {FormattedMessage} from "react-intl";
import {ProductCard} from "components/product-card/ProductCard";
import {ProductSlider} from "../product-slider/ProductSlider";

reduxStore.injectReducer("productLists", productListsReducer);

export interface ProductListProps {
    productListKey: string
    fetchFn: () => Promise<ProductCore[]>
    cartTrackingClass?: string
    customGridCols?: number
    mobileSlider?: boolean
    blackFridayActive: boolean
    orderId?: number
}

export function ProductList(props: ProductListProps): ReactElement {
    const dispatch = useAppDispatch();
    const products = useSelector(productListsSelectors.getProducts(props.productListKey));
    const error = useSelector(productListsSelectors.getError(props.productListKey));
    const isLoading = useSelector(productListsSelectors.isLoading(props.productListKey));

    useEffect(() => {
        dispatch(productListsActions.fetchList({
            key: props.productListKey,
            request: props.fetchFn
        }));
    }, [dispatch, props.productListKey, props.fetchFn]);

    if (error) {
        return <ErrorMessage><FormattedMessage id="frontend.productList.error.couldNotFetch"/></ErrorMessage>;
    }

    if (isLoading) {
        return(
            <div
                className={`tw-grid tw-gap-12 tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 ${props.customGridCols ? 'lg:tw-grid-cols-' + props.customGridCols : 'lg:tw-grid-cols-4'}`}>
                {times(n => <ProductListSkeleton key={n}/>, 4)}
            </div>
        )
    }

    if (props.mobileSlider) {
        return <ProductSlider
            blackFridayActive={props.blackFridayActive}
            orderId={props.orderId}
            productIds={products.map(p => p.productId)}/>
    } else {
        return <div
            className={`tw-grid tw-gap-12 tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 ${props.customGridCols ? 'lg:tw-grid-cols-' + props.customGridCols : 'lg:tw-grid-cols-4'}`}>
            {products.map(p =>
                <ProductCard
                    blackFridayActive={props.blackFridayActive}
                    orderId={props.orderId}
                    mode="responsive"
                    product={p}
                    key={p.productId}
                    cartTrackingClass={props.cartTrackingClass}
                />)}
        </div>
    }
}
