import {ReactElement} from "react";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../../lib/responsive-breakpoints";

export function SummaryItemSekelton() : ReactElement {

	const isMobile = !useMediaQuery(mediaQueries.sm);

	if(isMobile) {
		return(
			<div className={'tw-flex tw-flex-1 tw-flex-col'}>
				{[...Array(3)].map((e, i) => (
					<div className={'tw-border-b tw-border-inactiveGrey'} key={i}>
						<div className={'tw-w-full tw-bg-skeletonGrey skeleton-box tw-mb-8'} style={{height: '40px'}}></div>
					</div>
				))}
			</div>
		)
	} else {
		return (
			<div className={'tw-flex tw-flex-1 tw-flex-col'}>
				{[...Array(3)].map((e, i) => (
					<div className={'tw-flex tw-flex-col tw-flex-1 tw-border-b tw-border-inactiveGrey tw-pb-8 tw-pt-8'}
						 key={i}>
						<div>
							<div className={'tw-font-bold tw-text-3xl tw-bg-skeletonGrey skeleton-box'}
								 style={{width: '340px', height: '30px'}}/>
						</div>
						<div className={'tw-flex tw-flex-row tw-flex-1 tw-mt-2'}>
							<div className={'tw-text-2xl tw-w-2/3'}>
								<div className={'skeleton-box tw-bg-skeletonGrey'}
									 style={{width: '150px', height: '25px'}}/>
							</div>
							<div className={'tw-flex tw-flex-1 tw-text-2xl tw-items-end'}>
								<div style={{width: '40px', height: '25px'}}
									 className={'tw-bg-skeletonGrey skeleton-box'}/>
							</div>
							<div className={'tw-font-bold'}>
								<div className={'tw-bg-skeletonGrey skeleton-box'}
									 style={{width: '100px', height: '25px'}}/>
							</div>
						</div>
					</div>
				))}
			</div>
		)
	}
}