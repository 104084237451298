import {ReactElement, useCallback} from "react";
import {join} from "ramda";
import {getRecommendations} from "lib/api/statworxApi";
import {FeaturedProducts} from "components/featured-products/FeaturedProducts";
import {FormattedMessage} from "react-intl";

export interface StatWorxRecommendationsProps {
    num: number
    basket: number[]
    trackingClass: string,
    customTitle?: string,
    customGridCols?: number,
    mobileSlider?: boolean
    blackFridayActive: boolean
    orderId?: number
}

export function StatworxRecommendations(props: StatWorxRecommendationsProps): ReactElement {

    const getTitle = () => {
        if(props.customTitle) {
            return props.customTitle;
        } else if(props.mobileSlider) {
            return <FormattedMessage id="frontend.home.recommendations.title" />
        }
        return <FormattedMessage id="frontend.statworx.recommendations.title" />;
    }

    const fetchFn = useCallback(() => {
        return getRecommendations(props.num, props.basket);
    }, [props.num, props.basket]);

    return <FeaturedProducts
        title={getTitle()}
        productListKey={`statworx-${join('-', props.basket)}`}
        customGridCols={props.customGridCols}
        fetchFn={fetchFn}
        cartTrackingClass={props.trackingClass}
        mobileSlider={props.mobileSlider}
        blackFridayActive={props.blackFridayActive}
        orderId={props.orderId}
    />
}
