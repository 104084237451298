import {ReactElement} from "react";
import {depositInfoSelectors} from "./depositInfoSlice";
import {useSelector} from "react-redux";
import {DepositItemRowList} from "./DepositItemRowList";
import {DepositItemListSkeleton} from "./DepositItemListSkeleton";
import {FormattedMessage} from "react-intl";

interface DepositItemListProps {
	setDepositHasChanged: () => void
}

export function DepositItemList(props: DepositItemListProps) : ReactElement {

	const depositItems = useSelector(depositInfoSelectors.getDepositItems);
	const depositItemsState = useSelector(depositInfoSelectors.getDepositItemsStatus);

	const depositItemsSuggestions = useSelector(depositInfoSelectors.getDepositItemsSuggestions);
	const depositItemsSuggestionsState = useSelector(depositInfoSelectors.getDepositItemsSuggestionsStatus);

	if(depositItemsState === 'FETCHED' && depositItemsSuggestionsState === 'FETCHED') {
		if(depositItems.length > 0) {
			return(
				<DepositItemRowList
					depositItemList={depositItems}
					setDepositHasChanged={props.setDepositHasChanged} />
			);
		} else {
			if(depositItemsSuggestions.length > 0) {
				return (
					<DepositItemRowList
						depositItemList={depositItemsSuggestions}
						setDepositHasChanged={props.setDepositHasChanged}/>
				);
			} else {
				return(
					<div className={'tw-flex tw-flex-1 tw-justify-center tw-items-center tw-text-lightGrey tw-p-8 tw-border-b tw-border-inactiveGrey tw-text-2xl tw-my-2'}>
						<FormattedMessage id={'cart.deposit.no.deposit.items'} />
					</div>
				)
			}
		}
	} else {
		return <DepositItemListSkeleton />;
	}

}