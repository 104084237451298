export type CheckoutSectionStateType =
	'FORM' |
	'SAVED' |
	'INACTIVE'

function isStateType(str: string | undefined | null): str is CheckoutSectionStateType {
	return ['FORM', 'SAVED', 'INACTIVE'].includes(str ?? '');
}

export function getStateTypeFromString(str: string | undefined) : CheckoutSectionStateType {
	return isStateType(str) ? str : 'SAVED';
}