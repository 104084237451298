import {axiosDelete, get, post, put} from "./api";
import {
    AddressModel,
    CheckoutSettingsModel, DeliveryOptionsModel,
    FAQMenu,
    MyAccountModel,
    MyAccountOverviewModel,
    OrderPreviewModel,
    PersonalDataModel, PersonalDataModelAgeVerification
} from "../model/myaccount/MyAccountModel";
import {Order} from "../model/order/OrderModel";
import {MyAccountMenuRowModel} from "../../components/my-account/model/MyAccountMenuRowModel";

const MY_ACCOUNT_ENDPOINT = "/api/v1/my-account";
const MY_ACCOUNT_OVERVIEW_ENDPOINT = "/api/v1/my-account/overview";
const MY_ACCOUNT_ORDERS_ENDPOINT = "/api/v1/my-account/orders";
const MY_ACCOUNT_ADDRESS_ENDPOINT = "/api/v1/my-account/address";
const MY_ACCOUNT_FAQ_ENDPOINT = "/api/v1/my-account/faq";
const MY_ACCOUNT_PERSONAL_DATA_ENDPOINT = "/api/v1/my-account/personal-data";
const MY_ACCOUNT_CHECKOUT_SETTINGS_ENDPOINT = "/api/v1/my-account/checkout-settings";
const MY_ACCOUNT_MENU_ENDPOINT = "/api/v1/my-account/menu";
const MY_ACCOUNT_DELETE_ENDPOINT = "/api/v1/my-account/delete";

type ApiResponseState =
    'SUCCESS' |
    'ERROR'

export interface ApiResponse {
    state: ApiResponseState,
    value: {[key: string]: string}
}

export async function getMyAccount() : Promise<MyAccountModel> {
    return await post({
        url: MY_ACCOUNT_ENDPOINT
    });
}

export async function getMyAccountOverview() : Promise<MyAccountOverviewModel> {
    return await get({
        url: MY_ACCOUNT_OVERVIEW_ENDPOINT
    });
}

export async function getMyAccountOrders() : Promise<OrderPreviewModel[]> {
    return await get({
        url: MY_ACCOUNT_ORDERS_ENDPOINT
    });
}

export async function getMyAccountAddress() : Promise<AddressModel[]> {
    return await get({
        url: MY_ACCOUNT_ADDRESS_ENDPOINT
    });
}

export async function getMyAccountFaq() : Promise<FAQMenu> {
    return await get({
        url: MY_ACCOUNT_FAQ_ENDPOINT
    });
}

export async function getMyAccountPersonalData(allowAnonymous?: boolean) : Promise<PersonalDataModelAgeVerification> {
    const url = allowAnonymous ?
        MY_ACCOUNT_PERSONAL_DATA_ENDPOINT + "?allowAnonymous=true" :
        MY_ACCOUNT_PERSONAL_DATA_ENDPOINT
    return await get({
        url: url
    })
}

export async function getMyAccountCheckoutSettings() : Promise<CheckoutSettingsModel> {
    return await get({
        url: MY_ACCOUNT_CHECKOUT_SETTINGS_ENDPOINT
    })
}

export async function getAllOrders() : Promise<Order[]> {
    return await get({
        url: MY_ACCOUNT_ORDERS_ENDPOINT
    });
}

export async function getOrder(orderId: string) : Promise<Order> {
    return await get({
        url: MY_ACCOUNT_ORDERS_ENDPOINT + "/" + orderId
    });
}

export async function createAddress(address: AddressModel) : Promise<ApiResponse> {
    return await post({
        url: MY_ACCOUNT_ADDRESS_ENDPOINT,
        body: address
    })
}

export async function updateAddress(address: AddressModel) : Promise<ApiResponse> {
    return await put({
        url: MY_ACCOUNT_ADDRESS_ENDPOINT + "/" + address.addressId,
        body: address
    });
}

export async function updateDeliveryOptions(deliveryOptions: DeliveryOptionsModel) : Promise<ApiResponse> {
    return await put({
        url: MY_ACCOUNT_ADDRESS_ENDPOINT + "/shipping/" + deliveryOptions.addressId,
        body: deliveryOptions
    })
}

export async function deleteAddress(addressId: number) : Promise<ApiResponse> {
    return await axiosDelete({
        url: MY_ACCOUNT_ADDRESS_ENDPOINT + "/" + addressId
    });
}

export async function updatePersonalData(personalData: PersonalDataModel) : Promise<ApiResponse> {
    return await put({
        url: MY_ACCOUNT_PERSONAL_DATA_ENDPOINT,
        body: personalData
    });
}

export async function updateCheckoutSettings(checkoutSettings: CheckoutSettingsModel) : Promise<ApiResponse> {
    return await put({
        url: MY_ACCOUNT_CHECKOUT_SETTINGS_ENDPOINT,
        body: checkoutSettings
    });
}

export async function getMyAccountMenu() : Promise<MyAccountMenuRowModel[]> {
    return await get({
        url: MY_ACCOUNT_MENU_ENDPOINT
    });
}

export async function deleteMyAccount(): Promise<ApiResponse> {
    return axiosDelete ({
        url: MY_ACCOUNT_DELETE_ENDPOINT
    });
}