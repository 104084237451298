import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {WHERE_TO_DELIVER, WHERE_TO_DEPOSIT} from "../CheckoutSectionDeliveryRow";
import {CheckoutDeliveryDepositOtherInstructionField} from "./CheckoutDeliveryDepositOtherInstructionField";

interface CheckoutDeliveryDepositProps {
    depositEnabled?: boolean;
    setDepositEnabled: (depositEnabled?: boolean) => void;
    whereToDeposit?: WHERE_TO_DEPOSIT;
    setWhereToDeposit: (whereToDeposit?: WHERE_TO_DEPOSIT) => void;
    whereToDepositOtherText: string,
    setWhereToDepositOtherText: (whereToDepositOtherText: string) => void;
    whereToDepositOtherInstructionWarning: string;
    setWhereToDepositOtherInstructionWarning: (whereToDepositOtherInstructionWarning: string) => void;
    whereToDepositError: string;
    setWhereToDepositError: (whereToDepositError: string) => void;
    whereToDeliver?: WHERE_TO_DELIVER;
    whereToDeliverOtherText?: string;
    depositEnabledError?: string;
    setDepositEnabledError: (depositEnabledError?: string) => void;
    isCheckoutView?: boolean;
}

export function CheckoutDeliveryDeposit(props: CheckoutDeliveryDepositProps) {

    const intl = useIntl();

    function resetFields() {
        props.setWhereToDepositError('');
        props.setWhereToDepositOtherText('');
        props.setWhereToDepositOtherInstructionWarning('');
    }

    return (
        <div className={"tw-flex tw-flex-1 tw-mt-8"}>
            <div className={`tw-flex tw-flex-1 tw-pr-0 ${props.isCheckoutView ? 'md:tw-pr-72' : ''} tw-flex-col`}>
                <div className={"tw-text-3xl tw-font-semibold"}>
                    <FormattedMessage id={'cart.deliveryInformation.depositOrder.title'}/>
                </div>
                <div className={"tw-flex tw-flex-row tw-mt-4 tw-cursor-pointer"}
                     onClick={() => {
                         props.setDepositEnabledError(undefined);
                         if(props.depositEnabled) {
                            props.setDepositEnabled(undefined);
                            props.setWhereToDeposit(undefined);
                            props.setWhereToDepositOtherText('');
                            props.setWhereToDepositOtherInstructionWarning('')
                         } else {
                             props.setDepositEnabled(true);
                             if (props.whereToDeliver === 'DOOR') {
                                 props.setWhereToDeposit('ENTRY');
                             } else if (props.whereToDeliver === 'GARAGE') {
                                 props.setWhereToDeposit('GARAGE');
                             } else if (props.whereToDeliver === 'OTHER') {
                                 props.setWhereToDeposit('OTHER');
                                 if(props.whereToDeliverOtherText) {
                                     props.setWhereToDepositOtherText(props.whereToDeliverOtherText)
                                 }
                             }
                         }
                     }}>
                    <div className={"tw-flex tw-pr-4 tw-cursor-pointer"}>
                        <input type={'checkbox'}
                               readOnly
                               checked={props.depositEnabled === true}/>
                    </div>
                    <div className={`${props.depositEnabledError ? 'tw-text-red' : 'tw-text-black'}`}>
                        <span>
                            {intl.formatMessage({id: 'cart.deliveryInformation.depositOrder.checkbox.label'})}
                        </span>
                        <span className={'tw-ml-2'}
                              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'cart.deliveryInformation.depositOrder.checkbox.label.agb'})}}/>
                    </div>
                </div>
                {props.depositEnabled && (
                    <div className={"tw-mt-4 tw-ml-10"}>
                        <div className={"tw-font-semibold"}>
                            <FormattedMessage id={'cart.deliveryInfo.where.to.deposit'}/>
                        </div>
                        <div>
                            <FormattedMessage id={'cart.deliveryInfo.where.to.deposit.condition'}/>
                        </div>
                        <div className={`${props.whereToDepositError ? 'tw-text-red' : 'tw-text-black'} radio`}>
                            <label>
                                <input type="radio" checked={props.whereToDeposit === "ENTRY"}
                                       onChange={() => {
                                           props.setWhereToDeposit('ENTRY');
                                           resetFields()
                                       }}/>
                                <FormattedMessage id={'sevendays.deposit.entry'}/>
                            </label>
                        </div>
                        <div className={`${props.whereToDepositError ? 'tw-text-red' : 'tw-text-black'} radio`}>
                            <label>
                                <input type="radio" checked={props.whereToDeposit === "GARAGE"}
                                       onChange={() => {
                                           props.setWhereToDeposit('GARAGE');
                                           resetFields();
                                       }}/>
                                <FormattedMessage id={'sevendays.deposit.garage'}/>
                            </label>
                        </div>
                        <div className={`${props.whereToDepositError ? 'tw-text-red' : 'tw-text-black'} radio`}>
                            <label>
                                <input type="radio" checked={props.whereToDeposit === "OTHER"}
                                       onChange={() => {
                                           props.setWhereToDeposit('OTHER');
                                           resetFields();
                                       }}/>
                                <FormattedMessage id={'sevendays.deliver.other'}/>
                                {props.whereToDeposit === 'OTHER' &&
                                    <CheckoutDeliveryDepositOtherInstructionField
                                        whereToDepositOtherText={props.whereToDepositOtherText}
                                        setWhereToDepositOtherText={props.setWhereToDepositOtherText}
                                        whereToDepositOtherInstructionWarning={props.whereToDepositOtherInstructionWarning}
                                        setWhereToDepositOtherInstructionWarning={props.setWhereToDepositOtherInstructionWarning}
                                    />
                                }
                            </label>
                        </div>
                        {props.whereToDepositError && (
                            <div className={"tw-text-red tw-mt-4"}>
                                {props.whereToDepositError}
                            </div>
                        )}
                    </div>
                )}
                <div className={"tw-flex tw-flex-row tw-mt-4 tw-cursor-pointer"}
                     onClick={() => {
                         props.setDepositEnabledError(undefined);
                         if(props.depositEnabled) {
                             props.setDepositEnabled(false);
                         } else {
                             if(props.depositEnabled === false) {
                                 props.setDepositEnabled(undefined);
                             } else {
                                 props.setDepositEnabled(false);
                             }
                         }
                         props.setWhereToDeposit(undefined);
                         props.setWhereToDepositOtherText('')
                     }}>
                    <div className={"tw-flex tw-pr-4 tw-cursor-pointer"}>
                        <input type={'checkbox'} readOnly
                               checked={props.depositEnabled === false}/>
                    </div>
                    <div className={`${props.depositEnabledError ? 'tw-text-red' : 'tw-text-black'}`}>
                        <span>
                            {intl.formatMessage({id: 'cart.deliveryInformation.depositOrder.checkbox.no.label'})}
                        </span>
                    </div>
                </div>
            </div>
            <div className={"tw-flex tw-flex-0 md:tw-flex-1"}/>
        </div>
    );

}