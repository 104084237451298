import {useIntl} from "react-intl";
import React from "react";

interface CheckoutDeliveryOtherInstructionFieldProps {
    text: string,
    warning: string,
    changeInstruction: (instruction: string) => void,
    containsWarningKeys: boolean,
}


export function CheckoutDeliveryOtherInstructionField(props: CheckoutDeliveryOtherInstructionFieldProps) {
    const intl = useIntl()

    return (
        <>
            <div>
                <input
                    type={'text'}
                    className={'tw-border tw-border-black tw-p-6 tw-flex tw-flex-1 tw-mt-4'}
                    value={props.text}
                    onChange={(e) => props.changeInstruction(e.target.value)}
                    maxLength={25}
                    placeholder={intl.formatMessage({id: 'sevendays.deliver.other.placeholder'})}
                />
            </div>
            {props.warning &&
                <div className={`${props.containsWarningKeys ? "tw-flex tw-flex-1 md:tw-pr-72" : ""} tw-mt-2 tw-pr-0`}>
                    <div className={'tw-flex tw-flex-1 tw-text-red'}>
                        {props.warning}
                    </div>
                    <div className={"tw-flex tw-flex-0 md:tw-flex-1"}/>
                </div>
            }
        </>
    )
}