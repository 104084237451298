import {post} from "lib/api/api";

const CLAIM_WIN_ENDPOINT = "/api/v1/blackfriday/claim/";

export interface BlackFridayClaimRequest {
    orderId: number
}

export function claimPrice(req: BlackFridayClaimRequest): Promise<void> {
    return post({
        url: CLAIM_WIN_ENDPOINT,
        body: req
    });
}
