/**
 * [Tailwind breakpoints](https://tailwindcss.com/docs/breakpoints) as usable JS object
 */
export const breakpoints = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1563px'
};

/**
 * Breakpoints usable for [react-responsive](https://www.npmjs.com/package/react-responsive) `useMediaQuery()` calls
 */
export const mediaQueries = {
    sm: {query: `(min-width: ${breakpoints.sm})`},
    md: {query: `(min-width: ${breakpoints.md})`},
    lg: {query: `(min-width: ${breakpoints.lg})`},
    xl: {query: `(min-width: ${breakpoints.xl})`},
    '2xl': {query: `(min-width: ${breakpoints["2xl"]})`}
}
