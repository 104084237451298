import {ReactElement} from "react";
import {IndicatorSelected} from "./IndicatorSelected";
import {IndicatorNotSelected} from "./IndicatorNotSelected";
import {times} from "ramda";

export interface IndicatorProps {
    sliderLength: number,
    selectedIndex: number,
    scrollTo: (index: number) => void
}

export function Indicator(props: IndicatorProps) : ReactElement {

    return(
        <div className={"md:tw-absolute md:tw-bottom-2 md:tw-left-0 tw-mt-8 md:tw-mt-0 md:tw-right-0 tw-flex tw-items-center tw-justify-center tw-text-black md:tw-text-white"}>
            {times(n => (
                n === props.selectedIndex ?
                    <IndicatorSelected key={n} /> :
                    <IndicatorNotSelected
                        scrollTo={props.scrollTo}
                        index={n}
                        key={n} />
            ), props.sliderLength)}
        </div>
    )
}