import {ReactNode} from "react";
import {FormattedMessage} from "react-intl";


interface AgeVerificationButtonProps {
    isMobile: boolean,
    title: string,
    children?: ReactNode,
    classes?: string
    clickHandler?: () => void
}

export default function AgeVerificationButton(props: AgeVerificationButtonProps) {

    return (
        <>
            <div
                className={`tw-flex tw-items-center tw-justify-center ${props.children ? "tw-gap-4" : ""} ${props.isMobile ? "tw-w-full" : "tw-w-1/2"} ${props.classes ? props.classes : ""} tw-rounded tw-h-20 tw-px-5 tw-mt-12`}
                onClick={props.clickHandler ? (e) => props.clickHandler!() : undefined}
            >
                {props.children &&
                    props.children
                }
                <div className={`tw-text-2xl tw-font-bold`}>
                    <FormattedMessage id={props.title} />
                </div>
            </div>
        </>
    )
}