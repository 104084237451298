import {ReactElement} from "react";

export function ProductListSkeleton(): ReactElement {

	return (
		<div className={"tw-h-skeletonContainer tw-bg-skeletonBackgroundGrey tw-flex tw-flex-col " +
			"md:tw-h-desktopCard md:tw-bg-white md:tw-border-homeComponentsBg md:tw-border-2 tw-flex-1"}>
			<div className={"tw-flex tw-flex-1  md:tw-flex-col"}>
				<div className={"tw-bg-skeletonGrey tw-w-productCardImage tw-ml-4 tw-mt-6 md:tw-hidden"}></div>
				<div
					className={"tw-bg-skeletonGrey tw-flex-1 tw-h-skeletonText tw-mt-6 tw-mr-4 tw-ml-8 md:tw-m-8 md:tw-h-productCardImageDesktop"}></div>
			</div>
			<div className={"tw-flex tw-flex-row"}>
				<div
					className={"tw-flex tw-flex-row tw-w-productCardImage tw-ml-4 tw-mt-6 tw-mb-4 tw-justify-evenly tw-items-center"}>
					<div>
						<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="14" cy="14" r="14" fill="#E7E7E7"/>
						</svg>
					</div>
					<div>
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="7" cy="7" r="7" fill="#E7E7E7"/>
						</svg>
					</div>
					<div>
						<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="14" cy="14" r="14" fill="#E7E7E7"/>
						</svg>
					</div>
				</div>
				<div className={"tw-flex-1"}>
					<div className={"tw-bg-skeletonGrey tw-mt-6 tw-h-skeletonText tw-ml-8 tw-mr-10"}></div>
				</div>
				<div className={"tw-flex tw-mr-8 tw-items-center"}>
					<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="14" cy="14" r="14" fill="#E7E7E7"/>
					</svg>
				</div>
			</div>
		</div>
	);

}
