import {ProductListSkeleton} from "../product-list-item/ProductListSkeleton";
import {ReactElement} from "react";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";

export interface FavoritesSkeletonProps {
    isAnonymous: boolean
}

export default function FavoritesSkeleton(props: FavoritesSkeletonProps) : ReactElement {

    const isMobile = !useMediaQuery(mediaQueries.sm);

    if(isMobile) {
        return(
            <div className={"tw-mt-8 tw-flex tw-flex-1 tw-flex-col"}>
                {[...Array(8)].map((e, i) => (
                    <div className={"tw-my-4"} key={i}>
                        <ProductListSkeleton />
                    </div>
                ))}
            </div>
        )
    }

    return(
        <div className={"tw-flex tw-flex-1 tw-flex-col tw-mt-8"}>
            <div className={`tw-flex tw-flex-1 tw-flex-row tw-justify-between`}>
                <div className={"tw-mr-4 tw-flex tw-flex-1"}>
                    <ProductListSkeleton/>
                </div>
                <div className={"tw-mx-4 tw-flex tw-flex-1"}>
                    <ProductListSkeleton/>
                </div>
                {props.isAnonymous &&
                    <div className={"tw-mx-4 tw-flex tw-flex-1"}>
                        <ProductListSkeleton/>
                    </div>
                }
                <div className={"tw-ml-4 tw-flex tw-flex-1"}>
                    <ProductListSkeleton/>
                </div>
            </div>
            <div className={"tw-flex tw-flex-1 tw-flex-row tw-justify-between tw-mt-8"}>
                <div className={"tw-mr-4 tw-flex tw-flex-1"}>
                    <ProductListSkeleton/>
                </div>
                <div className={"tw-mx-4 tw-flex tw-flex-1"}>
                    <ProductListSkeleton/>
                </div>
                {props.isAnonymous &&
                    <div className={"tw-mx-4 tw-flex tw-flex-1"}>
                        <ProductListSkeleton/>
                    </div>
                }
                <div className={"tw-ml-4 tw-flex tw-flex-1"}>
                    <ProductListSkeleton/>
                </div>
            </div>
        </div>
    );
};