import {sanitize} from "../../../../../lib/utility";
import {CheckoutDeliveryOtherInstructionField} from "./CheckoutDeliveryOtherInstructionField";


interface CheckoutDeliveryDepositOtherInstructionFieldProps {
    whereToDepositOtherText: string,
    setWhereToDepositOtherText: (whereToDepositOtherText: string) => void,
    whereToDepositOtherInstructionWarning: string,
    setWhereToDepositOtherInstructionWarning: (whereToDepositOtherInstructionWarning: string) => void
}

export function CheckoutDeliveryDepositOtherInstructionField(props: CheckoutDeliveryDepositOtherInstructionFieldProps) {

    const changeDepositInstruction = (instruction: string) => {
        instruction = sanitize(instruction)

        if(instruction.length <= 25) {
            props.setWhereToDepositOtherText(instruction)
        }

        props.setWhereToDepositOtherInstructionWarning('')
    }

    return (
        <CheckoutDeliveryOtherInstructionField
            text={props.whereToDepositOtherText}
            warning={props.whereToDepositOtherInstructionWarning}
            changeInstruction={changeDepositInstruction}
            containsWarningKeys={false}
        />
    )
}