import {post} from "./api";
import {filter, fromPairs, toPairs} from "ramda";

export const TRANSLATION_ENDPOINT = "/api/v1/translation";

export type Translations = { [key: string]: string };

export async function getTranslations(keys: string[]): Promise<Translations> {
    return post({
        url: TRANSLATION_ENDPOINT,
        body: keys
    }).then(sanitizeTranslationResponse);
}

function sanitizeTranslationResponse(translations: Translations): Translations {
    const pairs = toPairs(translations);
    const filtered = filter(([msgId, msg]) => msgId !== msg, pairs);
    return fromPairs(filtered);
}
