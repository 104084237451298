import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FetchStatus, getFavoriteProductIds, updateFavorites} from "../../lib/utility";

type FavoriteProductListRootState = { favoriteProductList: FavoriteProductListState }

interface FavoriteProductListState {
    favoriteProductIds: number[],
    isAnonymous: boolean,
    status: FetchStatus
}

const initialState: FavoriteProductListState = {
    favoriteProductIds: [],
    isAnonymous: true,
    status: 'INIT'
}

const publicSelectors = {
    getFavoriteProductIds: (state: FavoriteProductListRootState) => state.favoriteProductList.favoriteProductIds,
    getStatus: (state: FavoriteProductListRootState) => state.favoriteProductList.status,
    isFavorite: (productId: number | undefined) => (state: FavoriteProductListRootState) => productId !== undefined && state.favoriteProductList.favoriteProductIds.includes(productId)
}

const fetchFavoriteProductList = createAsyncThunk(
    'favoriteProductList/create',
    async (isAnonymous: boolean) => {
        const favoriteProductIds = await getFavoriteProductIds(isAnonymous);
        return {
            favoriteProductIds,
            isAnonymous
        }
    }
)

const {actions, reducer} = createSlice({
    name: 'favoriteProductList',
    initialState,
    reducers: {
        addFavoriteProductId(state: FavoriteProductListState, action: PayloadAction<number>) {
            state.favoriteProductIds.push(action.payload);
            updateFavorites(state.favoriteProductIds, state.isAnonymous);
        },
        removeFavoriteProductId(state: FavoriteProductListState, action: PayloadAction<number>) {
            const index = state.favoriteProductIds.indexOf(action.payload);
            state.favoriteProductIds.splice(index, 1);
            updateFavorites(state.favoriteProductIds, state.isAnonymous);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchFavoriteProductList.pending, (state, action) => {
                state.status = 'FETCHING'
            })
            .addCase(fetchFavoriteProductList.fulfilled, (state, action) => {
                state.favoriteProductIds = action.payload.favoriteProductIds;
                state.isAnonymous = action.payload.isAnonymous;
                state.status = 'FETCHED'
            })
            .addCase(fetchFavoriteProductList.rejected, (state, action) => {
                state.status = 'ERROR'
            })
    }
});

const publicActions = {
    addFavoriteProductId: actions.addFavoriteProductId,
    removeFavoriteProductId: actions.removeFavoriteProductId,
    fetchFavoriteProductList
}

export {reducer as favoriteProductListReducer};
export {publicActions as favoriteActions};
export {publicSelectors as favoriteListSelectors};
