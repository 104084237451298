import {ReactElement, useState} from "react";
import {ReactComponent as CartIcon} from "./cart-icon.svg";
import {ReactComponent as CartIconWhite} from "./cart-icon_white_grid.svg";
import {addItemToCart} from "../../lib/utility";
import {getLotteryInfo} from "../../lib/api/cartApi";
import {
	BlackFridayCartWheelBanner
} from "../../components/blackfriday/blackfridaycartwheelbanner/BlackFridayCartWheelBanner";
import {ClaimableWin} from "../../components/blackfriday/blackFriday";
import {FormattedMessage} from "react-intl";

interface CartIconProps {
	quantity: number
	productId: number
	isIntegratedInButton?: boolean
	additionalClass?: string
	blackFridayActive: boolean
	orderId?: number
	addToCartEvent?: (quantity: number) => void
	isGridView?: boolean
}

export function AddToCartButton(props: CartIconProps): ReactElement {

	const [missingAmount, setMissingAmount] = useState<number | null | undefined>();
	const [claimableWin, setClaimableWin] = useState<ClaimableWin | null | undefined>();

	const addToCart = async () => {
		await addItemToCart(props.productId, props.quantity);
		if(props.blackFridayActive) {
			const lotteryInfo = await getLotteryInfo();
			setMissingAmount(lotteryInfo.openAmount);
			setClaimableWin(lotteryInfo.claimableWin);
		}
	}

	return (
		<div className={`${props.isGridView ? "tw-flex tw-justify-center tw-gap-4 tw-bg-black tw-h-14" : ""} tw-cursor-pointer`}>
			{props.blackFridayActive &&
				<BlackFridayCartWheelBanner
					alwaysHideBanner={true}
					orderId={props.orderId!}
					missingAmount={missingAmount}
					claimableWin={claimableWin}
				/>
			}
			<button
				onClick={() => {
					if(props.addToCartEvent) {
						props.addToCartEvent(props.quantity)
					}
					addToCart()
				}}
				className={`hover:tw-text-grey ${props.additionalClass ?? ''}`}>
				{props.isGridView ?
					<div className={`tw-flex tw-items-center tw-gap-4 tw-text-xl tw-text-white`}>
						<CartIconWhite />
						<FormattedMessage id="frontend.adventcalendar.add.to.cart" />
					</div> :
					<CartIcon />
				}
			</button>
		</div>
	);
}
