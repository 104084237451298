import {get, post} from "./api";

const BASE_ENDPOINT_URL = "/api/v1/checkout/agecheck";

type ApiResponseState =
	'SUCCESS' |
	'ERROR'

export interface ApiResponse {
	state: ApiResponseState,
	value: { [key: string]: string }
}

export async function saveAgeToOrder(day: string, month: string, year: string): Promise<ApiResponse> {
	return await post({
		url: BASE_ENDPOINT_URL,
		body: {
			day,
			month,
			year
		}
	});
}

export async function getAgeFromOrder(): Promise<string> {
	return await get({
		url: BASE_ENDPOINT_URL
	});
}