import {ReactComponent as Check} from "./img/check-brown.svg";

import merryChristmas from "./img/merrychristmas.png";
import bestDad from "./img/bestdad.png";
import bestFriends from "./img/bestfriends.png";
import {AdventCalendarMode, AdventCalendarStep, OutOfStockMap} from "./AdventCalendar";
import AdventCalendarPopupHeader from "./AdventCalendarPopupHeader";
import {FormattedMessage} from "react-intl";
import {useEffect} from "react";

export interface Step2Props {
	setIsOpen: (isOpen: boolean) => void
	setMode: (mode: AdventCalendarMode) => void
	mode: AdventCalendarMode
	setStep: (step: AdventCalendarStep) => void
	oosMap: OutOfStockMap | undefined
}

export default function AdventCalendarPopupStep2(props: Step2Props) {

	useEffect(() => {
		if(props.oosMap?.MERRY_CHRISTMAS) {
			if(props.oosMap?.BEST_DAD) {
				props.setMode('BEST_FRIENDS')
			} else {
				props.setMode('BEST_DAD')
			}
		}
	}, [props.oosMap, props])

	return (
		<>
			<AdventCalendarPopupHeader
				currentPage={2}
				setIsOpen={props.setIsOpen} />
			<div className={"tw-flex tw-flex-col tw-flex-1"}>
				<div className={'tw-flex tw-flex-col tw-flex-1 tw-px-8'}>
					<div onClick={() => {
							if(!props.oosMap?.MERRY_CHRISTMAS) {
								props.setMode('MERRY_CHRISTMAS')
							}
						}}
						className={`tw-relative ${props.oosMap?.MERRY_CHRISTMAS ? '' : 'tw-cursor-pointer'} tw-flex tw-flex-row tw-border tw-p-8 tw-items-center ${props.mode === 'MERRY_CHRISTMAS' ? 'tw-border-black' : 'tw-border-grey'}`}>
						<div className={`tw-mr-4 md:tw-w-1/3 ${props.oosMap?.MERRY_CHRISTMAS ? 'tw-opacity-30' : ''}`}>
							<img alt={"merry christmas"}
								src={merryChristmas} />
						</div>
						<div className={`tw-flex tw-flex-row ${props.oosMap?.MERRY_CHRISTMAS ? 'tw-opacity-30' : ''}`}>
							<div className={'tw-flex tw-flex-col tw-items-start tw-text-left tw-ml-4 tw-mr-4'}>
								<div className={'tw-font-semibold tw-text-3xl'}>
									<FormattedMessage id={'frontend.adventcalendar.merry.christmas.type'} />
								</div>
								<div>
									<FormattedMessage id={'frontend.adventcalendar.merry.christmas.type.description'} />
								</div>
							</div>
							{props.mode === 'MERRY_CHRISTMAS' &&
                                <div>
                                    <Check/>
                                </div>
							}
						</div>
						{props.oosMap?.MERRY_CHRISTMAS &&
                            <div
                                className={'tw-absolute tw-left-1/2 tw-top-1/2 tw-bg-white tw-p-6 tw--ml-32 tw--mt-8 tw-font-bold'}>
                                <FormattedMessage id={'frontend.adventcalendar.out.of.stock'} />
                            </div>
						}
					</div>
					<div onClick={() => {
							if(!props.oosMap?.BEST_DAD) {
								props.setMode('BEST_DAD')
							}
						}}
						className={`tw-relative ${props.oosMap?.BEST_DAD ? '' : 'tw-cursor-pointer'} tw-flex tw-flex-row tw-border tw-p-8 tw-items-center tw-mt-8 ${props.mode === 'BEST_DAD' ? 'tw-border-black' : 'tw-border-grey'}`}>
						<div className={`tw-mr-4 ${props.oosMap?.BEST_DAD ? 'tw-opacity-30' : ''}`}>
							<img
								alt={"best dad"}
								src={bestDad} />
						</div>
						<div className={`tw-flex tw-flex-row ${props.oosMap?.BEST_DAD ? 'tw-opacity-30' : ''}`}>
							<div className={'tw-flex tw-flex-col tw-items-start tw-text-left tw-ml-4 tw-mr-4'}>
								<div className={'tw-font-semibold tw-text-3xl'}>
									<FormattedMessage id={'frontend.adventcalendar.best.dad.type'} />
								</div>
								<div>
									<FormattedMessage id={'frontend.adventcalendar.best.dad.type.description'} />
								</div>
							</div>
							{props.mode === 'BEST_DAD' &&
                                <div>
                                    <Check/>
                                </div>
							}
						</div>
						{props.oosMap?.BEST_DAD &&
                            <div
                                className={'tw-absolute tw-left-1/2 tw-top-1/2 tw-bg-white tw-p-6 tw--ml-32 tw--mt-8 tw-font-bold'}>
                                <FormattedMessage id={'frontend.adventcalendar.out.of.stock'} />
                            </div>
						}
					</div>
					<div onClick={() => {
							if(!props.oosMap?.BEST_FRIENDS) {
								props.setMode('BEST_FRIENDS')
							}
						}}
						 className={`tw-relative ${props.oosMap?.BEST_FRIENDS ? '' : 'tw-cursor-pointer'} tw-flex tw-flex-row tw-border tw-p-8 tw-items-center tw-mt-8 ${props.mode === 'BEST_FRIENDS' ? 'tw-border-black' : 'tw-border-grey'}`}>
						<div className={`tw-mr-4 md:tw-w-1/3 ${props.oosMap?.BEST_FRIENDS ? 'tw-opacity-30' : ''}`}>
							<img
								alt={"best friends"}
								src={bestFriends} />
						</div>
						<div className={`tw-flex tw-flex-row ${props.oosMap?.BEST_FRIENDS ? 'tw-opacity-30' : ''}`}>
							<div className={'tw-flex tw-flex-col tw-items-start tw-text-left tw-ml-4 tw-mr-4'}>
								<div className={'tw-font-semibold tw-text-3xl'}>
									<FormattedMessage id={'frontend.adventcalendar.best.friends.type'} />
								</div>
								<div>
									<FormattedMessage id={'frontend.adventcalendar.best.friends.type.description'} />
								</div>
							</div>
							{props.mode === 'BEST_FRIENDS' &&
								<div>
									<Check />
								</div>
							}
						</div>
						{props.oosMap?.BEST_FRIENDS &&
                            <div
                                className={'tw-absolute tw-left-1/2 tw-top-1/2 tw-bg-white tw-p-6 tw--ml-32 tw--mt-8 tw-font-bold'}>
                                <FormattedMessage id={'frontend.adventcalendar.out.of.stock'} />
                            </div>
						}
					</div>
				</div>
				<div className={'tw-flex tw-flex-1 tw-flex-col md:tw-flex-row'} style={{marginRight: '20px', marginLeft: '20px'}}>
					<div
						onClick={() => props.setStep('STEP1')}
						className={'tw-p-6 tw-px-16 tw-mt-12 tw-mb-12 tw-font-bold tw-bg-white tw-border-black tw-text-black tw-cursor-pointer tw-border md:tw-w-adventCalendarPopupButton'}>
						<FormattedMessage id={'frontend.adventcalendar.back'} />
					</div>
					<div className={'tw-flex tw-flex-1'}/>
					<div
						onClick={() => {
							if(!props.oosMap?.MERRY_CHRISTMAS || !props.oosMap.BEST_DAD || !props.oosMap.BEST_FRIENDS) {
								props.setStep('STEP3')
							}
						}}
						className={'md:tw-w-adventCalendarPopupButton tw-p-6 tw-bg-black tw-text-white md:tw-mt-12 tw-mb-24 md:tw-mb-12 tw-font-bold hover:tw-bg-white tw-border-black hover:tw-text-black tw-cursor-pointer tw-border'}>
						<FormattedMessage id={'frontend.adventcalendar.next'} />
					</div>
				</div>
			</div>
		</>
	)

}