import {ReactElement, ReactNode} from "react";
import {ImageButton} from "widgets/ImageButton";
import {ReactComponent as DecrementButtonSvg} from "widgets/DecrementButton.svg";
import {ReactComponent as IncrementButtonSvg} from "widgets/IncrementButton.svg";

export interface CountInputProps {
    count: number
    onSetCount: (count: number) => void
    incrementButton?: ReactNode
    decrementButton?: ReactNode
    isGridView?: boolean
}

export function CountInput(props: CountInputProps): ReactElement {
    const incrementCount = () => props.onSetCount(props.count + 1);
    const decrementCount = () => props.onSetCount(props.count - 1);
    const incrementButton = props.incrementButton ?? DefaultIncrementButton;
    const decrementButton = props.decrementButton ?? DefaultDecrementButton;
    return (
        <div className={`tw-flex ${props.isGridView ? "tw-gap-4" : "tw-w-full tw-justify-evenly"}`}>
            <ImageButton
                onClick={decrementCount}
                disabled={props.count <= 1}>
                {decrementButton}
            </ImageButton>
            <div className="tw-text-2xl tw-self-center">{props.count}</div>
            <ImageButton
                onClick={incrementCount}
                disabled={false}>
                {incrementButton}
            </ImageButton>
        </div>
    );
}

export const DefaultIncrementButton = <IncrementButtonSvg className="tw-w-auto tw-h-10"/>
export const DefaultDecrementButton = <DecrementButtonSvg className="tw-w-auto tw-h-10"/>
