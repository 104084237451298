import {FormattedMessage} from "react-intl";

export interface ProductListStatsProps {
    noHits: number
}

export default function ProductListStats(props: ProductListStatsProps) {

    return(
        <div className={"tw-mt-8"}>
            <FormattedMessage
                id="frontend.advanced-search.results.noHits"
                values={{
                    noHits: props.noHits,
                    strong: (chunks: string) => <strong>{chunks}</strong>
                }}
            />
        </div>
    );
}