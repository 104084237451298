import {ReactElement, useEffect, useState} from "react";
import {Dialog} from "@headlessui/react";
import AdventCalendarPopupStep1 from "./AdventCalendarPopupStep1";
import AdventCalendarPopupStep2 from "./AdventCalendarPopupStep2";
import AdventCalendarPopupStep3 from "./AdventCalendarPopupStep3";
import {axiosDelete, get, post} from "../../lib/api/api";
import {FormattedMessage} from "react-intl";
import {redirectTo} from "../../lib/utility";
import {validateEmail} from "../../lib/mailchimp";

export type AdventCalendarMode = 'MERRY_CHRISTMAS' | 'BEST_DAD' | 'BEST_FRIENDS';
export type AdventCalendarEdition = 'WINTER' | 'LIGHT_AND_FRUITY';
export type AdventCalendarStep = 'STEP1' | 'STEP2' | 'STEP3';

interface AdventCalendarOrderItem {
	dailyGreetings: boolean
	edition: AdventCalendarEdition
	from: string
	gift: boolean
	message: string
	recipient: string
	to: string
	type: AdventCalendarMode
}

export interface AdventCalendarProps {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	orderItemId?: string
}

export interface OutOfStockMap {
	WINTER: boolean
	LIGHT_AND_FRUITY: boolean
	MERRY_CHRISTMAS: boolean
	BEST_DAD: boolean
	BEST_FRIENDS: boolean
}

export default function AdventCalendar(props: AdventCalendarProps): ReactElement {

	const [edition, setEdition] = useState<AdventCalendarEdition>('WINTER');
	const [mode, setMode] = useState<AdventCalendarMode>('MERRY_CHRISTMAS');
	const [step, setStep] = useState<AdventCalendarStep>('STEP1');

	const [isGift, setIsGift] = useState(false);
	const [dailyGreetings, setDailyGreetings] = useState(true);

	const [recipient, setRecipient] = useState<string>();
	const [from, setFrom] = useState<string>();
	const [to, setTo] = useState<string>();
	const [message, setMessage] = useState<string>();

	const [errorRecipient, setErrorRecipient] = useState<boolean>(false);
	const [errorFrom, setErrorFrom] = useState<boolean>(false);
	const [errorTo, setErrorTo] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<boolean>(false);

	const [outOfStockMap, setOutOfStockMap] = useState<OutOfStockMap|undefined>();

	useEffect(() => {
		let isMounted = true;
		get({
			url: '/api/v1/advent-calendar/out-of-stock'
		}).then((oosMap: OutOfStockMap) => {
			if(isMounted) {
				setOutOfStockMap(oosMap);
			}
		})

		return () => { isMounted = false }

	}, []);

	useEffect(() => {
		if(props.orderItemId) {
			get({
				url: '/api/v1/advent-calendar/get-order-item-by-id/' + props.orderItemId
			}).then((orderItem: AdventCalendarOrderItem) => {
				if(orderItem) {
					setEdition(orderItem.edition);
					setMode(orderItem.type);
					setIsGift(orderItem.gift);
					setDailyGreetings(orderItem.dailyGreetings);
					setRecipient(orderItem.recipient);
					setFrom(orderItem.from);
					setTo(orderItem.to);
					setMessage(orderItem.message);
				}
			})
		}
	}, [props.orderItemId]);

	const validateForm = () : boolean => {
		if(isGift) {
			setErrorFrom(!from);
			setErrorTo(!to);
			setErrorMessage(!message);
			setErrorRecipient(!recipient);

			if(recipient) {
				if(!validateEmail(recipient)) {
					setErrorRecipient(true);
					return false;
				}
			}

			return !!(recipient && from && to && message);
		}
		return true;
	}

	const addItemToCart = () => {
		post({
			url: '/api/v1/advent-calendar/add',
			body: {
				edition: edition,
				type: mode,
				gift: isGift,
				dailyGreetings: dailyGreetings,
				recipient: recipient,
				from: from,
				to: to,
				message: message
			}
		}).then(res => {
			props.setIsOpen(false);
			setEdition('WINTER');
			setMode('MERRY_CHRISTMAS');
			setIsGift(false);
			setDailyGreetings(true);
			setRecipient(undefined);
			setFrom(undefined);
			setTo(undefined);
			setMessage(undefined);
			setStep('STEP1');
			redirectTo("/cart");
		});
	}

	const addToCart = () => {
		if(validateForm()) {
			if (props.orderItemId) {
				axiosDelete({
					url: '/api/v1/advent-calendar/delete/' + props.orderItemId
				}).then(res => {
					addItemToCart();
				})
			} else {
				addItemToCart()
			}
		}
	}

	if(!props.isOpen) {
		return <></>;
	}

	return (
		<div>
			<Dialog
				open={true}
				onClose={() => props.setIsOpen(false)}
				className="tailwind">
				<Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black tw-opacity-50 tw-z-modal-bg" />
				<div className="tw-fixed tw-z-modal-content tw-inset-0 md:tw-my-4 tw-overflow-y-auto md:tw-mt-24 tw-mb-0">
					<div className="tw-relative tw-mx-auto md:tw-w-adventCalendarPopupDesktop tw-bg-white tw-text-center">
						<div className={"tw-text-sliderSubTitle tw-font-bold tw-font-bold tw-font-gotham-black tw-text-black tw-pt-8 tw-pb-4 tw-px-2"}>
							<FormattedMessage id={'frontend.adventcalendar.create'} />
						</div>
						{step === 'STEP1' &&
                            <AdventCalendarPopupStep1
								setStep={setStep}
								setIsOpen={props.setIsOpen}
								setEdition={setEdition}
								edition={edition}
								oosMap={outOfStockMap} />
						}
						{step === 'STEP2' &&
							<AdventCalendarPopupStep2
                                setStep={setStep}
								setIsOpen={props.setIsOpen}
								setMode={setMode}
								mode={mode}
								oosMap={outOfStockMap} />
						}
						{step === 'STEP3' &&
                            <AdventCalendarPopupStep3
								errorTo={errorTo}
								errorFrom={errorFrom}
								errorMessage={errorMessage}
								errorRecipient={errorRecipient}
								orderItemId={props.orderItemId}
								isGift={isGift}
								setIsGift={setIsGift}
								dailyGreetings={dailyGreetings}
								setDailyGreetings={setDailyGreetings}
								recipient={recipient}
								setRecipient={setRecipient}
								from={from}
								setFrom={setFrom}
								to={to}
								setTo={setTo}
								message={message}
								setMessage={setMessage}
                                setStep={setStep}
								setIsOpen={props.setIsOpen}
								mode={mode}
								edition={edition}
								addToCart={addToCart}/>
						}
					</div>
				</div>
			</Dialog>
		</div>
	);
}