import {ReactElement, useCallback, useEffect, useState} from "react";
import {ProductSliderInnerProps} from "./ProductSliderDesktop";
import {useEmblaCarousel} from "embla-carousel/react";
import {Indicator} from "../home-slider/Indicator";
import {ProductCard} from "components/product-card/ProductCard";

export function ProductSliderMobile(props: ProductSliderInnerProps) : ReactElement {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [emblaRef, embla] = useEmblaCarousel({align: "start"});
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        embla.on("select", onSelect);
    }, [embla, onSelect]);

    return(
        <div className={"embla-product-slider tw-mb-16 tw-overflow-hidden"} ref={emblaRef}>
            <div className={`${props.appOnlyOffer ? 'embla-product-slider-app__container' : 'embla-product-slider-mobile__container'}`}>
                {props.products.map((product, index) => (
                    <div key={index} className="embla-product-slider-mobile__slide">
                        <ProductCard
                            blackFridayActive={props.blackFridayActive}
                            orderId={props.orderId}
                            mode="tall"
                            appOnlyOffer={props.appOnlyOffer}
                            product={product}
                        />
                    </div>
                ))}
            </div>
            {props.products.length > 1 &&
                <Indicator
                    sliderLength={props.products.length}
                    selectedIndex={selectedIndex}
                    scrollTo={scrollTo} />
            }
        </div>
    )

}
