import {get} from "./api";
import {BrandModel} from "../../components/HomeBrands/models/BrandModel";

export const BRANDS_ENDPOINT = "/api/v1/brands";


export async function getAllBrands(): Promise<BrandModel[]> {
	const apiBrandsList = await get({
		url: BRANDS_ENDPOINT
	});
	return apiBrandsList.map(apiBrandToBrandCore);
}

export async function getTopBrands(): Promise<BrandModel[]> {
	const apiProduct = await get({
		url: BRANDS_ENDPOINT + "/top",
	})
	return apiProduct.map(apiBrandToBrandCore);
}

export function apiBrandToBrandCore(apiBrand: ApiBrand): BrandModel {
	return {
		id: apiBrand.id,
		name: apiBrand.name,
		image: apiBrand.image,
		link: apiBrand.link,
	}
}

export interface ApiBrand {
	id: number
	name: string
	image: string
	link: string
}