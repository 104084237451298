import {ReactElement, ReactNode} from "react";
import {redirectTo} from "lib/utility";
import {FormattedMessage} from "react-intl";

export interface FeaturedProductsTitleProps {
    title: ReactNode
    url?: string
}

export function FeaturedProductsTitle(props: FeaturedProductsTitleProps): ReactElement {
    const onClickAction = props.url ?
        () => redirectTo(props.url!) :
        () => undefined;
    return <div
        className={`tw-flex ${props.url ? 'tw-cursor-pointer' : ''}
                    tw-my-8`}
        onClick={onClickAction}
    >
        <h4 className="tw-flex-1 tw-text-4xl tw-text-black tw-font-gotham-black tw-font-bold">{props.title}</h4>
        {props.url &&
        <span className="tw-flex-none">
            <FormattedMessage id="category.goToAll"/>
        </span>
        }
    </div>
}
