import {HeaderSearchProductHits} from "./HeaderSearchProductHits";
import {HeaderSearchRefinements} from "./HeaderSearchRefinements";
import {ComponentType, ReactElement} from "react";
import {connectStateResults, InsightsClient, StateResultsProvided} from "react-instantsearch-core";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {i18nSelectors} from "components/bundle-entry/i18n/i18nSlice";

export interface HeaderSearchResultsProps {
	productIndex: string,
	insightsClient: InsightsClient,
	searchTerm: string
}

function HeaderSearchResultsComponent(props: HeaderSearchResultsProps & StateResultsProvided): ReactElement {
	const blLocale = useSelector(i18nSelectors.getCurrentLocale);
	const intl = useIntl();

	return props.searchResults?.nbHits > 0 ?
		<div
			className="tw-flex tw-flex-col tw-overflow-y-visible tw-overflow-x-hidden
                   sm:tw-flex-row sm:tw-max-h-full sm:tw-flex-1 sm:tw-overflow-y-auto">
			<div className="tw-border-b tw-border-homeComponentsBg tw-py-8
                        sm:tw-flex-none sm:tw-flex sm:tw-w-2/3 sm:tw-overflow-y-auto sm:tw-border-r sm:tw-border-homeComponentsBg">
				<HeaderSearchProductHits
					insightsClient={props.insightsClient}
				/>
			</div>
			<div className="sm:tw-flex-1 sm:tw-flex sm:tw-flex-col sm:tw-overflow-y-auto">
				<div className="tw-border-b tw-border-homeComponentsBg tw-px-6 tw-py-8 sm:tw-border-none sm:tw-px-0">
					<HeaderSearchRefinements
						title={intl.formatMessage({id: 'frontend.advanced-search.filter.category'})}
						attribute={`searchableCategories_${blLocale}`}
						highlight={props.searchTerm}
						limit={3}
					/>
				</div>

				<div className="tw-px-6 tw-py-8 sm:tw-px-0">
					<HeaderSearchRefinements
						title={intl.formatMessage({id: 'frontend.advanced-search.filter.manufacturer'})}
						attribute="manufacturer"
						highlight={props.searchTerm}
						limit={3}
					/>
				</div>
			</div>
		</div>
		:
		// no results
		<div className="tw-p-20 sm:tw-text-center">
			<h3 className="tw-font-bold tw-text-black tw-text-2xl tw-mb-8">
				<FormattedMessage id="frontend.advanced-search.noResults.noResults"/>
			</h3>
			<div className="tw-text-2xl sm:tw-inline-block sm:tw-text-left">
				<ul className="tw-list-outside tw-list-disc">
					<li className="tw-py-3">
						<FormattedMessage id="frontend.advanced-search.noResults.tip1"/>
					</li>
					<li className="tw-py-3">
						<FormattedMessage id="frontend.advanced-search.noResults.tip2"/>
					</li>
				</ul>
			</div>
		</div>
}

export const HeaderSearchResults: ComponentType<HeaderSearchResultsProps> = connectStateResults(HeaderSearchResultsComponent);
