import {AnyAction, createSlice, PayloadAction, ThunkAction} from "@reduxjs/toolkit";
import {BlLocale, DefaultBlLocale} from "lib/BlLocale";
import {getTranslations, Translations} from "lib/api/translationsApi";
import {difference, isEmpty, merge, union} from "ramda";
import {debounce} from "debounce";
import {createPromiseHolder} from "lib/promises";

type I18nRootState = { i18n: I18nState };
type I18nThunk<ReturnType = void> = ThunkAction<ReturnType, I18nRootState, unknown, AnyAction>

interface LanguageTranslationCache {
    knownKeys: string[]
    downloadedTranslations: Translations
    currentFetchBatch: string[]
}

interface I18nState {
    currentLocale: BlLocale
    caches: {[locale in BlLocale]: LanguageTranslationCache}
}

const initialState: I18nState = {
    currentLocale: DefaultBlLocale,
    caches: {
        'de_CH': {
            knownKeys: [],
            downloadedTranslations: {},
            currentFetchBatch: []
        },
        'fr': {
            knownKeys: [],
            downloadedTranslations: {},
            currentFetchBatch: []
        },
        'it': {
            knownKeys: [],
            downloadedTranslations: {},
            currentFetchBatch: []
        }
    }
}

type LocaledPayload<T> = {
    locale: BlLocale
    payload: T
}

const {actions, reducer} = createSlice({
    name: 'i18n',
    initialState,
    reducers: {
        setCurrentLocale(state: I18nState, action: PayloadAction<BlLocale>) {
            state.currentLocale = action.payload;
        },
        addDownloadedTranslations(state: I18nState, action: PayloadAction<LocaledPayload<Translations>>) {
            const {locale, payload} = action.payload;
            state.caches[locale].downloadedTranslations =
                merge(state.caches[locale].downloadedTranslations, payload)
        },
        addKnownKeys(state: I18nState, action: PayloadAction<LocaledPayload<string[]>>) {
            const {locale, payload} = action.payload;
            state.caches[locale].knownKeys = union(state.caches[locale].knownKeys, payload);
        },
        clearCurrentFetchBatch(state: I18nState, action: PayloadAction<BlLocale>) {
            state.caches[action.payload].currentFetchBatch = [];
        },
        addToCurrentFetchBatch(state: I18nState, action: PayloadAction<LocaledPayload<string[]>>) {
            const {locale, payload} = action.payload;
            state.caches[locale].currentFetchBatch = union(state.caches[locale].currentFetchBatch, payload);
        }
    }
});

function constructDebouncedFetchBatch(locale: BlLocale) {
    let currentPromiseHolder = createPromiseHolder<void>();

    const debouncedFetch = debounce((dispatch: Parameters<I18nThunk>[0], getState: Parameters<I18nThunk>[1]) => {
        const {resolve} = currentPromiseHolder;
        currentPromiseHolder = createPromiseHolder<void>();
        const keysToFetch = Array.from(getState().i18n.caches[locale].currentFetchBatch);
        if (!isEmpty(keysToFetch)) {
            dispatch(actions.clearCurrentFetchBatch(locale));
            getTranslations(keysToFetch)
                .then(newTranslations => {
                    dispatch(actions.addDownloadedTranslations({locale, payload: newTranslations}));
                    resolve();
                });
        } else {
            resolve();
        }
    }, 200);

    return (dispatch: Parameters<I18nThunk>[0], getState: Parameters<I18nThunk>[1]) => {
        debouncedFetch(dispatch, getState);
        return currentPromiseHolder.promise;
    }
}

const debouncedFetchBatches = {
    de_CH: constructDebouncedFetchBatch('de_CH'),
    fr: constructDebouncedFetchBatch('fr'),
    it: constructDebouncedFetchBatch('it')
};

const fetchTranslationBatched = (locale: BlLocale, keys: string[]): I18nThunk<Promise<void>> =>
    (dispatch, getState) => {
        const unknownKeys = difference(keys, getState().i18n.caches[locale].knownKeys);
        if (!isEmpty(unknownKeys)) {
            dispatch(actions.addKnownKeys({locale, payload: keys}));
            dispatch(actions.addToCurrentFetchBatch({locale, payload: keys}));
            return debouncedFetchBatches[locale](dispatch, getState);
        } else {
            return Promise.resolve();
        }
    }

const fetchTranslationImmediate = (locale: BlLocale, keys: string[]): I18nThunk =>
    (dispatch, getState) => {
        const unknownKeys = difference(keys, getState().i18n.caches[locale].knownKeys);
        if (!isEmpty(unknownKeys)) {
            dispatch(actions.addKnownKeys({locale, payload: keys}));
            getTranslations(unknownKeys)
                .then(newTranslations => {
                    dispatch(actions.addDownloadedTranslations({locale, payload: newTranslations}));
                });
        }
    }

const publicActions = {
    setCurrentLocale: actions.setCurrentLocale,
    fetchTranslationBatched,
    fetchTranslationImmediate
};

const getCurrentCache = (state: I18nRootState) => state.i18n.caches[state.i18n.currentLocale];

const publicSelectors = {
    getCurrentLocale: (state: I18nRootState) => state.i18n.currentLocale,
    getTranslations: (state: I18nRootState) => getCurrentCache(state).downloadedTranslations
}

export {reducer as i18nReducer};
export {publicActions as i18nActions};
export {publicSelectors as i18nSelectors};
