import {ProductList, ProductListProps} from "components/product-list/ProductList";
import {ReactElement} from "react";
import {FeaturedProductsTitle, FeaturedProductsTitleProps} from "components/featured-products/FeaturedProductsTitle";
import {pick} from "ramda";

export type FeaturedProductsProps = FeaturedProductsTitleProps & ProductListProps

export function FeaturedProducts(props: FeaturedProductsProps): ReactElement {
    return <>
        <FeaturedProductsTitle {...pick(['title', 'url'], props)} />
        <ProductList {...pick(['productListKey', 'fetchFn', 'cartTrackingClass', 'customGridCols', 'mobileSlider', 'blackFridayActive', 'orderId'], props)} />
    </>
}
