export interface PricingInfo {
    isSale: boolean
    retailPrice: number
    formattedRetailPrice: string
    salePrice: number
    formattedSalePrice: string
    discount: number
    pricePerUnit: string | undefined
}

export function calculatePricingInfo(retailPrice: string | number, salePrice: string | number | null, pricePerUnit?: string | undefined): PricingInfo {
    const retailPriceNumber = getRetailPrice(retailPrice);
    const formattedRetailPrice = getFormattedRetailPrice(retailPriceNumber);
    const salePriceNumber = getSalePrice(retailPriceNumber, salePrice);
    const formattedSalePrice = getFormattedSalePrice(formattedRetailPrice, salePrice);
    const isSale = formattedRetailPrice !== formattedSalePrice;
    const discount = isSale ? calculateDiscount(retailPriceNumber, salePriceNumber) : 0;

    return {
        isSale,
        retailPrice: retailPriceNumber,
        formattedRetailPrice,
        salePrice: salePriceNumber,
        formattedSalePrice,
        discount,
        pricePerUnit
    };
}

function getRetailPrice(productRetailPrice: string | number): number {
    return (typeof productRetailPrice === 'number') ? productRetailPrice : parseFloat(productRetailPrice);
}

function getFormattedRetailPrice(productRetailPrice: string | number): string {
    return (typeof productRetailPrice === 'string') ? productRetailPrice : productRetailPrice.toFixed(2);
}

function getSalePrice(retailPrice: number, salePrice: string | number | null): number {
    if (typeof salePrice === 'number') {
        return salePrice
    }
    if (typeof salePrice === 'string') {
        return parseFloat(salePrice);
    }
    return retailPrice;
}

function getFormattedSalePrice(formattedRetailPrice: string, salePrice: string | number | null): string {
    if (typeof salePrice === 'string') {
        return salePrice;
    }
    if (typeof salePrice === 'number') {
        return salePrice.toFixed(2)
    }
    return formattedRetailPrice;
}

function calculateDiscount(retailPrice: number, salePrice: number): number {
    return Math.floor(100 - (salePrice / retailPrice * 100));
}
