import React, {ReactElement, useEffect, useState} from "react";
import {ProductCore} from "lib/model/product/ProductModel";
import {getAllProducts} from "lib/api/productApi";
import FavoritesAnonymous from "./FavoritesAnonymous";
import FavoritesCustomer from "./FavoritesCustomer";
import {favoriteActions, favoriteListSelectors} from "./favoriteListSlice";
import {useSelector} from "react-redux";
import FavoritesSkeleton from "./FavoritesSkeleton";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "lib/responsive-breakpoints";
import {JustDrinkBanner} from "../justdrink-banner/JustDrinkBanner";
import {FormattedMessage} from "react-intl";
import {useAppDispatch} from "../../lib/redux/reduxHooks";

export interface FavoritesProps {
    isAnonymous: boolean
    isApp: boolean
    hideBanner: boolean
    blackFridayActive: boolean
    orderId?: number
}

export default function Favorites(props: FavoritesProps): ReactElement {

    const favoriteProductIds = useSelector(favoriteListSelectors.getFavoriteProductIds);
    const favoritesStatus = useSelector(favoriteListSelectors.getStatus);
    const [products, setProducts] = useState<ProductCore[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = !useMediaQuery(mediaQueries.sm);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if(props.isApp) {
            dispatch(favoriteActions.fetchFavoriteProductList(
                props.isAnonymous
            ));
        }
    }, [props.isApp, dispatch, props.isAnonymous])

    useEffect(() => {
        if (favoritesStatus === 'FETCHED') {
            getAllProducts(JSON.stringify(favoriteProductIds))
                .then(favoriteProducts => {
                    setProducts(favoriteProducts);
                    setIsLoading(false);
                });
        }
    }, [favoriteProductIds, favoritesStatus]);

    if (favoritesStatus === 'FETCHED' && !isLoading) {

        if (props.isAnonymous) {
            return <>
                {(!props.isApp && !props.hideBanner) &&
                    /* Note: The following component has been created due to the Rebranding of Justdrink. At the moment, only one banner image ("jd-bottles") exist.
                    However, in case of multiple banner images in the future, the following component can be easily adjusted/refactored to fit the purpose and
                    another value can then be passed to the image prop of the component. */
                    <JustDrinkBanner
                        image={"jd-bottles"}
                        title={<FormattedMessage id="frontend.banner.defaultTitle"/>}
                        subtitle={<FormattedMessage id="frontend.banner.defaultSubTitle"/>}
                    />
/*                    <CarlBanner
                        image="promo-carl"
                        title={<FormattedMessage id="frontend.banner.defaultTitle"/>}
                        subTitle={<FormattedMessage id="frontend.banner.defaultSubTitle"/>}
                    />*/
                }
                <FavoritesAnonymous
                    blackFridayActive={props.blackFridayActive}
                    orderId={props.orderId}
                    products={products}/>
            </>
        } else {
            return <>
                {(!props.isApp && !props.hideBanner) &&
                    /* Note: The following component has been created due to the Rebranding of Justdrink. At the moment, only one banner image ("jd-bottles") exist.
                    However, in case of multiple banner images in the future, the following component can be easily adjusted/refactored to fit the purpose and
                    another value can then be passed to the image prop of the component. */
                    <JustDrinkBanner
                        image={"jd-bottles"}
                        title={<FormattedMessage id="frontend.banner.defaultTitle"/>}
                        subtitle={<FormattedMessage id="frontend.banner.defaultSubTitle"/>}
                    />
/*                    <CarlBanner
                        image="promo-carl"
                        title={<FormattedMessage id="frontend.banner.defaultTitle"/>}
                        subTitle={<FormattedMessage id="frontend.banner.defaultSubTitle"/>}
                    />*/
                }
                <FavoritesCustomer
                    blackFridayActive={props.blackFridayActive}
                    orderId={props.orderId}
                    products={products}/>
            </>
        }

    }

    return (
        <div className={`${isMobile ? '' : 'tw-ml-24'} ${!isMobile && !props.isAnonymous ? 'tw-flex tw-flex-1' : ''}`}>
            <FavoritesSkeleton isAnonymous={props.isAnonymous}/>
        </div>
    );

}
