import {FormattedMessage} from "react-intl";
import React from "react";

export function CheckoutDeliveryCargoInfo() {

    return (
        <div className={'tw-flex tw-flex-1 tw-mt-8 tw-mb-16'}>
            <div className={"tw-flex tw-flex-1 tw-pr-72"}>
                <div className={"tw-flex tw-flex-1 tw-pt-4 tw-italic"}>
                    <FormattedMessage id={'sevendays.delivery.cargo.delivery.info'}/>
                </div>
            </div>
            <div className={"tw-flex tw-flex-0 md:tw-flex-1"}/>
        </div>
    );

}