import {FormattedMessage} from "react-intl";
import {format} from "date-fns";
import React from "react";

interface CheckoutSectionDeliveryDateFieldProps {
    startDate: Date;
    setShowDatePicker: (showDatePicker: boolean) => void;
}

export function CheckoutDeliveryDateField(props: CheckoutSectionDeliveryDateFieldProps) {

    return(
        <div className={"tw-flex tw-flex-1 tw-flex-col tw-mt-8"}>
            <div className={"tw-text-3xl tw-font-semibold tw-mt-4"}>
                <FormattedMessage id={'cart.shipping.delivery.date.label'} />
            </div>
            <div className={"tw-flex tw-flex-1 tw-flex-row tw-pr-0 md:tw-pr-72"}>
                <div className={"tw-border tw-border-grey tw-flex tw-flex-1 tw-mt-2 tw-cursor-pointer"}
                     onClick={() => props.setShowDatePicker(true)}>
                    <div className={"tw-flex tw-flex-1 tw-p-4"}>
                        {format(props.startDate, "dd.MM.yyyy")}
                    </div>
                    <div className={"tw-border-l tw-border-grey tw-p-4"}>
                        <FormattedMessage id={'checkout.button.delivery.date.change'}/>
                    </div>
                </div>
                <div className={"tw-flex tw-flex-0 md:tw-flex-1"}/>
            </div>
            <div className={"tw-flex tw-flex-1 tw-pr-0 md:tw-pr-72"}>
                <div className={"tw-flex tw-flex-1 tw-pt-4"}>
                    <FormattedMessage id={'sevendays.delivery.avis'} />
                </div>
                <div className={"tw-flex tw-flex-0 md:tw-flex-1"}/>
            </div>
        </div>
    );

}