import {ReactElement} from "react";

export default function AccountIcon(): ReactElement {

	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 6.5C13.7158 6.5 15.1 7.88414 15.1 9.6C15.1 11.3159 13.7158 12.7 12 12.7C10.2841 12.7 8.89998 11.3159 8.89998 9.6C8.89998 7.88414 10.2841 6.5 12 6.5ZM12 22.9C10.5543 22.9 10.0125 22.7589 9.24586 22.4801C9.12029 22.4344 8.98863 22.3849 8.84691 22.3317C8.1159 22.0568 7.11734 21.6814 5.30712 21.2131C5.40465 18.6023 6.48977 17.0335 7.79214 16.0958C9.18446 15.0933 10.8731 14.78 12 14.78C13.1206 14.78 14.8095 15.0932 16.2035 16.0959C17.5007 17.029 18.5838 18.5864 18.6912 21.1724C16.9334 21.4631 16.0538 21.8651 15.3022 22.2087C15.2571 22.2293 15.2124 22.2497 15.1681 22.2698C14.7631 22.4539 14.4051 22.6062 13.9381 22.7165C13.4695 22.8273 12.8722 22.9 12 22.9Z"
				fill="#000000" stroke="#000000"/>
			<circle cx="12" cy="12" r="11" stroke="#000000" strokeWidth="2"/>
		</svg>
	);

}