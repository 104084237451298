import {get, post} from "./api";
import {CheckoutOrderModel} from "../../components/checkout/model/CheckoutOrderModel";
import {CheckoutContactModel} from "../../components/checkout/model/CheckoutContactModel";
import {CheckoutAddressRequest} from "../../components/checkout/sections/address/CheckoutSectionAddressExpanded";
import {CheckoutCustomerModel} from "../../components/checkout/model/CheckoutCustomerModel";
import {CheckoutDeliveryModel, SaveCheckoutDeliveryModel} from "../../components/checkout/model/CheckoutDeliveryModel";
import {
    ApplyCheckoutOfferItemModel,
    CheckoutPaymentModel,
    PaymentTransactionIdModel
} from "../../components/checkout/model/CheckoutPaymentModel";
import {
    CheckoutAgeVerificationCustomerInfoModel,
    CheckoutAgeVerificationTransactionModel, CompleteCheckoutOrderFinalizedModel, TransactionStatusModel
} from "../../components/checkout/model/CheckoutAgeVerificationModel";

const CHECKOUT_ORDER_ENDPOINT = "/api/v1/checkout/order";
const CHECKOUT_CONTACT_ENDPOINT = "/api/v1/checkout/contact";
const CHECKOUT_ADDRESS_ENDPOINT = "/api/v1/checkout/address";
const CHECKOUT_SECTION_ENDPOINT = "/api/v1/checkout/section";
const CHECKOUT_CUSTOMER_ENDPOINT = "/api/v1/checkout/customer";
const CHECKOUT_DELIVERY_ENDPOINT = "/api/v1/checkout/delivery";
const CHECKOUT_PAYMENT_ENDPOINT = "/api/v1/checkout/payment";
const CHECKOUT_OFFER_ITEM_ENDPOINT = "/api/v1/checkout/payment/gift-card/check";
const CHECKOUT_DELIVERY_DAY_VALIDITY = "/api/v1/checkout/delivery/dates/is-valid";
const CHECKOUT_AGE_VERIFICATION_ENDPOINT = "/api/v1/checkout/age-verification"
const CHECKOUT_COMPLETE_ORDER_ENDPOINT = "/api/v1/checkout/complete";
const CHECKOUT_ENABLE_NEWSLETTER_ENDPOINT = "/api/v1/checkout/enable-newsletter"

type ApiResponseState =
    'SUCCESS' |
    'ERROR'

interface ApiResponse {
    status: ApiResponseState,
    message: string,
    key?: string,
}

export async function getCheckoutOrder() : Promise<CheckoutOrderModel> {
    return await get({
        url: CHECKOUT_ORDER_ENDPOINT
    });
}

export async function getCheckoutContact(): Promise<CheckoutContactModel> {
    return await get({
        url: CHECKOUT_CONTACT_ENDPOINT
    });
}

export async function saveGuestEmailAddress(emailAddress: string): Promise<ApiResponse> {
    return await post({
        url: CHECKOUT_CONTACT_ENDPOINT,
        body: {
            emailAddress: emailAddress
        }
    });
}

export async function saveCheckoutAddress(checkoutAddressRequest: CheckoutAddressRequest): Promise<ApiResponse> {
    return await post({
        url: CHECKOUT_ADDRESS_ENDPOINT,
        body: checkoutAddressRequest
    });
}

export async function getCurrentCheckoutSection(): Promise<string> {
    return await get({
        url: CHECKOUT_SECTION_ENDPOINT
    });
}

export async function getCheckoutOrderInfo(): Promise<string> {
    return await get({
        url: CHECKOUT_ORDER_ENDPOINT + "/info"
    });
}

export async function getCheckoutAddresses(): Promise<CheckoutAddressRequest> {
    return await get({
        url: CHECKOUT_ADDRESS_ENDPOINT
    });
}

export async function getCheckoutCustomer(): Promise<CheckoutCustomerModel> {
    return await get({
        url: CHECKOUT_CUSTOMER_ENDPOINT
    })
}

export async function saveCheckoutDelivery(checkoutDeliveryRequest: SaveCheckoutDeliveryModel): Promise<ApiResponse> {
    return await post({
        url: CHECKOUT_DELIVERY_ENDPOINT,
        body: checkoutDeliveryRequest
    });
}

export async function getCheckoutDelivery(): Promise<CheckoutDeliveryModel> {
    return await get({
        url: CHECKOUT_DELIVERY_ENDPOINT
    });
}

export async function getCheckoutDeliveryDates(): Promise<string[]> {
    return await get({
        url: CHECKOUT_DELIVERY_ENDPOINT + "/dates"
    });
}

export async function getCheckoutKeywords(): Promise<string[]> {
    return await get({
        url: CHECKOUT_DELIVERY_ENDPOINT + "/warning-keywords"
    })
}

export async function getCheckoutPayment(): Promise<CheckoutPaymentModel> {
    return await get({
        url: CHECKOUT_PAYMENT_ENDPOINT
    })
}

export async function getPaymentTransactionId(currency: string, transactionTotal: number): Promise<PaymentTransactionIdModel> {
    return await post({
        url: CHECKOUT_PAYMENT_ENDPOINT + "/transaction-id",
        body: {
            currency: currency,
            transactionTotal: transactionTotal
        }
    })

}
export async function applyCheckoutOfferItem(checkoutOfferItemRequest: ApplyCheckoutOfferItemModel): Promise<ApiResponse> {
    return await post({
        url: CHECKOUT_OFFER_ITEM_ENDPOINT,
        body: checkoutOfferItemRequest
    })
}

export async function checkDeliveryDayValidity(): Promise<any> {
    return await get({
        url: CHECKOUT_DELIVERY_DAY_VALIDITY
    })
}

export async function getAgeVerificationTransactionLink(): Promise<CheckoutAgeVerificationTransactionModel> {
    return await get({
        url: CHECKOUT_AGE_VERIFICATION_ENDPOINT + "/transaction-link"
    })
}

export async function getPxlTransactionStatus(transactionCode: string): Promise<TransactionStatusModel> {
    return await get({
        url: CHECKOUT_AGE_VERIFICATION_ENDPOINT + `/transaction-status?transactionCode=${transactionCode}`
    })
}

export async function getAgeVerificationCustomerInfo(transactionCode?: string): Promise<CheckoutAgeVerificationCustomerInfoModel> {
    const url: string = transactionCode ?
        CHECKOUT_AGE_VERIFICATION_ENDPOINT + "/customer-info?transactionCode=" + transactionCode :
        CHECKOUT_AGE_VERIFICATION_ENDPOINT + "/customer-info"

    return await get({
        url: url
    })
}

export async function completeCheckoutOrderFinalized(): Promise<CompleteCheckoutOrderFinalizedModel> {
    return await post({
        url: CHECKOUT_COMPLETE_ORDER_ENDPOINT
    })
}

export async function setNewsletterEnabledOnOrder(newsletterEnabledOnOrder: string): Promise<ApiResponse> {
    return await post({
        url: CHECKOUT_ENABLE_NEWSLETTER_ENDPOINT,
        body: {
            isNewsletterEnabled: newsletterEnabledOnOrder
        }
    })
}

