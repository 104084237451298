import {ReactElement} from "react";
import {FormattedMessage} from "react-intl";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "lib/responsive-breakpoints";
import {ProductCore} from "lib/model/product/ProductModel";
import FavoritesEmptyPage from "../favorites/FavoritesEmptyPage";
import ProductListStats from "../product-list/ProductListStats";
import {ProductCard} from "components/product-card/ProductCard";

export interface FavoritesAnonymousProps {
    products: ProductCore[]
    blackFridayActive: boolean
    orderId?: number
}

export default function FavoritesInclusiveLastOrdersAnonymous(props: FavoritesAnonymousProps) : ReactElement {

    const isMobile = !useMediaQuery(mediaQueries.sm);

    if(props.products.length === 0) {
        return <FavoritesEmptyPage />
    }

    return(
        <div className={"tw-flex tw-flex-1 tw-flex-col"}>
            <div className={"tw-flex tw-flex-1 tw-text-4xl tw-font-bold"}>
                <FormattedMessage id={"frontend.topnavbarmenu.favoritesInclusiveLastOrders"} />
            </div>
            <ProductListStats noHits={props.products.length} />
            <div className={"tw-flex tw-flex-1"}>
                <div className={`${isMobile ? 'tw-flex tw-flex-1 tw-flex-col' : 'tw-flex tw-flex-1 tw-grid tw-grid-cols-4 tw-gap-10 tw-mt-4 tw-mb-12'}`}>
                    {props.products.map((product, index) => (
                        <ProductCard
                            blackFridayActive={props.blackFridayActive}
                            orderId={props.orderId}
                            mode="responsive"
                            key={index}
                            product={product}
                        />
                    ))}
                </div>
            </div>
        </div>
    );

}
