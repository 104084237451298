import {FormattedMessage} from "react-intl";

interface NoDepositButtonProps {
	hasDeposit: boolean
	noDepositAction: () => void
}

export function NoDepositButton(props: NoDepositButtonProps) {

	return (
		<div className={'tw-border-2 tw-border-black tw-flex tw-justify-center tw-p-4 tw-text-white md:tw-text-2xl ' +
				'tw-bg-black hover:tw-cursor-pointer hover:tw-bg-white hover:tw-text-black tw-mt-8 tw-font-bold tw-italic tw-font-gotham-black'}
			onClick={() => {
				props.noDepositAction();
			}}>
			<FormattedMessage
				id={props.hasDeposit ? 'cart.depositInformation.submit' : 'cart.depositInformation.submit.noDeposit'}/>
		</div>
	);
}