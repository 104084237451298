import {ComponentClass, ReactElement} from "react";
import {connectRefinementList, RefinementListExposed, RefinementListProvided} from 'react-instantsearch-core';
import {Highlighter} from "widgets/Highlighter";

export interface SearchbarRefinementsProps extends RefinementListExposed {
	title: string
	highlight: string
}

function Refinements(props: SearchbarRefinementsProps & RefinementListProvided): ReactElement {
	return <>
		<h3 className="tw-text-black tw-font-bold tw-px-7 tw-py-4">{props.title}</h3>
		<ul>
			{props.items.map(item =>
				<Refinement
					key={item.label}
					item={item}
					highlight={props.highlight}
					href={props.createURL(item.value)}
				/>
			)}
		</ul>
	</>
}

type RefinementItem = RefinementListProvided['items'][number];

interface RefinementProps {
	item: RefinementItem
	highlight: string
	href?: string
	onClick?: (item: RefinementItem) => void
}

function Refinement(props: RefinementProps): ReactElement {
	return <li
		className="tw-text-black tw-px-7 tw-py-2 hover:tw-bg-homeComponentsBg tw-cursor-pointer"
	>
		<a className="tw-block"
		   href={props.href ? encodeURI(props.href) : '#'}
		   onClick={(e) => {
			   if (props.onClick) {
				   e.preventDefault();
				   props.onClick(props.item)
			   }
		   }}
		>
			<span><Highlighter highlight={props.highlight} text={props.item.label}/></span>&nbsp;
			<span className="tw-text-grey">({props.item.count})</span>
		</a>
	</li>
}

export const HeaderSearchRefinements: ComponentClass<SearchbarRefinementsProps> = connectRefinementList(Refinements);
