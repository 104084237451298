import {ComponentClass, FormEvent, useCallback, useRef, useState, useEffect} from "react";
import {SearchBoxProvided} from 'react-instantsearch-core';
import {connectSearchBox} from 'react-instantsearch-dom';

import {ReactComponent as SearchIcon} from "widgets/SearchIcon.svg";
import {debounce} from "debounce";

import {useSelector} from "react-redux";
import {selectSidebarStatus} from "components/mobile-menu/mobileMenuSlice";

export interface SearchbarSearchboxProps {
	onSubmit: () => void
}

function SearchBox(props: SearchbarSearchboxProps & SearchBoxProvided) {
	const isSidebarActivated = useSelector(selectSidebarStatus)
	const [searchTerm, setSearchTerm] = useState(props.currentRefinement);
	const inputRef = useRef<HTMLInputElement>(null);
	const hasValue = props.currentRefinement.length !== 0;
	const submitCallback = (e: FormEvent<HTMLFormElement>) => {
		props.onSubmit();
		e.preventDefault();
		return false;
	};

	useEffect(() => {
		setSearchTerm("");
		props.refine("");
		// eslint-disable-next-line
	}, [isSidebarActivated])

	// debounce doesn't introduce new dependencies
	// eslint-disable-next-line
	const debouncedRefine = useCallback(debounce(props.refine, 150), [props.refine]);

	return <form noValidate role="search" onSubmit={submitCallback}>
		<div className="tw-relative tw-inline-block tw-w-full">
			<input
				className="tw-border-2 tw-border-grey tw-rounded-md tw-placeholder-grey tw-py-2.5 tw-pl-8 tw-pr-2 tw-w-full"
				type="search"
				placeholder="Suche"
				value={searchTerm}
				ref={inputRef}
				onChange={e => {
					setSearchTerm(e.target.value);
					debouncedRefine(e.target.value);
					e.preventDefault();
				}}
			/>
			{hasValue ?
				<button
					type="reset"
					onClick={e => {
						setSearchTerm("");
						props.refine("");
						inputRef.current?.focus();
						e.preventDefault();
					}}
					className="tw-absolute tw-top-0 tw-right-0 tw-px-5 tw-pt-2 tw-pb-2.5 ">
					<i className="tw-not-italic tw-text-4xl tw-text-grey">&times;</i>
				</button>
				:
				<span className="tw-absolute tw-top-0 tw-right-0 tw-px-5 tw-pt-4 tw-text-grey">
                    <SearchIcon/>
                </span>
			}
		</div>
	</form>
}

export const HeaderSearchSearchbox: ComponentClass<SearchbarSearchboxProps> = connectSearchBox(SearchBox);
