import {ProductCore, ProductType, SupplyLimitedSaleInfo} from "lib/model/product/ProductModel";
import {BadgeType} from "lib/model/product/BadgeType";
import {calculatePricingInfo} from "lib/model/product/PricingInfo";
import {get, post} from "./api";
import {ApiCategoryWithProductsModel, CategoryWithProductsModel} from "../model/category/CategoryWithProductsModel";

export const ALL_PRODUCTS_ENDPOINT = "/api/v1/product/cards";
export const PRODUCT_VOLATILE_ENDPOINT = "/api/v1/product/cards/volatile";
export const PRODUCT_ENDPOINT = "/api/v1/product/card";
export const PRODUCT_DETAIL_ENDPOINT = "/api/v1/product";
export const PRODUCT_WITH_APP_ONLY_DISCOUNT = "api/v1/product/app-only"
export const CATEGORY_PRODUCT_ENDPOINT = "/api/v1/product/category"

export async function getAllProducts(pIds: string): Promise<ProductCore[]> {
	const apiProductList = await post({
		url: ALL_PRODUCTS_ENDPOINT,
		body: {
			'productList': JSON.parse(pIds)
		}
	});
	return apiProductList.map(apiProductToProductCore);
}

export async function getProduct(pId: string): Promise<ProductCore> {
	const apiProduct = await post({
		url: PRODUCT_ENDPOINT + '/' + pId,
	})
	return apiProduct.map(apiProductToProductCore);
}

export async function getCategoryWithProducts(cId: string): Promise<CategoryWithProductsModel> {
	const categoryWithProducts: ApiCategoryWithProductsModel = await get({
		url: CATEGORY_PRODUCT_ENDPOINT + '/' + cId
	});

	return {
		products: categoryWithProducts.products.map(apiProductToProductCore),
		preSliderDesktop: categoryWithProducts.preSliderDesktop,
		preSliderMobile: categoryWithProducts.preSliderMobile,
		title: categoryWithProducts.title,
		link: categoryWithProducts.link
	};

}

export async function getProductDetail(pId: number) {
	return post({
		url: PRODUCT_DETAIL_ENDPOINT + '/' + pId
	});
}

export async function getAppOnlyProducts() {
	const products = await get({
			url: PRODUCT_WITH_APP_ONLY_DISCOUNT
		}
	);
	return products.map(apiProductToProductCore);
}

export function apiProductToProductCore(apiProduct: ApiProduct): ProductCore {
	return {
		productId: apiProduct.productId,
		type: apiProduct.productType,
		name: apiProduct.name,
		productUrl: apiProduct.url,
		productUrlFr: apiProduct.urlFr,
		productUrlIt: apiProduct.urlIt,
		imgUrl: apiProduct.imgUrl,
		cardActionButtonUrl: apiProduct.redirectUrl ?? apiProduct.url,
		badge: apiProduct.badge,
		packagingInfo: {
			type: apiProduct.packagingType,
			amountOfClInUnit: apiProduct.amountOfClInUnit,
			units: apiProduct.packagingSize
		},
		pricingInfo: calculatePricingInfo(apiProduct.retailPrice, apiProduct.salePrice, apiProduct.pricePerUnit),
		supplyLimitedSale: apiProduct.supplyLimitedSaleInfo
	}
}

/**
 * this corresponds to ProductCardDto.java
 */
export interface ApiProduct {
	productId: number
	name: string
	packagingType: string
	retailPrice: string
	salePrice: string
	badge: BadgeType
	imgUrl: string
	url: string
	urlFr: string
	urlIt: string
	productType: ProductType
	packagingSize: number
	amountOfClInUnit: number
	pricePerUnit: string
	redirectUrl: string | null
	supplyLimitedSaleInfo: SupplyLimitedSaleInfo | null
}

export function getVolatileProductInfo(productIds: number[]): Promise<ApiVolatileProductInfo[]> {
	return post({
		url: PRODUCT_VOLATILE_ENDPOINT,
		body: {
			"productList": productIds
		}
	});
}

export interface ApiVolatileProductInfo {
	productId: number
	inStock: boolean
	salePrice: string | null
	supplyLimitedSale: SupplyLimitedSaleInfo | null
}
