import {ImgHTMLAttributes, ReactElement} from "react";
import JustDrinkBottlesSmall from "./justdrink-bottles-md.png"
import JustDrinkBottlesLarge from "./justdrink-bottles-original.png"
import {breakpoints} from "../../../lib/responsive-breakpoints";


export function JustDrinkBottlesXs(props: ImgHTMLAttributes<HTMLImageElement>): ReactElement {
    const alt = props.alt ?? "JustDrink Bottles"
    const maxWidth = breakpoints.sm
    const isMobile = window.matchMedia(`(max-width: ${maxWidth})`).matches;

    const imageStyle = {
        height: isMobile ? '100px' : '140px'
    };

    return (
        <div>
            <picture>
                <source media={`(max-width: ${maxWidth})`} srcSet={JustDrinkBottlesSmall}/>
                <img src={JustDrinkBottlesLarge} alt={alt} style={imageStyle}/>
            </picture>
        </div>
    )
}
