import React, {ReactElement, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    favoriteInclusiveLastOrdersActions,
    favoriteInclusiveLastOrdersListSelectors
} from "./favoriteInclusiveLastOrdersSlice";
import {ProductCore} from "../../lib/model/product/ProductModel";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";
import {useAppDispatch} from "../../lib/redux/reduxHooks";
import {getAllProducts} from "../../lib/api/productApi";
import {JustDrinkBanner} from "../justdrink-banner/JustDrinkBanner";
import {FormattedMessage} from "react-intl";
import FavoritesInclusiveLastOrdersAnonymous from "./FavoritesInclusiveLastOrdersAnonymous"
import FavoritesInclusiveLastOrdersCustomer from "./FavoritesInclusiveLastOrdersCustomer"
import FavoritesSkeleton from "../favorites/FavoritesSkeleton";

export interface FavoritesProps {
    isAnonymous: boolean
    isApp: boolean
    hideBanner: boolean
    blackFridayActive: boolean
    orderId?: number
}

export default function FavoritesInclusiveLastOrders(props: FavoritesProps): ReactElement {

    const favoriteProductIdsIncludedLastOrders = useSelector((favoriteInclusiveLastOrdersListSelectors.getProductIds));
    const status = useSelector(favoriteInclusiveLastOrdersListSelectors.getStatus);
    const [products, setProducts] = useState<ProductCore[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = !useMediaQuery(mediaQueries.sm);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (props.isApp) {
            dispatch(favoriteInclusiveLastOrdersActions.favoriteProductInclusiveLastOrdersList(
                props.isAnonymous
            ));
        }
    }, [props.isApp, dispatch, props.isAnonymous])

    useEffect(() => {
        if (status === 'FETCHED') {
            getAllProducts(JSON.stringify(favoriteProductIdsIncludedLastOrders))
                .then(favoriteProducts => {
                    setProducts(favoriteProducts);
                    setIsLoading(false);
                });
        }
    }, [favoriteProductIdsIncludedLastOrders, status]);

    if (status === 'FETCHED' && !isLoading) {

        if (props.isAnonymous) {
            return <>
                {(!props.isApp && !props.hideBanner) &&
                    /* Note: The following component has been created due to the Rebranding of Justdrink. At the moment, only one banner image ("jd-bottles") exist.
                    However, in case of multiple banner images in the future, the following component can be easily adjusted/refactored to fit the purpose and
                    another value can then be passed to the image prop of the component. */
                    <JustDrinkBanner
                        image={"jd-bottles"}
                        title={<FormattedMessage id="frontend.banner.defaultTitle"/>}
                        subtitle={<FormattedMessage id="frontend.banner.defaultSubTitle"/>}
                    />
                    /*<CarlBanner
                        image="promo-carl"
                        title={<FormattedMessage id="frontend.banner.defaultTitle"/>}
                        subTitle={<FormattedMessage id="frontend.banner.defaultSubTitle"/>}
                    />*/
                }
                <FavoritesInclusiveLastOrdersAnonymous
                    blackFridayActive={props.blackFridayActive}
                    orderId={props.orderId}
                    products={products}/>
            </>
        } else {
            return <>
                {(!props.isApp && !props.hideBanner) &&
                    /* Note: The following component has been created due to the Rebranding of Justdrink. At the moment, only one banner image ("jd-bottles") exist.
                    However, in case of multiple banner images in the future, the following component can be easily adjusted/refactored to fit the purpose and
                    another value can then be passed to the image prop of the component. */
                    <JustDrinkBanner
                        image={"jd-bottles"}
                        title={<FormattedMessage id="frontend.banner.defaultTitle"/>}
                        subtitle={<FormattedMessage id="frontend.banner.defaultSubTitle"/>}
                    />
                    /*<CarlBanner
                        image="promo-carl"
                        title={<FormattedMessage id="frontend.banner.defaultTitle"/>}
                        subTitle={<FormattedMessage id="frontend.banner.defaultSubTitle"/>}
                    />*/
                }
                <FavoritesInclusiveLastOrdersCustomer
                    blackFridayActive={props.blackFridayActive}
                    orderId={props.orderId}
                    products={products}/>
            </>
        }

    }

    return (
        <div className={`${isMobile ? '' : 'tw-ml-24'} ${!isMobile && !props.isAnonymous ? 'tw-flex tw-flex-1' : ''}`}>
            <FavoritesSkeleton isAnonymous={props.isAnonymous}/>
        </div>
    );

}