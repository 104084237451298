import {getAllFavorites, getFavoritesInclusiveLastOrders, updateCustomerFavorites} from "./api/favoritesApi";
import {addSingleItemToCart} from "./api/cartApi";

export type StringKeyedObject<T> = { [key: string]: T }
export type RenameMap = { [key: string]: string }

export type FetchStatus =
    'INIT'
    | 'FETCHING'
    | 'FETCHED'
    | 'ERROR';

/**
 * I'm sure this can be done in a more typesafe way, but idk how
 */
export function renameProperties<T>(renameMap: RenameMap, obj: StringKeyedObject<T>): StringKeyedObject<T> {
    const out: StringKeyedObject<T> = {};
    for (const [oldName, newName] of Object.entries(renameMap)) {
        out[newName] = obj[oldName];
    }
    return out as { [key: string]: any }
}

export function redirectTo(url: string) {
    window.location.href = url;
}

export function asArray<T>(arg: T | T[]): T[] {
    return Array.isArray(arg) ? arg : [arg];
}

export const addItemToCart = async (productId: number, quantity: number) => {
    const response = await addSingleItemToCart({
        productId,
        quantity
    });
    showNotification(response.successMessage);
    updateHeaderCartItemsCount(response.cartItemCount);
}

export function showNotification(notification: string, delay?: number) {
    let $notificationBar = document.getElementById('notification_bar');
    const event = new CustomEvent(
        'fireNotification',
        {
            detail: {
                notification: notification,
                delay: delay
            }
        });
    $notificationBar?.dispatchEvent(event);
}

export function updateHeaderCartItemsCount(newCount: number) {
    let $headerCartItemsCount = document.getElementsByClassName('headerCartItemsCount').item(0);
    if($headerCartItemsCount) {
        if (newCount === 0) {
            $headerCartItemsCount.classList.add('hidden');
        } else {
            $headerCartItemsCount.classList.remove('hidden');
        }
        $headerCartItemsCount.innerHTML = newCount.toString();
    }
}

export async function getFavoriteProductIds(isAnonymous: boolean): Promise<number[]> {
    if(isAnonymous) {
        let favoriteProductIds = getFavoriteProductIdsFromLocalStorage();
        if(favoriteProductIds) {
            let parsedFavoriteProductIds = JSON.parse(favoriteProductIds);
            return parsedFavoriteProductIds.map(Number);
        } else {
            return [];
        }
    } else {
        let favoriteProductIds = await getAllFavorites();
        return favoriteProductIds.map(p => p.productId);
    }
}

export async function getFavoriteProductIdsIInclusiveLastOrders(isAnonymous: boolean) : Promise<number[]> {
    if (isAnonymous) {
        let favoriteProductIds = getFavoriteProductIdsFromLocalStorage();
        if(favoriteProductIds) {
            let parsedFavoriteProductIds = JSON.parse(favoriteProductIds);
            return parsedFavoriteProductIds.map(Number);
        } else {
            return [];
        }
    } else {
        let productIds = await getFavoritesInclusiveLastOrders();
        return productIds.map(p => p.productId);
    }
}

export function getFavoriteProductIdsFromLocalStorage() : string {
    let favoriteProductIds = localStorage.getItem("favoriteProductIds");
    if(favoriteProductIds) {
        return favoriteProductIds;
    }
    return "";
}

export function updateFavorites(favoriteProductIds: number[], isAnonymous: boolean) {
    let favProductIds = JSON.stringify(favoriteProductIds);
    if (isAnonymous) {
        localStorage.setItem("favoriteProductIds", favProductIds);
    } else {
        updateCustomerFavorites(favProductIds);
    }
}

export function sanitize(input: string) {
    return input.replace(/[/<>\\$(){}]/g, '');
}
