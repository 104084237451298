import {ReactElement} from "react";
import {FormattedMessage} from "react-intl";

interface SummaryNoDepositProps {
	showEditDeposit: () => void
}

export function SummaryNoDeposit(props: SummaryNoDepositProps): ReactElement {

	return (
		<div>
			<div className={'tw-font-bold tw-text-3xl'}>
				<FormattedMessage id={'cart.deposit.no.deposit'}/>
			</div>
			<div className={'tw-text-2xl tw-text-grey tw-underline tw-cursor-pointer tw-mt-4'}
				 onClick={() => props.showEditDeposit()}>
				<FormattedMessage id={'cart.deposit.add.deposit'}/>
			</div>
		</div>
	);

}