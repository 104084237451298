import {ReactElement, ReactNode} from "react";
import {FormattedMessage} from "react-intl";

export interface ErrorMessageProps {
    children: ReactNode
}

export function ErrorMessage(props: ErrorMessageProps): ReactElement {
    return <div className="tw-text-black">
        <strong className="tw-font-bold tw-text-2xl"><FormattedMessage id="frontend.error.label" />: </strong>
        <span>{props.children}</span>
    </div>
}
