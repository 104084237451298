import {ReactElement, ReactNode, useEffect} from "react";
import {i18nActions} from "components/bundle-entry/i18n/i18nSlice";
import {useSelector} from "react-redux";
import {
    bundleEntryActions,
    bundleEntryReducer,
    bundleEntrySelectors
} from "components/bundle-entry/init/bundleEntrySlice";
import {BlLocale} from "lib/BlLocale";
import {useAppDispatch} from "lib/redux/reduxHooks";
import {ThunkAction} from "@reduxjs/toolkit";
import {reduxStore} from "lib/redux/reduxStore";
import {isEmpty} from "ramda";

reduxStore.injectReducer("bundleEntry", bundleEntryReducer);

interface BundleEntryInitProps {
    bundleName: string
    initLocale: BlLocale | null
    prefetchTranslations?: string[]
    children: ReactNode
}

export function BundleEntryInit(props: BundleEntryInitProps): ReactElement {
    const dispatch = useAppDispatch();
    const finishedInit = useSelector(bundleEntrySelectors.getFinishedInit(props.bundleName));

    useEffect(() => {
        dispatch(bundleEntryActions.addBundleEntry(props.bundleName));
    }, [dispatch, props.bundleName]);

    useEffect(() => {
        if (props.initLocale != null) {
            dispatch(i18nActions.setCurrentLocale(props.initLocale));
        }
    }, [dispatch, props.initLocale]);

    useEffect(() => {
        if (props.prefetchTranslations != null && !isEmpty(props.prefetchTranslations) && props.initLocale != null) {
            dispatch(i18nActions.fetchTranslationBatched(props.initLocale, props.prefetchTranslations) as ThunkAction<Promise<void>, any, any, any>)
                .then(() => {
                    dispatch(bundleEntryActions.finishedInitForBundle(props.bundleName));
                })
        } else {
            dispatch(bundleEntryActions.finishedInitForBundle(props.bundleName));
        }
    }, [dispatch, props.bundleName, props.prefetchTranslations, props.initLocale]);

    return <>{finishedInit && props.children}</>
}
