import {get} from "./api";
import {MenuModel} from "../../components/desktop-menu/models/MenuModel";
import {CategoryEntryModel} from "../../components/desktop-menu/models/CategoryEntryModel";

export const MENU_ENDPOINT = '/api/v1/menu';

export async function getMenu(): Promise<MenuModel> {
	return get({
		url: MENU_ENDPOINT
	})
}

export async function getMenuWithoutSales(): Promise<MenuModel> {
	return get({
		url: MENU_ENDPOINT + "/without"
	})
}

export async function getBlogMenu(): Promise<any> {
	return get({
		url: MENU_ENDPOINT + "/blog"
	})
}

export async function getActionButtonMenu(): Promise<any> {
	return get({
		url: MENU_ENDPOINT + "/header-right-action-button"
	})
}

export async function getNftHoldersOffersButtonMenu(): Promise<any> {
	return get( {
		url: MENU_ENDPOINT + "/header-right-nft-holders-offers-button"
	})
}

export async function getBasicMenu(): Promise<any> {
	return get({
		url: MENU_ENDPOINT + "/basic"
	})
}

export async function getCategories(): Promise<CategoryEntryModel[]> {
	return get({
		url: MENU_ENDPOINT + "/categories"
	})
}

export interface MenuItem {
	altText: string,
	categoryId: number,
	customHtml: string,
	imageUrl: string,
	label: string,
	noFollow: boolean,
	submenu: MenuItem[],
	url: string
}
