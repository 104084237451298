import {Dialog} from "@headlessui/react";
import {ReactElement, ReactNode, useEffect, useState} from "react";
import X from "widgets/X.svg";
import {FormattedMessage} from "react-intl";

export interface ModalProps {
    open: boolean
    onClose: () => void
    children: ReactNode
    isClaimed: boolean
}

export function Modal(props: ModalProps): ReactElement {
    const [isOpen, setIsOpen] = useState(props.open);

    useEffect(() => {
        setIsOpen(props.open);
    }, [props.open]);

    const onCloseHandler = () => {
        setIsOpen(false);
        props.onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandler}
            className="tailwind">
            <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black tw-opacity-50 tw-z-modal-bg" />
            <div className="tw-fixed tw-z-modal-content tw-inset-0 tw-my-32 tw-overflow-y-auto
                            md:tw-mt-32 md:tw-mb-0">
                <div className="tw-relative tw-mx-auto md:tw-w-max">
                    <button className="tw-absolute tw-top-8 tw-right-8 tw-z-modal-close-btn"
                            onClick={onCloseHandler}>
                        <img className="tw-w-12 md:tw-w-20" src={X} alt="Schliessen"/>
                    </button>
                    <div className={'tw-p-8 tw-bg-white'}>
                        <div className={'tw-border-b tw-pt-2 md:tw-pt-6 tw-font-gotham tw-font-bold tw-pb-8 md:tw-pb-16 tw-border-beige tw-mb-8 tw-text-4xl md:tw-text-6xl tw-flex tw-items-center tw-flex-1 tw-justify-center'}>
                            <FormattedMessage id={'frontend.blackfriday.popup.title'} />
                        </div>
                        {props.children}
                        <div className={'tw-mt-12 tw-mb-6 tw-flex tw-flex-1 tw-flex-row'}>
                            <div className={'tw-flex tw-flex-1'} />
                            <div onClick={onCloseHandler}
                                 className={'tw-border tw-border-black tw-font-gotham tw-px-32 md:tw-px-96 tw-py-8 tw-flex tw-items-center tw-justify-center tw-text-3xl tw-font-bold hover:tw-bg-black tw-cursor-pointer hover:tw-text-white'}>
                                {props.isClaimed ? <FormattedMessage id={'frontend.blackfriday.popup.next'} /> : <FormattedMessage id={'frontend.blackfriday.popup.later'} />}
                            </div>
                            <div className={'tw-flex tw-flex-1'} />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
