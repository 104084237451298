import {ReactElement, useCallback, useState} from "react";
import {ProductCore} from "lib/model/product/ProductModel";
import {useEmblaCarousel} from "embla-carousel/react";
import {EmblaCarouselType} from "embla-carousel";
import {NextButton} from "./NextButton";
import {PrevButton} from "./PrevButton";
import {ProductCard} from "components/product-card/ProductCard";

export interface ProductSliderInnerProps {
    products: ProductCore[]
    blackFridayActive: boolean
    orderId?: number
    appOnlyOffer?: boolean
}

export interface ActionButtonProps {
    clickAction: () => void
}

export function ProductSliderDesktop(props: ProductSliderInnerProps) : ReactElement {

    const [emblaRef, embla] = useEmblaCarousel({
        inViewThreshold: 4,
        align: "start",
        draggable: false
    });

    const [nextEnabled, setNextEnabled] = useState(props.products.length > 4);
    const [prevEnabled, setPrevEnabled] = useState(false);


    function toggleButtons(embla: EmblaCarouselType) {
        setNextEnabled(embla.selectedScrollSnap() < embla.slideNodes().length - 4);
        setPrevEnabled(embla.selectedScrollSnap() > 0);
    }

    const scrollPrev = useCallback(() => {
        if (embla) {
            embla.scrollPrev();
            toggleButtons(embla);
        }
    }, [embla]);

    const scrollNext = useCallback(() => {
        if (embla) {
            embla.scrollNext();
            toggleButtons(embla);
        }
    }, [embla]);

    return (
        <div className={"tw-mb-12"}>
            <div className={"embla-product-slider"}>
                <div className={"embla__viewport"} ref={emblaRef}>
                    <div className={"embla-product-slider__container"}>
                        {props.products && props.products.map((product, index) => (
                            <div className={"embla-product-slider__slide"} key={index}>
                                <div className={"embla-product-slider__slide__inner"}>
                                    <ProductCard
                                        blackFridayActive={props.blackFridayActive}
                                        orderId={props.orderId}
                                        mode="tall"
                                        key={index}
                                        product={product}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {prevEnabled &&
                <PrevButton
                    clickAction={scrollPrev}/>
                }
                {nextEnabled &&
                <NextButton
                    clickAction={scrollNext}/>
                }
            </div>
        </div>
    );

}
