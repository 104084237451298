import axios from "axios";

export interface GetProps {
    url: string
}

export interface PostProps {
    url: string,
    body?: {}
}

export interface PutProps {
    url: string,
    body?: {}
}

export interface DeleteProps {
    url: string
}

export async function get(props: GetProps) {
    try {
        const result = await axios.get(props.url, {data: {}});
        return result.data;
    } catch (err) {
        console.error(err);
        return {};
    }
}

export async function post(props: PostProps) {
    try {
        const result = await axios.post(props.url, props.body ? props.body : {});
        return result.data;
    } catch (err) {
        console.error(err);
        return {};
    }
}

export async function put(props: PutProps) {
    try {
        const result = await axios.put(props.url, props.body ? props.body : {});
        return result.data;
    } catch (err) {
        console.error(err);
        return {};
    }
}

export async function axiosDelete(props: DeleteProps) {
    try {
        const result = await axios.delete(props.url, {data: {}});
        return result.data;
    } catch (err) {
        console.error(err);
        return {};
    }
}