import {FormattedMessage} from "react-intl";

interface SelectDepositButtonProps {
	onClick: () => void
}

export function SelectDepositButton(props: SelectDepositButtonProps) {

	return (
		<div
			className={'tw-border-2 tw-border-black tw-flex tw-justify-center tw-p-4 tw-text-black tw-text-2xl ' +
				'tw-font-gotham-black hover:tw-cursor-pointer hover:tw-bg-black hover:tw-text-white tw-mb-4 tw-text-bold tw-italic'}
			onClick={props.onClick}>
			<FormattedMessage id={'cart.depositInformation.submit.deposit'}/>
		</div>
	);

}