import {combineReducers, configureStore, Reducer, ReducersMapObject} from "@reduxjs/toolkit";

// make typescript know that reduxStore might be on the window object
declare global {
    interface Window {
        reduxStore: ReduxStore
    }
}

type InjectReducer = (key: string, reducer: Reducer) => void
export type ReduxStore = ReturnType<typeof createStore>;
export type RootState = { [scope: string]: any }

function createStore() {

    const store = configureStore({
        reducer:(state = {}, action) => state,
    });

    const injectedReducers: ReducersMapObject = {};
    const injectReducer: InjectReducer = (key, reducer) => {
        injectedReducers[key] = reducer;
        const newReducer = combineReducers(injectedReducers);
        store.replaceReducer(newReducer);
    }

    return {
        ...store,
        injectReducer
    }
}

/**
 * gets redux store either from existing window attribute, or create one if not yet existing
 */
if (!window.reduxStore) {
    window.reduxStore = createStore();
}
export const reduxStore = window.reduxStore;
export type AppDispatch = typeof reduxStore.dispatch;
