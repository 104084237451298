import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {sanitize} from "../../../../../lib/utility";

export interface CheckoutAddressPhoneFieldProps {
    phoneError: string
    setPhoneError: (error: string) => void
    phone: string
    setPhone: (phone: string) => void
}

export function CheckoutAddressPhoneField(props: CheckoutAddressPhoneFieldProps) {

    const intl = useIntl();

    return (
        <div className={"tw-mt-8"}>
            <div className={"tw-flex tw-flex-1"}>
                <div className={`${props.phoneError ? 'tw-text-red' : 'tw-text-black'} tw-flex tw-flex-1`}>
                    <FormattedMessage id={'cart.phone'}/> *
                </div>
                <div className={"tw-flex"}>
                    <FormattedMessage id={'cart.phone.description'}/>
                </div>
            </div>
            <div className={"tw-flex tw-flex-1"}>
                <input
                    value={props.phone ? props.phone : ''}
                    onChange={(e) => {
                        props.setPhoneError('');
                        props.setPhone(sanitize(e.target.value))
                    }}
                    placeholder={intl.formatMessage({id: 'cart.phone'})}
                    className={`${props.phoneError ? 'tw-border-red' : 'tw-border-grey'} tw-border-2 tw-flex-1 tw-p-6 tw-text-2xl`}
                    type={"text"}/>
            </div>
            {props.phoneError && (
                <div className={"tw-text-red tw-ml-2 tw-mt-2"}>
                    <FormattedMessage id={props.phoneError}/>
                </div>
            )}
        </div>
    )

}