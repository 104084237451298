import {Duration, intervalToDuration} from "date-fns";
import {ReactElement, useCallback, useEffect, useState} from "react";

export type DurationFormatter = (duration: Duration) => string

export interface CountdownProps {
    target: Date
    refreshIntervalMs: number
    format: DurationFormatter
}

export function Countdown(props: CountdownProps): ReactElement {
    const countdownFn = useCallback(() => calculateCountdown(props.format, props.target, new Date()), [props.format, props.target]);
    const [duration, setDuration] = useState(countdownFn());
    useEffect(() => {
        const timer = setInterval(() => {
            setDuration(countdownFn());
        }, props.refreshIntervalMs);
        return function cleanupTimer() {
            clearInterval(timer);
        };
    }, [props.refreshIntervalMs, countdownFn]);

    return <>{duration}</>;
}

function calculateCountdown(formatter: DurationFormatter, target: Date, start: Date): string {
    const duration = intervalToDuration({start: start, end: target});
    return formatter(duration);
}

export function formatDurationHoursMinutesSeconds(d: Duration): string {
    const hours = (d.days ?? 0) * 24 + (d.hours ?? 0);
    return `${formatDurationUnit(hours)}:${formatDurationUnit(d.minutes)}:${formatDurationUnit(d.seconds)}`;
}

export function formatDurationHoursMinutes(d: Duration): string {
    const hours = (d.days ?? 0) * 24 + (d.hours ?? 0);
    return `${formatDurationUnit(hours)}:${formatDurationUnit(d.minutes)}`;
}

function formatDurationUnit(n: number | undefined): string {
    return (n ?? 0).toLocaleString('de-CH', {minimumIntegerDigits: 2});
}
