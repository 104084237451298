import {IParseOptions, IStringifyOptions} from "qs";

export const qsFormat: {
    stringify: IStringifyOptions,
    parse: IParseOptions
} = {
    stringify: {
        arrayFormat: 'comma',
        allowDots: true,
        encode: false,
        format: 'RFC1738' // spaces to '+'
    },
    parse: {
        allowDots: true,
        comma: true
    }
}

// copied from blc.js to use in react part
export function addUrlParam(search: string, key: string, val: string){
    let newParam = key + '=' + val, params = '?' + newParam;

    // If the "search" string exists, then build params from it
    if (search) {
        // Try to replace an existing instance
        params = search.replace(new RegExp('[?]' + key + '[^&]*'), '?' + newParam);

        // If nothing was replaced, then check if it exists as a trailing param
        if (params === search) {
            params = search.replace(new RegExp('[&]' + key + '[^&]*'), '&' + newParam);

            // If nothing was replaced and the key is not already present, then add the new param to the end
            if ((params === search) && (search.indexOf(val) === -1) ) {
                params += '&' + newParam;
            }
        }
    }

    return document.location.search = params;
}
