import {SearchBox} from "./SearchBox";
import {useState} from "react";
import {DepositItemModel} from "../../lib/model/depositinfo/DepositItemModel";
import {useSelector} from "react-redux";
import {depositInfoSelectors} from "./depositInfoSlice";
import {DepositItemList} from "./DepositItemList";
import {SearchInput} from "./SearchInput";
import {EnterDepositTitle} from "./EnterDepositTitle";

interface AddDepositFormProps {
	setDepositHasChanged: () => void
}

export function AddDepositForm(props: AddDepositFormProps) {


	const [search, setSearch] = useState<string>();
	const [searchResults, setSearchResults] = useState<DepositItemModel[]>();

	const searchableDepositItems = useSelector(depositInfoSelectors.getSearchableDepositItems);
	const searchableDepositItemsState = useSelector(depositInfoSelectors.getSearchableDepositItemsStatus);

	function searchInSearchableDepositItems(name: string) : DepositItemModel[] {
		return searchableDepositItems.filter(depositItem => {
			return depositItem.name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1
		})
	}

	const searchDepositItem = (query: string) => {
		setSearch(query);
		if(searchableDepositItemsState === 'FETCHED') {
			setSearchResults(searchInSearchableDepositItems(query));
		}
	}

	return(
		<>
			<EnterDepositTitle />
			<div className={'tw-relative'}>
				<SearchInput
					searchDepositItem={searchDepositItem} />
				{search &&
					<SearchBox
                        setDepositHasChanged={props.setDepositHasChanged}
						setSearch={setSearch}
						searchResults={searchResults}
						searchableDepositItemsState={searchableDepositItemsState} />
				}
			</div>
			<DepositItemList
				setDepositHasChanged={props.setDepositHasChanged} />
		</>
	);

}