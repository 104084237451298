import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type BundleEntryRootState = { bundleEntry: BundleEntryState }
// type BundleEntryThunk<ReturnType = void> = ThunkAction<ReturnType, BundleEntryRootState, unknown, AnyAction>

type BundleEntryState = {
    [entry: string]: SingleEntryState
}

interface SingleEntryState {
    finishedInit: boolean
}

const initialState: BundleEntryState = {}
const createSingleEntryState = () => ({
    finishedInit: false
});

const {actions, reducer} = createSlice({
    name: 'bundleEntry',
    initialState,
    reducers: {
        addBundleEntry(state: BundleEntryState, action: PayloadAction<string>) {
            if (!state[action.payload]) {
                state[action.payload] = createSingleEntryState();
            }
        },
        finishedInitForBundle(state: BundleEntryState, action: PayloadAction<string>) {
            const bundleEntryState = state[action.payload];
            if (bundleEntryState) {
                bundleEntryState.finishedInit = true;
            } else {
                console.error(`trying to set finishedInit on bundleEntry ${action.payload}`);
            }
        }
    }
});

const publicActions = {
    addBundleEntry: actions.addBundleEntry,
    finishedInitForBundle: actions.finishedInitForBundle
}

const publicSelectors = {
    getFinishedInit: (bundleEntry: string) => (state: BundleEntryRootState) => state.bundleEntry[bundleEntry]?.finishedInit
}

export {reducer as bundleEntryReducer};
export {publicActions as bundleEntryActions};
export {publicSelectors as bundleEntrySelectors};
