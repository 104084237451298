import desktopDark from "./img/desktopdark.png";
import desktopLight from "./img/desktoplight.png";
import mobileDark from "./img/mobiledark.png";
import mobileLight from "./img/mobilelight.png";

import {ReactComponent as Check} from "./img/check-white.svg";
import {AdventCalendarEdition, AdventCalendarStep, OutOfStockMap} from "./AdventCalendar";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";
import {FormattedMessage} from "react-intl";
import AdventCalendarPopupHeader from "./AdventCalendarPopupHeader";
import {useEffect} from "react";

export interface Step1Props {
	setIsOpen: (isOpen: boolean) => void
	setEdition: (mode: AdventCalendarEdition) => void
	edition: AdventCalendarEdition
	setStep: (step: AdventCalendarStep) => void
	oosMap: OutOfStockMap | undefined
}

export default function AdventCalendarPopupStep1(props: Step1Props) {

	const isMobile = !useMediaQuery(mediaQueries.sm);

	useEffect(() => {
		if(props.oosMap?.WINTER) {
			props.setEdition('LIGHT_AND_FRUITY');
		}
	}, [props.oosMap, props])

	return (
		<>
			<AdventCalendarPopupHeader
				currentPage={1}
				setIsOpen={props.setIsOpen} />
			<div className={"tw-flex tw-flex-col tw-flex-1"}>
				<div className={'tw-flex tw-justify-center'} style={{marginLeft: '20px', marginRight: '20px'}}>
					<img
						alt={'header'}
						className={'tw-w-full'}
						src={props.edition === 'WINTER' ? (isMobile ? mobileDark : desktopDark) : (isMobile ? mobileLight : desktopLight)} />
				</div>
				<div className={"tw-flex tw-flex-1 tw-flex-col md:tw-flex-row tw-mt-8"} style={{marginLeft: '20px', marginRight: '20px'}}>
					<div className={`tw-relative tw-flex tw-flex-1 tw-flex-col ${props.oosMap?.WINTER ? '' : 'tw-cursor-pointer'} tw-border tw-border-black md:tw-mr-4 " ${props.edition === 'WINTER' ? 'tw-bg-black tw-text-white' : 'tw-bg-white tw-text-black'}`}
						 onClick={() => {
							 if(!props.oosMap?.WINTER) {
								 props.setEdition('WINTER')
							 }
						 }}>
						<div className={`tw-flex tw-flex-row tw-text-3xl tw-p-6 tw-pt-10 ${props.oosMap?.WINTER ? 'tw-opacity-30' : ''}`}>
							<div className={'tw-flex tw-flex-1 tw-font-bold tw-justify-center'}>
								<FormattedMessage id={'frontend.adventcalendar.winter.edition'} />
							</div>
							<div>
								{props.edition === 'WINTER' && <Check />}
							</div>
						</div>
						<div className={`tw-pb-4 tw-px-2 ${props.oosMap?.WINTER ? 'tw-opacity-30' : ''}`}>
							<FormattedMessage id={'frontend.adventcalendar.winter.edition.beers'} />
						</div>
						<div className={`tw-pb-12 tw-px-2 ${props.oosMap?.WINTER ? 'tw-opacity-30' : ''}`}>
							<FormattedMessage id={'frontend.adventcalendar.winter.edition.description'} />
						</div>
						{props.oosMap?.WINTER &&
                            <div
                                className={'tw-absolute tw-left-1/2 tw-top-1/2 tw-bg-white tw-p-6 tw--ml-32 tw--mt-8 tw-font-bold'}>
                                <FormattedMessage id={'frontend.adventcalendar.out.of.stock'} />
                            </div>
						}
					</div>
					<div className={`tw-relative tw-flex tw-flex-1 tw-flex-col ${props.oosMap?.LIGHT_AND_FRUITY ? '' : 'tw-cursor-pointer'} tw-border tw-border-black md:tw-ml-4 ${props.edition === 'LIGHT_AND_FRUITY' ? 'tw-bg-black tw-text-white' : 'tw-bg-white tw-text-black'} tw-mt-4 md:tw-mt-0`}
						 onClick={() => {
							 if(!props.oosMap?.LIGHT_AND_FRUITY) {
								 props.setEdition('LIGHT_AND_FRUITY')
							 }
						 }}>
						<div className={`tw-flex tw-flex-row tw-text-3xl tw-p-6 tw-pt-10 ${props.oosMap?.LIGHT_AND_FRUITY ? 'tw-opacity-30' : ''}`}>
							<div className={'tw-flex tw-flex-1 tw-font-bold tw-justify-center'}>
								<FormattedMessage id={'frontend.adventcalendar.light.and.fruity.edition'} />
							</div>
							<div>
								{props.edition === 'LIGHT_AND_FRUITY' && <Check />}
							</div>
						</div>
						<div className={`tw-pb-4 tw-px-2 ${props.oosMap?.LIGHT_AND_FRUITY ? 'tw-opacity-30' : ''}`}>
							<FormattedMessage id={'frontend.adventcalendar.light.and.fruity.beers'} />
						</div>
						<div className={`tw-pb-12 tw-px-2 ${props.oosMap?.LIGHT_AND_FRUITY ? 'tw-opacity-30' : ''}`}>
							<FormattedMessage id={'frontend.adventcalendar.light.and.fruity.description'} />
						</div>
						{props.oosMap?.LIGHT_AND_FRUITY &&
                            <div
                                className={'tw-absolute tw-left-1/2 tw-top-1/2 tw-bg-white tw-p-6 tw--ml-32 tw--mt-8 tw-font-bold'}>
                                <FormattedMessage id={'frontend.adventcalendar.out.of.stock'} />
                            </div>
						}
					</div>
				</div>
				<div className={'tw-flex tw-flex-1 tw-justify-end'} style={{marginRight: '20px', marginLeft: '20px'}}>
					<div
						onClick={() => {
							if(!props.oosMap?.WINTER || !props.oosMap.LIGHT_AND_FRUITY) {
								props.setStep('STEP2')
							}
						}}
						className={`tw-p-6 tw-bg-black tw-text-white tw-mt-4 md:tw-mt-12 tw-mb-12 tw-font-bold hover:tw-bg-white hover:tw-border-black 
						hover:tw-text-black tw-cursor-pointer tw-border ${isMobile ? 'tw-flex-1' : ''} tw-w-adventCalendarPopupButton`}>
						<FormattedMessage id={'frontend.adventcalendar.next'} />
					</div>
				</div>
			</div>
		</>
	)

}