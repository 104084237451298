import {ReactElement} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {DepositItemModel} from "../../../lib/model/depositinfo/DepositItemModel";

interface SummaryItemListProps {
	depositItems: DepositItemModel[]
}

export function SummaryItemList(props: SummaryItemListProps) : ReactElement {

	const intl = useIntl();

	const getTotalDepositValue = () => {
		let total = 0;
		for(let depositItem of props.depositItems) {
			total += depositItem.totalDepositAmount;
		}
		return total.toFixed(2);
	}

	return(
		<div className={"tw-mt-8"}>
			{props.depositItems.map(depositItem => (
				<div className={'tw-flex tw-flex-col tw-border-b tw-border-inactiveGrey tw-pb-8 tw-mb-8'} key={depositItem.id}>
					<div>
						<div className={'tw-font-bold tw-text-3xl tw-w-2/3'}>
							{`${depositItem.packagingType} ${depositItem.name}`}
						</div>
					</div>
					<div className={'tw-flex tw-flex-row tw-flex-1 tw-mt-2'}>
						<div className={'tw-text-2xl tw-w-2/3 tw-text-lightGrey tw-flex tw-items-end'}>
							{`${intl.formatMessage({id: 'cart.depositValue'})}: ${depositItem.depositValue.toFixed(2)} CHF`}
						</div>
						<div className={'tw-flex tw-flex-1 tw-text-2xl tw-items-end'}>{`x ${depositItem.quantity}`}</div>
						<div className={'tw-font-bold tw-flex tw-justify-end tw-text-3xl'}>
							{`${depositItem?.totalDepositAmount?.toFixed(2)} CHF`}
						</div>
					</div>
				</div>
			))}
			<div className={'tw-flex tw-flex-1 tw-py-8 tw-text-4xl tw-font-bold'}>
				<div className={'tw-flex-1'}>
					<FormattedMessage id={'cart.deposit.total.label'} />
				</div>
				<div className={''}>
					{`${getTotalDepositValue()} CHF`}
				</div>
			</div>
		</div>
	);

}