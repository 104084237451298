import {BlLocale} from "../BlLocale";
import {renameProperties, StringKeyedObject} from "lib/utility";
import {pick} from "ramda";
import {BadgeType} from "lib/model/product/BadgeType";

const localizedAttributes = ['name','categories', 'container', 'country', 'parentCategory', 'description'];
const unlocalizedAttributes = [
	'objectID',
	'productId',
	'productUrl',
	'productUrl_fr',
	'productUrl_it',
	'imageUrl',
	'manufacturer',
	'retailPrice',
	'salePrice',
	'discount',
	'type',
	'badge',
	'packagingSize',
	'packagingType',
	'amountOfClInUnit',
	'pricePerUnit',
	'cardActionButtonUrl',
	'badges',
	'eanNumber',
	'eanBottle'
];

export interface ProductIndexDoc {
	objectID: string
	productId: number
	name_de_CH: string
	name_fr: string
	name_it: string
	productUrl: string
	productUrl_fr: string
	productUrl_it: string
	imageUrl: string
	manufacturer: string
	container_de_CH: string
	container_fr: string
	container_it: string
	retailPrice: number
	salePrice: number
	discount: number
	// soldUnits: number
	type: 'degupack' | 'friendspack' | 'giftcard' | 'beer' | 'wine' | 'spirit' | 'product',
	badge: string
	packagingSize: number
	packagingType: string
	amountOfClInUnit: number
	pricePerUnit: string
	cardActionButtonUrl: string
	badges: BadgeType[]
	categories_de_CH: string[]
	categories_fr: string[]
	categories_it: string[]
	parentCategory_de_CH: string
	parentCategory_fr: string
	parentCategory_it: string
	description_de_CH: string
	description_fr: string
	description_it: string
	EanNumber: string
	eanBottle: string
}

export interface LocalizedProductIndexDoc {
	objectID: string
	productId: number
	name: string
	productUrl: string
	productUrl_fr: string
	productUrl_it: string
	imageUrl: string
	manufacturer: string
	container: string
	packagingType: string
	retailPrice: number
	salePrice: number
	discount: number
	soldUnits: number
	type: 'degupack' | 'friendspack' | 'giftcard' | 'beer' | 'wine' | 'spirit' | 'product',
	badge: BadgeType
	packagingSize: number
	amountOfClInUnit: number
	pricePerUnit: string
	cardActionButtonUrl: string
	badges: string[]
	categories: string[]
	parentCategory: string
	description: string,
	category: string
	EanNumber: string
	eanBottle: string
}

export function localizeAttributeName(locale: BlLocale, attributeName: string): string {
	return `${attributeName}_${locale}`;
}

export function localize(locale: BlLocale, doc: ProductIndexDoc): LocalizedProductIndexDoc {
	const renameMap = Object.fromEntries(localizedAttributes.map(a => [localizeAttributeName(locale, a), a]));
	return Object.assign(
		{},
		renameProperties(renameMap, doc as StringKeyedObject<any>),
		pick(unlocalizedAttributes, doc)
	) as unknown as LocalizedProductIndexDoc;
}

