import {FormattedMessage} from "react-intl";
import {CheckoutAddressValues} from "../../../model/CheckoutAddressValuesModel";

export interface CheckoutAddressSelectorProps {
    salutationError: string
    updateAddress: (address: CheckoutAddressValues) => void
    customerAddresses: CheckoutAddressValues[]
    selectedAddressIndex: number
}

export function CheckoutAddressSelector(props: CheckoutAddressSelectorProps) {

    return (
        <>
            {(props.customerAddresses && props.customerAddresses.length > 0) && (
                <>
                    <div className={`${props.salutationError ? 'tw-text-red' : 'tw-text-black'} tw-flex tw-flex-1`}>
                        <FormattedMessage id={'sevendays.saved.address'} />
                    </div>
                    <div className={"tw-flex tw-flex-1 tw-cursor-pointer tw-mb-8"}>
                        <select
                            value={props.selectedAddressIndex}
                            onChange={(e) => props.updateAddress(props.customerAddresses[parseInt(e.target.value)])}
                            className={`tw-flex tw-h-myAccountFormHeight tw-flex-1 tw-border tw-p-4 tw-cursor-pointer`}>
                            {props.customerAddresses.map((address, i) => (
                                <option value={i} key={i}>
                                    {address.addressName}
                                </option>
                            ))}
                        </select>
                    </div>
                </>
            )}
        </>
    )

}