import {ProductCore} from "lib/model/product/ProductModel";
import axios from "axios";
import {ApiProduct, apiProductToProductCore} from "lib/api/productApi";

export const STATWORKS_RECOMMENDATIONS_ENDPOINT = "/api/v1/recommendations";

export function getRecommendations(num: number, basket: number[]): Promise<ProductCore[]> {
    return axios.request<ApiProduct[]>({
        url: STATWORKS_RECOMMENDATIONS_ENDPOINT,
        method: 'post',
        data: {
            num: num,
            basket: basket
        }
    })
        .then(response => response.data)
        .then(apiProducts => apiProducts.map(apiProductToProductCore));
}
