import React, {ReactElement, ReactNode} from 'react';
import {Provider} from "react-redux";
import {I18n} from "components/bundle-entry/i18n/I18n";
import {BlLocale} from "lib/BlLocale";
import {BundleEntryInit} from "components/bundle-entry/init/BundleEntryInit";
import {reduxStore} from "lib/redux/reduxStore";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const siteKey = '6LeUTkAlAAAAAMpPdpDph-nga3CXLUOozEmVSOLV';

export interface BundleEntryProps {
    bundleName: string
    locale: BlLocale | null
    children: ReactNode
    prefetchTranslations?: string[]
}

export function BundleEntry(props: BundleEntryProps): ReactElement {
    return <React.StrictMode>
        <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <Provider store={reduxStore}>
            <BundleEntryInit
                bundleName={props.bundleName}
                initLocale={props.locale}
                prefetchTranslations={props.prefetchTranslations}>
                <I18n>
                    {props.children}
                </I18n>
            </BundleEntryInit>
        </Provider>
        </GoogleReCaptchaProvider>
    </React.StrictMode>
}
