import {FormattedMessage, useIntl} from "react-intl";
import React from "react";

export interface CheckoutAddressSalutationFieldProps {
    salutationError: string
    setSalutationError: (error: string) => void
    salutation: string
    setSalutation: (salutation: string) => void
}

export function CheckoutAddressSalutationField(props: CheckoutAddressSalutationFieldProps) {

    const intl = useIntl();

    return (
        <>
            <div className={`${props.salutationError ? 'tw-text-red' : 'tw-text-black'} tw-flex tw-flex-1`}>
                <FormattedMessage id={'cart.salutation'}/> *
            </div>
            <div className={"tw-flex tw-flex-1 tw-cursor-pointer"}>
                <select
                    className={`${props.salutationError ? 'tw-border-red' : 'tw-border-black'} tw-flex tw-h-myAccountFormHeight tw-flex-1 tw-border tw-p-4 tw-cursor-pointer`}
                    onChange={(e) => {
                        props.setSalutationError('');
                        props.setSalutation(e.target.value);
                    }}
                    value={props.salutation}>
                    <option
                        value={""}
                        disabled={true}>
                        {intl.formatMessage({id: 'cart.salutation.choose'})}
                    </option>
                    <option value={'HERR'}>
                        {intl.formatMessage({id: 'salutation.HERR'})}
                    </option>
                    <option value={'FRAU'}>
                        {intl.formatMessage({id: 'salutation.FRAU'})}
                    </option>
                </select>
            </div>
            {props.salutationError && (
                <div className={"tw-text-red tw-ml-2 tw-mt-2"}>
                    <FormattedMessage id={props.salutationError} />
                </div>
            )}
        </>
    )

}