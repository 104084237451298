import { createSlice } from "@reduxjs/toolkit";

// TYPES
interface MobileMenuRootState {
    mobileMenu: MobileMenuState;
}

interface MobileMenuState {
    value: boolean;
}

// SELECTORS
const selectSidebarStatus = (state: MobileMenuRootState) => state.mobileMenu?.value;

// REDUCER

const initialState: MobileMenuState = {
    value: false,
};

const mobileMenuSlice = createSlice( {
    name: 'mobileMenu',
    initialState,
    reducers: {
        activateSidebar: (state: MobileMenuState) => {
            state.value = true;
        },
        deactivateSidebar: (state: MobileMenuState) => {
            state.value = false;
        }
    }
})

// EXPORTS

const reducer = mobileMenuSlice.reducer;
export {reducer as mobileMenuReducer};
export const {activateSidebar, deactivateSidebar} = mobileMenuSlice.actions;
export {selectSidebarStatus};