import {ComponentType, ReactElement} from "react";
import {connectHitInsights, ConnectHitInsightsProvided, Hit, InsightsClient} from "react-instantsearch-core";
import {Highlight} from "react-instantsearch-dom";
import {localize, localizeAttributeName, ProductIndexDoc} from "lib/algolia/algoliaProductIndex";
import {useSelector} from "react-redux";
import {i18nSelectors} from "components/bundle-entry/i18n/i18nSlice";


type ProductHitProps = {
	hit: Hit<ProductIndexDoc>,
};

function ProductHit(props: ProductHitProps & ConnectHitInsightsProvided): ReactElement {
	const blLocale = useSelector(i18nSelectors.getCurrentLocale);
	const sendInsightsClickEvent = () => props.insights('clickedObjectIDsAfterSearch', {eventName: 'Product Clicked'})
	const product = localize(blLocale, props.hit);
	return <li className="hover:tw-bg-homeComponentsBg hover:tw-cursor-pointer tw-flex tw-py-3 tw-px-6 tw-relative">
		<div className="tw-mr-7 tw-h-thumbnail tw-w-thumbnail">
			<img src={`${product.imageUrl}?thumbnail`}
				 alt={`Thumbnail ${product.name}`}
				 className="tw-h-auto tw-w-auto tw-max-h-full tw-max-w-full tw-mx-auto"
			/>
		</div>
		{/* hacky hack for vertical alignment*/}
		<div className="tw-flex tw-items-center">
			<Highlight
				hit={props.hit}
				attribute={localizeAttributeName(blLocale, 'name')}
				tagName="strong"
			/>
		</div>
		<a className="tw-absolute tw-inset-0 tw-w-full header-search-product-hit"
		   href={`${props.hit.productUrl}`}
		   aria-label={`Go to ${product.name}`}
		   onClick={sendInsightsClickEvent}
		>
		</a>
	</li>
}

export interface HeaderSearchProductHitProps {
	hit: Hit<ProductIndexDoc>,
	insightsClient: InsightsClient
}

export function HeaderSearchProductHit(props: HeaderSearchProductHitProps): ReactElement {
	const ConnectedHit = connectHitInsights(props.insightsClient)(ProductHit) as unknown as ComponentType<ProductHitProps>;
	return <ConnectedHit hit={props.hit}/>
}
