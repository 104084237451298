import {ReactElement, useEffect, useState} from "react";
import {ProductSliderDesktop} from "./ProductSliderDesktop";
import {getAllProducts} from "../../lib/api/productApi";
import {ProductCore} from "../../lib/model/product/ProductModel";
import {times} from "ramda";
import {ProductListSkeleton} from "../product-list-item/ProductListSkeleton";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";
import {ProductSliderMobile} from "./ProductSliderMobile";

export interface ProductSliderProps {
    productIds: number[]
    blackFridayActive: boolean
    orderId?: number
}

export function ProductSlider(props: ProductSliderProps) : ReactElement | null {
    const [products, setProducts] = useState<ProductCore[]>();
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = !useMediaQuery(mediaQueries.sm);

    useEffect(() => {
        let isMounted = true;
        getAllProducts(JSON.stringify(props.productIds))
            .then(result => {
                if(isMounted) {
                    setProducts(result);
                    setIsLoading(false);
                }
            })

        return () => { isMounted = false }

    }, [props.productIds]);

    if (isLoading) {
        return <div className={`tw-grid tw-gap-12 tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-mb-12`}>
            {times(n => <ProductListSkeleton key={n} />, 4)}
        </div>;
    }

    if (products) {
        return isMobile ?
            <ProductSliderMobile
                blackFridayActive={props.blackFridayActive}
                orderId={props.orderId}
                products={products}/> :
            <ProductSliderDesktop
                blackFridayActive={props.blackFridayActive}
                orderId={props.orderId}
                products={products}/>
    }

    return null;
}
