import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ProductCore} from "lib/model/product/ProductModel";

// TYPES

type ProductListsRootState = { productLists: ProductListsState }

interface ProductListsState {
    lists: { [listKey: string]: ProductListState }
}

interface ProductListState {
    requestState: ProductListStatus,
    products: ProductCore[],
    error: string | null
}

type ProductListStatus =
    'fetching'
    | 'fetched'
    | 'error';


// SELECTORS

const selectors = {
    getRequestState: (key: string) => (state: ProductListsRootState) => state.productLists.lists[key]?.requestState ?? 'fetching',
    getProducts: (key: string) => (state: ProductListsRootState) => state.productLists.lists[key]?.products ?? [],
    getError: (key: string) => (state: ProductListsRootState) => state.productLists.lists[key]?.error ?? null
}

// THUNKS

export interface CreateListRequest {
    key: string,
    request: () => Promise<ProductCore[]>
}

const fetchList = createAsyncThunk(
    'productLists/create',
    (createListRequest: CreateListRequest, thunkAPI) => {
        return createListRequest.request();
    }
);



// REDUCER

const initialState: ProductListsState = {
    lists: {}
};

const {reducer} = createSlice({
    name: 'productLists',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchList.pending, (state, action) => {
            state.lists[action.meta.arg.key] = {
                requestState: 'fetching',
                products: [],
                error: null
            };
        });
        builder.addCase(fetchList.rejected, (state, action) => {
            state.lists[action.meta.arg.key].requestState = 'error';
            state.lists[action.meta.arg.key].error = action.error.message?.toString() ?? "unknown error";
            console.log(action.error);
        });
        builder.addCase(fetchList.fulfilled, (state, action) => {
            state.lists[action.meta.arg.key].requestState = 'fetched';
            state.lists[action.meta.arg.key].error = null;
            state.lists[action.meta.arg.key].products = action.payload;
        });
    }
});

// PUBLIC STUFF

const publicActions = {
    fetchList
};

const publicSelectors = {
    getProducts: selectors.getProducts,
    getError: selectors.getError,
    isLoading: (key: string) => (state: ProductListsRootState) => state.productLists.lists[key]?.requestState === 'fetching'
};

export {reducer as productListsReducer};
export {publicActions as productListsActions};
export {publicSelectors as productListsSelectors};
